import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Typography,
  TableCell,
  Box
} from '@mui/material';
import styled from 'styled-components';
import { Button } from 'components';
import { UnRepresentedlist } from 'schemas';

interface UnderRepresentedProps {
  UnRepresentedlist: UnRepresentedlist[];
  handleObjectSelection: (type: string, bin: number | null | undefined) => void;
  handleCheckUnrepresented: (res: UnRepresentedlist) => void;
  checkedUnRepresented: UnRepresentedlist[];
  selectBins: boolean;
  currentTable: string | undefined;
  currentBin: number | null | undefined;
}
interface selectBinProps {
  selectbin: boolean;
}
const StyledTableCell = styled(TableCell)<selectBinProps>(
  ({ theme, selectbin }) => ({
    padding: '4px',
    backgroundColor: selectbin ? '#00000059' : '#ededed'
  })
);

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  border: `1px solid #ededed`,
  padding: '2px',
  backgroundColor: '#ededed',
  position: 'sticky',
  top: 0,
  zIndex: 5
}));

const UnderRepresented: React.FC<UnderRepresentedProps> = ({
  handleObjectSelection,
  UnRepresentedlist,
  handleCheckUnrepresented,
  checkedUnRepresented,
  selectBins,
  currentBin,
  currentTable
}) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: '92%',
          overflowY: 'auto',
          borderRadius: '8px'
        }}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableHeader align="center">
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Bin no
                </Typography>
              </StyledTableHeader>
              <StyledTableHeader align="center">
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  No. of Images
                </Typography>
              </StyledTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {UnRepresentedlist &&
              UnRepresentedlist.map(res => (
                <TableRow key={res.bin_number}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    selectbin={selectBins}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative'
                      }}
                    >
                      <Button
                        disableRipple
                        version={
                          selectBins
                            ? checkedUnRepresented
                                .map(i => i.bin_number)
                                .includes(res.bin_number)
                              ? 'dark'
                              : 'light'
                            : currentTable == 'unrepresented' &&
                              currentBin == res.bin_number
                            ? 'dark'
                            : 'light'
                        }
                        onClick={() => {
                          if (selectBins) {
                            handleCheckUnrepresented(res);
                          } else {
                            // Deselect if already selected
                            if (
                              currentTable === 'unrepresented' &&
                              currentBin === res.bin_number
                            ) {
                              handleObjectSelection('underrepresented', null);
                            } else {
                              handleObjectSelection(
                                'unrepresented',
                                res.bin_number || 0
                              );
                            }
                          }
                        }}
                        sx={{
                          width: '35px',
                          height: '25px',
                          minWidth: '0px',
                          border: 'none',
                          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                        }}
                      >
                        {res.bin_number}
                      </Button>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center" selectbin={selectBins}>
                    {res.number_of_images}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UnderRepresented;
