import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import Card from 'components/Card';
import { Typography } from 'components/Typography/Typography';

const SkeletonLoader: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={1} mt={1}>
        <Grid container item xs={12} md={12} lg={12} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Card
              style={{
                minHeight: '68vh',
                maxHeight: '68vh',
                minWidth: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px'
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  height: '15vh'
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    <Skeleton width="60%" />
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    <Skeleton width="60%" />
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    <Skeleton width="60%" />
                  </Typography>
                </Grid>
                <Grid item xs={6} mt={2}>
                  <Skeleton variant="rectangular" width="90%" height="90%" />
                </Grid>
                <Grid item xs={12} mt={2} mb={1}>
                  <div
                    style={{ border: '1px solid #696969', opacity: 0.3 }}
                  ></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={2}
                  mb={1}
                  style={{
                    minHeight: '45vh',
                    maxHeight: '45vh',
                    overflow: 'scroll'
                  }}
                >
                  <Grid container spacing={2} justifyContent="flex-start">
                    {Array.from({ length: 36 }).map((_, index) => (
                      <Grid item key={index} ml={2}>
                        <Skeleton
                          variant="rectangular"
                          width="100px"
                          height="100px"
                          style={{ borderRadius: '5px' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkeletonLoader;
