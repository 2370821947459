// Dependency imports
import React, { ReactNode, useState, useEffect } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
  SortDirection,
  IconButton,
  Box,
  Stack,
  Skeleton,
  Tooltip
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { visuallyHidden } from '@mui/utils';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  SearchOutlined
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';

// Local imports
import { setRowsPerPage } from 'store/pagination/paginationSlice';
import { RootState } from 'store';
import DropDown from '../../assets/images/Drop_down.svg';
import Dropup from '../../assets/images/Drop_up.svg';
type Order = 'asc' | 'desc';

type RenderFunctionInput = {
  value: RecordValue;
  record: Record;
};

export const TableContainer = MuiTableContainer;

const StyledTable = styled(MuiTable)({
  // tableLayout: 'fixed',
  minWidth: '100%'
});

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: 16,
  paddingLeft: '40px',
  textAlign: 'center'
  // minWidth: '350px',
  // maxWidth: '500px',
}));

const StyledTableContainer = styled(MuiTableContainer, {
  shouldForwardProp: prop => prop !== 'hasData' // Ensures that 'hasData' is passed as a prop but not forwarded to the root element
})<{ hasData: boolean }>(({ hasData }) => ({
  height: hasData ? '55vh' : '55vh',
  overflowY: hasData ? 'scroll' : 'hidden',
  overflowX: 'scroll',
  width: '100%',
  position: 'relative',
  '&::-webkit-scrollbar': {
    height: '8px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  }
}));

const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '10px 40px'
  // minWidth: '250px',
  // maxWidth: '400px',
});

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.primary.main,
  whiteSpace: 'nowrap'
}));

export interface ITableCellProps {
  value: RecordValue;
  key: string;
  render?: (input: RenderFunctionInput) => ReactNode;
  align?: 'left' | 'right' | 'center';
  ellipsis?: boolean;
}

export interface ITableHeadColumn extends ITableCellProps {
  sorter?: boolean;
  sortOrder?: 'ascend' | 'descend';
  sortDirections?: SortDirection;
  width?: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof Record>(
  order: Order,
  orderBy: Key
): (a: Record, b: Record) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedArray = array.map((el, index) => [el, index] as [T, number]);
  stabilizedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedArray.map(el => el[0]);
}

interface EnhancedTableProps {
  columns: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  expandedRowRender: any;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, columns, expandedRowRender } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <StyledTableHead
      sx={{
        width: '100%',
        backgroundColor: '#102633',
        '& .MuiTableCell-root': {
          color: 'white'
        },
        '& .MuiTableSortLabel-root': {
          color: 'white',
          '&.Mui-active': {
            color: 'white'
          },
          '&:hover': {
            color: 'white'
          },
          '& .MuiTableSortLabel-icon': {
            color: 'white !important'
          }
        },
        '& .MuiCheckbox-root': {
          color: 'white'
        }
      }}
    >
      <StyledTableHeadRow>
        {columns.map((column: any) => (
          <StyledTableHeadCell
            key={column.key}
            variant="head"
            size={'medium'}
            sortDirection={orderBy === column.key ? order : false}
          >
            {column.key === 'id' ||
            column.key === 'actions' ||
            column.key === 'Novelty' ? (
              <span>{column.value}</span>
            ) : (
              <TableSortLabel
                active={orderBy === column.key}
                direction={orderBy === column.key ? order : 'asc'}
                onClick={createSortHandler(column.key)}
                sx={{ fontSize: 16 }}
              >
                {column.value}
                {orderBy === column.key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableHeadCell>
        ))}
        {expandedRowRender && <StyledTableHeadCell />}
      </StyledTableHeadRow>
    </StyledTableHead>
  );
}

interface EnhancedTableToolbarProps {
  setRow?: any;
  rows?: any[];
  setExpandedRowIndex?: any;
  searchVal?: string;
  setSearchVal?: any;
  setPage?: any;
  setIsRowEditing?: any;
}

const EnhancedTableToolbar = ({
  setRow,
  rows,
  setExpandedRowIndex,
  searchVal,
  setSearchVal,
  setPage,
  setIsRowEditing
}: EnhancedTableToolbarProps) => {
  const handleSearch = (searchVal: string) => {
    // setIsRowEditing(false);
    if (searchVal === '') {
      if (rows) {
        setRow(rows);
        return;
      }
    }

    const filterBySearch = rows?.filter((item: any) => {
      if (
        typeof item.name === 'string' &&
        item.name.toLowerCase().includes(searchVal?.trim()?.toLowerCase())
      ) {
        return item;
      }
    });
    setRow(filterBySearch);
  };

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      sx={{ marginBottom: 2 }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifySelf: 'flex-start',
          gap: 4,
          width: '100%',
          border: 'none',
          borderColor: '#1F1F1F',
          borderRadius: '4px',
          padding: '8px 4px',
          boxShadow: '0.25px 0.25px 2px 1px grey'
        }}
      >
        <SearchOutlined />
        <input
          type="text"
          value={searchVal}
          onChange={e => {
            setSearchVal(e.target.value);
            if (setExpandedRowIndex) {
              setExpandedRowIndex(null);
            }

            setPage(0);
            handleSearch(e.target.value);
          }}
          style={{
            flex: 1,
            border: 'none',
            fontSize: '14px',
            padding: '4px',
            outline: 'none',
            zIndex: 20
          }}
          placeholder="Search by Name"
        />
      </div>
    </Stack>
  );
};

export type RecordValue =
  | string
  | number
  | undefined
  | object
  | React.ReactElement;

export type Record = {
  id: string; // Ensure that each record has a unique id
  [key: string]: RecordValue | RecordValue[];
};

interface ITableProps {
  columns: any;
  rows: Array<Record>;
  expandable?: boolean;
  TooltipName?: string;
  expandedRowRender?: (record: Record) => ReactNode;
  striped?: boolean; // Striped prop highlights row by setting different color on alternate rows
  loading?: boolean;
  stickyHeader?: boolean;
  containerStyle?: React.CSSProperties;
  highlightCondition?: (record: Record) => boolean; // Custom highlight condition
  size?: 'small' | 'medium';
  disableHeader?: boolean;
  bordered?: boolean;
  expandedRowIndex?: any;
  setExpandedRowIndex?: any;
  setIsRowEditing?: any;
  searchVal?: string;
  setSearchVal?: any;
  showSearch?: boolean;
}

const CellValue: React.FC<{
  key: string;
  value: RecordValue;
}> = ({ value }) => {
  if (React.isValidElement(value)) {
    return value;
  }

  if (typeof value === 'object') {
    return <div style={{ textAlign: 'center' }}>{JSON.stringify(value)}</div>;
  }
  return <div style={{ textAlign: 'center' }}>{value}</div>;
};

interface IRowProps {
  record: Record;
  columns: any;
  expanded?: boolean;
  expandedRowRender?: (record: Record) => ReactNode;
  onExpandClick?: (expanded: boolean) => void;
  rowIndex: number; // Added rowIndex prop for indentify the odd and even rows on table
  striped?: boolean; // Striped prop highlights row by setting different color on alternate rows
  analysis?: RecordValue;
  highlightCondition?: (record: Record) => boolean; // Custom highlight condition
  size?: 'small' | 'medium';
  TooltipName?: string;
}

// Define the component for rendering a row
const Row: React.FC<IRowProps> = ({
  record,
  columns,
  expanded = false,
  expandedRowRender,
  onExpandClick,
  TooltipName,
  rowIndex, // Added rowIndex prop for indentify the odd and even rows on table
  striped, // Striped prop highlights row by setting different color on alternate rows
  highlightCondition, // Custom highlight condition,
  size
}) => {
  const highlightRow = highlightCondition && highlightCondition(record);

  return (
    <>
      <TableRow
        style={{
          fontSize: '14px',
          backgroundColor: highlightRow
            ? '#90F893'
            : striped && rowIndex % 2 === 1
            ? '#E1E1E1'
            : 'inherit'
        }}
      >
        {columns.map((column: any, index: number) => (
          <StyledTableCell
            key={`row-${record.id}-${index}`}
            size={size}
            sx={{
              width: column.width || '200px', // Apply dynamic width based on column configuration
              whiteSpace: 'nowrap' // Prevent text wrapping
            }}
          >
            {column.render ? (
              column.render({ value: record[column.key], record })
            ) : (
              <CellValue key={column.key} value={record[column.key]} />
            )}
          </StyledTableCell>
        ))}
        {expandedRowRender && (
          <StyledTableCell
            size={size}
            sx={{
              width: '100px', // Apply dynamic width based on column configuration
              whiteSpace: 'nowrap' // Prevent text wrapping
            }}
          >
            <Tooltip title={TooltipName}>
              <IconButton
                onClick={() => onExpandClick && onExpandClick(!expanded)}
              >
                {expanded ? (
                  <img src={DropDown} alt="" />
                ) : (
                  <img src={Dropup} alt="" />
                )}
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        )}
      </TableRow>
      {expanded && expandedRowRender && (
        <TableRow>
          <StyledTableCell colSpan={columns.length + 1}>
            {expandedRowRender(record)}
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};

export const ListTable: React.FC<ITableProps> = ({
  columns,
  rows = [],
  expandedRowRender,
  striped = false,
  TooltipName,
  loading = false,
  stickyHeader = false,
  containerStyle,
  highlightCondition,
  expandedRowIndex,
  setExpandedRowIndex,
  size = 'medium',
  disableHeader,
  setIsRowEditing,
  searchVal,
  setSearchVal,
  bordered,
  showSearch
}: ITableProps) => {
  const [order, setOrder] = useState<Order>('asc');
  let data: string[] = [];
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState(data[0]);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsInPage] = useState(
    useSelector((state: RootState) => state?.pagination?.rowsPerPage)
  );
  //   const [rowsPerPage, setRowsInPage] = useState(10);
  const [rowData, setRowData] = useState(rows);

  useEffect(() => {
    data = extractKeys(columns);
  }, []);

  useEffect(() => {
    setRowData(rows);
  }, [rows]);

  const extractKeys = (data: any) => {
    return data.map((item: any) => item.key);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (setExpandedRowIndex) {
      setExpandedRowIndex(null);
    }

    // setIsRowEditing(false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsInPage(parseInt(event.target.value, 10));
    dispatch(setRowsPerPage(event.target.value));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = React.useMemo(
    () =>
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0,
    [rowData]
  );

  const visibleRows = React.useMemo(
    () =>
      stableSort(rowData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rowData]
  );

  return (
    <Box sx={{ width: '100%' }}>
      {showSearch && (
        <EnhancedTableToolbar
          setRow={setRowData}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          rows={rows}
          setExpandedRowIndex={setExpandedRowIndex}
          setPage={setPage}
          setIsRowEditing={setIsRowEditing}
        />
      )}
      <StyledTableContainer
        style={containerStyle}
        hasData={visibleRows.length > 0}
      >
        <StyledTable stickyHeader={stickyHeader}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            expandedRowRender={expandedRowRender}
            rowCount={rows.length}
            columns={columns}
          />
          <TableBody>
            {loading &&
              [1, 2, 3, 4, 5].map((_, index) => (
                <TableRow key={`loading-row-${index}`}>
                  {columns.map(() => (
                    <TableCell
                      key={`loading-cell-${index}`}
                      size={size}
                      className=""
                    >
                      <Skeleton variant="text" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!loading &&
              visibleRows.map((row, rowIndex) => {
                return (
                  <Row
                    key={`row-${row.id}-${rowIndex}`}
                    record={row}
                    size={size}
                    analysis={row.analysis}
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    onExpandClick={expanded => {
                      setExpandedRowIndex(expanded ? rowIndex : null);
                    }}
                    expanded={expandedRowIndex === rowIndex}
                    TooltipName={TooltipName}
                    rowIndex={rowIndex}
                    striped={striped}
                    highlightCondition={highlightCondition}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
        {visibleRows.length == 0 && (
          <p
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Records Found
          </p>
        )}
      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

function useStateDispatch<T>(rows: Record[]): [any, any] {
  throw new Error('Function not implemented.');
}
