import {
  Drawer,
  Grid,
  Popover,
  styled,
  useMediaQuery,
  Box,
  LinearProgress,
  IconButton,
  Typography,
  Stack
} from '@mui/material';
import { Appbar } from 'components';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useEffect,
  useState
} from 'react';
import Navbar from 'components/sidebar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useWebSocket } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: '64px',
  minHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
  width: 'calc(100% - 15rem)',
  backgroundColor: '#ededed'
}));

enum FileStatus {
  uploading = 'uploading',
  uploadFailed = 'uploadFailed',
  uploadCancelled = 'uploadCancelled',
  uploadComplete = 'uploadComplete',
  waitingForUnzip = 'waitingForUnzip',
  unzipping = 'unzipping',
  unzipFailed = 'unzipFailed',
  completed = 'completed'
}

type UploadContext = {
  uploadFiles: (files: FileList, uploadUrl: string, token: string) => void;
  cancelUpload: (filename: string) => void;
  open: boolean;
  filesWithStatus: FileWithStatus[];
};
interface unzipProgress {
  progress: number;
  foldername: string;
}
type FileWithStatus = {
  filename: string;
  status: FileStatus;
  uplodProgress: number;
  unzippingProgress: number;
  filesPassed: number;
  filesFailed: number;
};

export const MyContext = createContext<UploadContext>({
  uploadFiles: () => {},
  cancelUpload: () => {},
  open: false,
  filesWithStatus: []
});

const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const workerRef = useRef<Worker | null>(null);
  const isMobile = useMediaQuery('(max-width:1400px)');
  const [filesWithStatus, setFilesWithStatus] = useState<FileWithStatus[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [minimize, setMinimize] = useState<boolean>(false);

  const [finished, setFinished] = useState<boolean>(false);

  const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL_MEDIA}/ws/media_manager`;
  useEffect(() => {
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const allFilesFinished = filesWithStatus.every(file =>
      [
        FileStatus.uploadFailed,
        FileStatus.uploadCancelled,
        FileStatus.unzipFailed,
        FileStatus.completed
      ].includes(file.status)
    );

    setFinished(allFilesFinished);
  }, [filesWithStatus]);

  useEffect(() => {
    if (finished) {
    }
  }, [finished]);

  const { socket, connectionStatus, sendMessage, reconnect } = useWebSocket(
    socketUrl || ''
  );

  function isvalidJSON(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        if (isvalidJSON(event.data)) {
          const message = JSON.parse(event.data);
          console.log(message, 'message');
          if (message.type == 'IN_PROGRESS') {
            setFilesWithStatus(prev =>
              prev.map(file =>
                file.filename === `${message.data.foldername}.zip`
                  ? {
                      ...file,
                      status: FileStatus.unzipping,
                      unzippingProgress: message.data.progress
                    }
                  : file
              )
            );
          }
          if (message.type == 'COMPLETED') {
            setFilesWithStatus(prev =>
              prev.map(file =>
                file.filename === `${message.data.foldername}.zip`
                  ? {
                      ...file,
                      status: FileStatus.completed,
                      unzippingProgress: message.data.progress,
                      filesFailed: message.data.number_of_files_failed,
                      filesPassed: message.data.number_of_files_successed
                    }
                  : file
              )
            );
          }
        }
      });
    }
  }, [socket]);

  const initializeWorker = () => {
    if (!workerRef.current) {
      workerRef.current = new Worker('/fileUploadWorker.js');
    }
    return workerRef.current;
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const uploadFiles = (files: FileList, uploadUrl: string, token: string) => {
    setOpen(true);
    const worker = initializeWorker();
    const newFiles: FileWithStatus[] = Array.from(files).map(file => ({
      filename: file.name,
      status: FileStatus.uploading,
      uplodProgress: 0,
      unzippingProgress: 0,
      filesPassed: 0,
      filesFailed: 0
    }));

    if (connectionStatus === 'closed') {
      reconnect();
    }

    setFilesWithStatus(prev => [...prev, ...newFiles]);

    worker.postMessage({ action: 'upload', files, uploadUrl, token });

    worker.onmessage = (event: MessageEvent) => {
      const { type, filename, progress, message } = event.data;

      if (type == 'progress') {
        setFilesWithStatus(prev =>
          prev.map(file =>
            file.filename === filename
              ? {
                  ...file,
                  status: FileStatus.uploading,
                  uplodProgress: progress
                }
              : file
          )
        );
      }
      if (type == 'success') {
        setFilesWithStatus(prev =>
          prev.map(file =>
            file.filename === filename && file.status !== FileStatus.completed
              ? {
                  ...file,
                  status: FileStatus.uploadComplete,
                  uplodProgress: 100
                }
              : file
          )
        );
      }

      if (type === 'error') {
        setFilesWithStatus(prev =>
          prev.map(file =>
            file.filename === filename
              ? {
                  ...file,
                  status: FileStatus.uploadFailed,
                  uplodProgress: 0
                }
              : file
          )
        );
      }
    };
  };

  const cancelUpload = (filename: string) => {
    const worker = workerRef.current;

    if (worker) {
      worker.postMessage({ action: 'cancel', filename });

      setFilesWithStatus(prev =>
        prev.map(file =>
          file.filename === filename
            ? { ...file, status: FileStatus.uploadCancelled }
            : file
        )
      );
    } else {
      alert('worker not found');
    }
  };

  return (
    <Grid container>
      <MyContext.Provider
        value={{ uploadFiles, cancelUpload, open, filesWithStatus }}
      >
        <Appbar
          mobile={isMobile}
          isMenuOpen={drawerOpen}
          onMenuClick={() => setDrawerOpen(!drawerOpen)}
        />

        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={drawerOpen}
          anchor="left"
          style={{
            position: 'relative',
            zIndex: 1000,
            width: '15rem'
          }}
          PaperProps={{
            sm: { width: '90%' }
          }}
          ModalProps={{ onBackdropClick: handleDrawerToggle }}
        >
          <Navbar />
        </Drawer>
        <Content>{children}</Content>
      </MyContext.Provider>

      {open && (
        <div
          style={{
            position: 'fixed',
            bottom: 10,
            left: 10,
            zIndex: 1000,
            backgroundColor: 'white',
            padding: '10px',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '4px'
          }}
        >
          <Box
            sx={{ p: 1, width: '400px', maxHeight: '400px', overflow: 'auto' }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>uploading files</Typography>

              <Stack direction={'row'}>
                <IconButton
                  size="small"
                  aria-label="cancel upload"
                  onClick={() => {
                    setMinimize(!minimize);
                  }}
                >
                  {minimize ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>

                {finished && (
                  <IconButton
                    size="small"
                    aria-label="cancel upload"
                    onClick={() => {
                      setFilesWithStatus([]);
                      setFinished(false);
                      setOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>

            {!minimize &&
              filesWithStatus.map(
                ({
                  filename,
                  status,
                  uplodProgress,
                  unzippingProgress,
                  filesPassed,
                  filesFailed
                }) => (
                  <Box key={filename} sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {filename}
                      </Typography>
                      {status === FileStatus.completed &&
                        filesFailed > 0 &&
                        filesPassed !== 0 && <> {filesFailed} skipped</>}
                      {status === FileStatus.completed && filesPassed == 0 && (
                        <> no valid files found</>
                      )}
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        {status}
                      </Typography>
                    </Box>
                    {status === FileStatus.uploading && (
                      <LinearProgress
                        variant="determinate"
                        value={uplodProgress}
                      />
                    )}
                    {status === FileStatus.unzipping && (
                      <LinearProgress
                        variant="determinate"
                        value={unzippingProgress}
                      />
                    )}
                  </Box>
                )
              )}
          </Box>
        </div>
      )}
    </Grid>
  );
};

export default PrivateLayout;
