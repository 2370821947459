import { Typography } from '@mui/material';
import { Modal } from 'components';
import React, { useEffect, useState } from 'react';

interface videoProps {
  img: any;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  streamError: string | null;
}
export const VideoModal: React.FC<videoProps> = ({
  img,
  onClose,
  open,
  loading,
  streamError
}) => {
  return (
    <Modal open={open} onClose={onClose} size="lg">
      {streamError && <Typography>{streamError}</Typography>}
      {loading ? (
        <>
          <div className="loader"> loading PLease wait...</div>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src={img} alt="" />
        </div>
      )}
    </Modal>
  );
};
