/* esling-disable */
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { PrivateLayout } from 'layouts';
import { ConfigurationListPage } from 'pages/configuration';

import { RootState } from 'store';

import urls from './privateRoutesConfig';
import publicUrls from './urls';

import { TrainPage } from 'pages/train';
import { Label } from '@mui/icons-material';
import { LabelAssist1 } from 'pages/LabelAssist1';

/**
 * List of routes available  for authenticated users
 * Also renders the "Private Layout" composition
 * @routes PrivateRoutes
 */
const PrivateRoutes = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Route to login page if token is not valid
   * @param {any} (
   * @returns {any}
   */
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(publicUrls.LOGIN.generate());
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && location.pathname.startsWith('/auth')) {
      const url = '/configuration';
      navigate(url);
    }
  }, []);
console.log("Objects",Object.values(urls))
  return (
    <PrivateLayout>
      <Routes>
        {Object.values(urls).map(route => {
          if (route.items) {
            return Object.values(route.items).map(subRoute => {
              return (
                <Route
                  key={subRoute.name}
                  path={`${route.route}${subRoute.route}`}
                  element={subRoute.component}
                />
              );
            });
          }
          return (
            <Route
              key={route.name}
              path={route.route}
              element={route.component}
            />
          );
        })}
        <Route path="/configuration/list" element={<ConfigurationListPage />} />

        <Route path="/train/:id" element={<TrainPage />} />
        <Route path="/labelAssist1/:id/:model_id" element={<LabelAssist1 />} />

        <Route path="*" element={<ConfigurationListPage />} />
      </Routes>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
