import { Grid, Stack, Tooltip } from '@mui/material';
import { Card, Typography } from 'components';
import React from 'react';

const GroupStatistics = () => {
  return (
    <>
      <Card style={{ backgroundColor: '#1E4E6A' }}>
        <Stack spacing={1}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
            color={'#FFFFFF'}
          >
            Statistics Across All Groups
          </Typography>
          <Grid container spacing={1} justifyContent={'space-between'}>
            {[
              { label: 'Total no. of Groups', value: '05' },
              { label: 'Total no. of Viewers', value: '32' },
              { label: 'Total no. of Configurations', value: '150' },
              { label: 'Total no. of Models Trained', value: '268' },
              {
                label: 'Total no. of Models Trained ( Incrementally )',
                value: '268'
              },
              { label: 'Total no. of Live Inference', value: '250' },
              { label: 'Total no. of Recorded Inference', value: '219' }
            ].map(({ label, value }, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={1.5}
                key={index}
                color={'#FFFFFF'}
                textAlign={'center'}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 'normal',
                    lineHeight: '1.5em',
                    minHeight: '4.5em',
                    display: '-webkit-box',
                    WebkitLineClamp: 2 // Limit to 2 lines
                  }}
                >
                  {label}
                </Typography>

                <Typography variant="h6" fontWeight="bold">
                  {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Card>
    </>
  );
};

export default GroupStatistics;
