/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type Report = typeof Report[keyof typeof Report];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Report = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;
