import React, { useState, useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import { Stack } from '@mui/material';
import { Button, Typography } from 'components';
import { useDeleteObjectDataId } from 'api/sdk';
import { useLocation, useNavigate } from 'react-router-dom';

interface DeleteModalProps {
  deleteModal: boolean;
  onClose: (data: boolean) => void;
  currentInferenceId: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteModal,
  onClose,
  currentInferenceId
}) => {
  const { mutateAsync: deleteObject, isLoading } = useDeleteObjectDataId();
  const navigate = useNavigate();

  return (
    <Modal open={deleteModal} onClose={() => onClose(false)}>
      <Typography>
        All the progress made on this dataset will be Erased
      </Typography>

      <Typography>are you sure you want to proceed?</Typography>
      <Stack direction={'row'} spacing={1} justifyContent={'center'}>
        <Button version="light" onClick={() => onClose(false)}>
          cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            deleteObject({ params: { data_id: currentInferenceId } }).then(
              res => {
                navigate('/Media/Media');
              }
            );
          }}
        >
          Proceed
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
