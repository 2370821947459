import React, { useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { Modal, Button } from 'components';
import EditIcon from '@mui/icons-material/Edit';
import LayersIcon from '@mui/icons-material/Layers';
import { BiLayerPlus } from 'react-icons/bi';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ObjSchema } from 'schemas';
import { useConfirm } from 'plugins/confirm';
import { v4 as uuidv4 } from 'uuid';
import { useSnack } from 'plugins/snack';

interface EditClassModalProps {
  EditClassModal: boolean;
  onClose: () => void;
  handleSync: (
    data: ObjSchema[],
    configuration_id: string,
    inference_id: string
  ) => Promise<any>;
  data: ObjSchema[];
  currentInferenceID: string;
  setData: React.Dispatch<React.SetStateAction<ObjSchema[]>>;
  syncLoading: boolean;
  refetchClass: () => void;
  dataLoading: boolean;
  dataFetching: boolean;
  type: boolean;
}

const EditClassModal: React.FC<EditClassModalProps> = ({
  EditClassModal,
  onClose,
  handleSync,
  data,
  currentInferenceID,
  syncLoading,
  setData,
  dataFetching,
  dataLoading,
  refetchClass,
  type
}) => {
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [editingClassId, setEditingClassId] = useState<string | null>(null);
  const [editingClassName, setEditingClassName] = useState<string>('');
  const [newClassName, setNewClassName] = useState('');
  const [newClassDescription, setNewClassDescription] = useState('');
  const confirm = useConfirm();
  const snack = useSnack();

  const handleCheckboxChange = (id: string) => {
    if (type) {
      const updatedSelection = selectedClasses.includes(id)
        ? selectedClasses.filter(item => item !== id)
        : [...selectedClasses, id];

      const remainingClasses = data.length - updatedSelection.length;

      if (remainingClasses < 2) {
        snack({
          message: 'there should be at least 2 classes for training dataset',
          severity: 'warning'
        });
        return;
      }
    } else {
      const updatedSelection = selectedClasses.includes(id)
        ? selectedClasses.filter(item => item !== id)
        : [...selectedClasses, id];

      const remainingClasses = data.length - updatedSelection.length;

      if (remainingClasses < 1) {
        snack({
          message: 'there should be at least one class  ',
          severity: 'warning'
        });
        return;
      }
    }

    setSelectedClasses(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const handleEditClick = (id: string, classname: string) => {
    setEditingClassId(id);
    setEditingClassName(classname);
  };
  const unbalelled = [
    'UNLABELED',
    'unlabeled',
    'Unlabeled',
    'Unlabeled',
    'unlabelled',
    'Unlabelled',
    'unlabelled',
    'Unlabelled'
  ];
  const handleSaveEdit = (id: string) => {
    if (!editingClassName.trim()) {
      snack({
        message: 'Class name cannot be empty.',
        severity: 'error'
      });
      return;
    }

    const isDuplicate = data.some(
      item =>
        item.classname.toLowerCase() ===
          editingClassName.trim().toLowerCase() && item.id !== id
    );

    if (isDuplicate) {
      snack({
        message: `Class name "${editingClassName}" already exists.`,
        severity: 'error'
      });
      return;
    }

    if (unbalelled.includes(editingClassName.trim())) {
      snack({
        message: `Class name "UNLABELED" is reserved for unlabeled images`,
        severity: 'error'
      });
      return;
    }

    setData((prev: ObjSchema[]) =>
      prev.map(item =>
        item.id === id ? { ...item, classname: editingClassName } : item
      )
    );

    snack({
      message: 'Class name edited successfully.',
      severity: 'success'
    });

    setEditingClassId(null);
    setEditingClassName('');
  };

  const handleCancelEdit = () => {
    setEditingClassId(null);
    setEditingClassName('');
  };

  const handleDeleteSelectedClasses = () => {
    setData(prev => prev.filter(item => !selectedClasses.includes(item.id)));
    snack({
      message: `class  deleted successfully`,
      severity: 'success'
    });
    setSelectedClasses([]);
  };

  const handleSaveNewClass = () => {
    if (!newClassName.trim()) {
      snack({
        message: 'Class name cannot be empty.',
        severity: 'error'
      });
      return;
    }

    const isDuplicate = data.some(
      classObj =>
        classObj.classname.toLowerCase() === newClassName.trim().toLowerCase()
    );

    if (isDuplicate) {
      snack({
        message: `Class name "${newClassName}" already exists.`,
        severity: 'error'
      });
      return;
    }
    if (unbalelled.includes(newClassName.trim())) {
      snack({
        message: `Class name "UNLABELED" is reserved for unlabeled images`,
        severity: 'error'
      });
      return;
    }
    const baseClass = data[0] || {};

    const newClass: ObjSchema = {
      id: uuidv4(),
      classname: newClassName,
      category: baseClass.category,
      configuration_id: baseClass.configuration_id,
      groupname: baseClass.groupname,
      data_id: baseClass.data_id,
      mode: baseClass.mode,
      images: [],
      is_defect_free: false,
      processed_id: null
    };

    setData(prev => [...prev, newClass]);

    setNewClassName('');
    setNewClassDescription('');
    snack({
      message: `New class "${newClassName}" added successfully.`,
      severity: 'success'
    });
  };
  const table = {
    backgroundColor: '#000',
    color: '#fff',
    padding: '8px',
    width: '30%',
    borderRight: '1px solid #fff'
  };

  const loading = useMemo(() => {
    return dataLoading || dataFetching || syncLoading;
  }, [dataFetching, dataLoading, syncLoading]);

  return (
    <Modal
      open={EditClassModal}
      onClose={() => {
        if (!loading) {
          confirm({
            title: 'Cancel Changes',
            content: 'Are you sure you want to cancel changes?'
          })
            .then(() => {
              onClose();
            })
            .catch(() => {});
        }
      }}
      size="lg"
    >
      <Box position="relative">
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1000}
            style={{
              backgroundColor: 'rgba(255, 255,  255, 0.7)',
              pointerEvents: 'none'
            }}
            onClick={e => e.stopPropagation()}
          >
            <CircularProgress />
          </Box>
        )}

        <Typography variant="h6" gutterBottom>
          Edit Class
        </Typography>
        <Grid container>
          <Grid item xs={8.5}>
            <TableContainer sx={{ overflowY: 'scroll', maxHeight: '30vh' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ ...table, width: '10%', borderRight: 'none' }}
                    >
                      {' '}
                    </TableCell>
                    <TableCell sx={{ ...table, width: '20%' }}>
                      Serial No.
                    </TableCell>
                    <TableCell sx={{ ...table, width: '50%' }}>
                      Class Name
                    </TableCell>
                    <TableCell
                      sx={{ ...table, width: '20%', borderRight: 'none' }}
                    >
                      Defect Free
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={loading}
                          checked={selectedClasses.includes(item.id)}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </TableCell>
                      <TableCell>{`${index + 1}`}</TableCell>
                      <TableCell>
                        {editingClassId === item.id ? (
                          <Stack direction={'row'} alignItems={'center'}>
                            <TextField
                              value={editingClassName}
                              onChange={e =>
                                setEditingClassName(e.target.value)
                              }
                              size="small"
                              fullWidth
                            />
                            <IconButton
                              color="primary"
                              onClick={() =>
                                !loading && handleSaveEdit(item.id)
                              }
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => !loading && handleCancelEdit()}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          <Stack direction={'row'} alignItems={'center'}>
                            <Tooltip title={item.classname}>
                              <Typography>
                                {item.classname.slice(0, 15)}
                                {item.classname.length > 15 ? '...' : ''}
                              </Typography>
                            </Tooltip>
                            <IconButton
                              onClick={() =>
                                !loading &&
                                handleEditClick(item.id, item.classname)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Stack>
                        )}
                      </TableCell>
                      <TableCell>
                        <>
                          <IconButton
                            onClick={() => {
                              setData(prev => {
                                return prev.map(item2 => {
                                  if (item2.id === item.id) {
                                    return {
                                      ...item,
                                      is_defect_free: !item.is_defect_free
                                    };
                                  }
                                  return item2;
                                });
                              });
                            }}
                            sx={{
                              color: item.is_defect_free ? 'red' : 'grey'
                            }}
                          >
                            <BiLayerPlus />
                          </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3.5} p={1}>
            <Stack sx={{ height: '95%' }} justifyContent={'space-between'}>
              <Link
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',

                  justifyContent: 'center'
                }}
                onClick={() => {
                  confirm({
                    title: 'Cancel Changess',
                    content: 'Are you sure you want to cancel changes?'
                  })
                    .then(res => {
                      refetchClass();
                      setSelectedClasses([]);
                      setEditingClassId(null);
                      setNewClassDescription('');
                      setNewClassName('');
                      setEditingClassName('');
                    })
                    .catch(() => {});
                }}
              >
                <RestoreIcon />
                <Typography>Restore To Defaults</Typography>
              </Link>

              <Button
                version="light"
                disabled={selectedClasses.length === 0}
                color="primary"
                onClick={() => [
                  confirm({
                    title: 'Delete Classes',
                    content:
                      'Deleteing the selected classes will also remove assosiated images . Do you want to continue?'
                  })
                    .then(() => handleDeleteSelectedClasses())
                    .catch(() => {})
                ]}
              >
                Delete Selected Classes
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid container mt={3} pt={2} sx={{ borderTop: '1px solid #fff' }}>
          <Grid item xs={8.5}>
            <Typography variant="subtitle1">
              Enter class name and description to add class.
            </Typography>
            <TextField
              fullWidth
              label="Class Name"
              variant="outlined"
              size="small"
              value={newClassName}
              onChange={e => setNewClassName(e.target.value)}
              sx={{ mt: 1 }}
            />
            <TextField
              fullWidth
              label="Description (Optional)"
              variant="outlined"
              size="small"
              value={newClassDescription}
              onChange={e => setNewClassDescription(e.target.value)}
              sx={{ mt: 1 }}
            />
          </Grid>

          <Grid item xs={3.5}>
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'end'}
              height={'100%'}
            >
              <Button
                version="light"
                onClick={handleSaveNewClass}
                sx={{ mt: 2 }}
                disabled={!newClassName}
              >
                Save New Class
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {/* Actions */}
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={2}
          mt={3}
          pt={2}
          sx={{ borderTop: '1px solid #fff' }}
        >
          <Button
            loading={loading}
            version="light"
            onClick={() =>
              confirm({
                title: 'Cancel Changes',
                content: 'Are you sure you want to cancel changes?'
              })
                .then(() => {
                  onClose();
                  setSelectedClasses([]);
                  setEditingClassId(null);
                  setNewClassDescription('');
                  setNewClassName('');
                  setEditingClassName('');
                })
                .catch(() => {})
            }
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={editingClassId !== null}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSync(
                data,
                data[0].configuration_id,
                currentInferenceID
              ).then(res => {
                onClose();
                setSelectedClasses([]);
                setSelectedClasses([]);
                setEditingClassId(null);
                setNewClassDescription('');
                setNewClassName('');
                setEditingClassName('');
              });
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditClassModal;
