import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  IconButton,
  CircularProgress,
  Skeleton
} from '@mui/material';
import Thumbnail from './Thumbnail';
import {
  ConfigSchema,
  ObjResponseSchemaCount,
  ObjSchema,
  QualitygeneratemaskRequest,
  SyncObjectsRequest
} from 'schemas';
import { Graph } from './graph';
import { SelectInput, TextField } from 'components/Form';

import Default from '../../assets/images/default.svg';
import forwardArrow from '../../assets/images/forwardArrow.svg';

import { useNavigate } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { useSyncObjectByDataid } from 'api/sdk';
import { v4 } from 'uuid';
import SkeletonLoader from 'components/SkeletonLoader';
import TrainingModal from './trainingModal';

interface MoveImageProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  reportsLoaded?: boolean;

  objects: ObjSchema[] | undefined;
  objectAll: ObjResponseSchemaCount | undefined;

  mode: string;
  setStep: (data: number) => void;
  viewMode?: boolean;
  currentInferenceID: string | undefined;

  selectedModel?: string;
}
interface FormValues {
  modelName: string;
  trainTestSplit: number;
  batchSize: number;
  learningRate: number;
  epochs: number;
  classesType: string;
}

type WarningModalProps = {
  onClose: () => void;
  message: string;
};

export const ViewDetails: React.FC<MoveImageProps> = ({
  selectedConfig,

  objects,
  objectAll,
  currentInferenceID,
  reportsLoaded,
  mode,
  configs,
  selectedModel,
  setStep,
  viewMode
}) => {
  const [selectedObject, setSelectedObject] = useState<string>('');

  const [modelArchitecture, setModelArchitecture] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [previewImageID, setPreviewImageID] = useState<number>(1);
  const [trainingModal, setTrainingModal] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [localImages, setLocalImages] = useState<ObjSchema[]>();

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const ImagesRowRef = useRef(null);
  const [imagesPerRow, setImagesPerRow] = useState(0);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorWarning, setShowErrorWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const navigate = useNavigate();
  const handelChangeImageIndex = (id: number) => {
    setPreviewImageID(id);
    setShowPreview(true);
  };

  useEffect(() => {
    if (objects && objects?.length > 0) {
      setSelectedObject(objects[0]?.classname);
    }
  }, [objects]);

  useEffect(() => {
    setShowOverlay(false);
  }, [previewImageID, selectedObject]);

  useEffect(() => {
    setLocalImages(objects);
  }, [objects]);

  const onCheckItem = (checked: boolean, item: string) => {
    if (checked) {
      setCheckedItems(prev => [...prev, item]);
    } else {
      setCheckedItems(prev => prev.filter(i => i !== item));
    }
  };

  const WarningModal: React.FC<WarningModalProps> = ({ onClose, message }) => (
    <Modal open={true} onClose={onClose}>
      <div>
        <Typography variant="h6">Warning: Class Count Below 200</Typography>
        <Typography variant="body1">{message}</Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Button onClick={onClose}>Close</Button>

          <Button
            onClick={() => {
              onClose();
              setModelArchitecture('');
              objects && handleSync(objects);
              setTrainingModal(true);
            }}
          >
            Proceed
          </Button>
        </Stack>
      </div>
    </Modal>
  );

  useEffect(() => {
    setCheckedItems([]);
    setPreviewImageID(0);
    setPage(1);
  }, [selectedObject]);

  const { mutateAsync: syncObject } = useSyncObjectByDataid();

  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      processed_id: null,
      configuration_id: selectedConfig || '',
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid ? inferenceid : currentInferenceID || '',
      params: { config_id: selectedConfig || '' }
    });
  };

  const mainObject = useMemo(() => {
    const o = localImages?.find(i => i.classname == selectedObject);
    return o ?? null;
  }, [localImages, objects, selectedObject]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const previewImageSrc = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = localImages?.find(i => i.classname === selectedObject)
      ?.images?.[previewImageID];
    if (showOverlay) {
      return image?.overlayed_url || Default;
    } else return image?.thumb_url || Default;
  }, [localImages, selectedObject, previewImageID, showOverlay]);

  const handlePrevImage = () => {
    setPreviewImageID(prev => Math.max(prev - 1, 0));
  };

  const handleNextImage = () => {
    setPreviewImageID(prev =>
      Math.min(prev + 1, (mainObject?.images?.length || 1) - 1)
    );
  };

  const handleUprow = () => {
    if (previewImageID > imagesPerRow) {
      setPreviewImageID(previewImageID - imagesPerRow);
    }
  };
  const handleDownrow = () => {
    if (previewImageID + imagesPerRow <= 99) {
      setPreviewImageID(previewImageID + imagesPerRow);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          handleUprow();
          break;
        case 'ArrowDown':
          handleDownrow();
          break;
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  useEffect(() => {
    setPreviewImageID((page - 1) * 100);
  }, [page]);

  const layoutRef = useRef(null);

  useEffect(() => {
    const updateImagesPerRow = () => {
      if (ImagesRowRef.current) {
        const boxWidth = (ImagesRowRef.current as HTMLElement)?.offsetWidth;
        const thumbnailWidth = 110;
        const imagesInRow = Math.floor(boxWidth / thumbnailWidth);
        setImagesPerRow(imagesInRow);
      }
    };
    updateImagesPerRow();
    window.addEventListener('resize', updateImagesPerRow);

    return () => {
      window.removeEventListener('resize', updateImagesPerRow);
    };
  }, []);
  const imagesPerPage = 100;

  const currentImages = mainObject?.images?.slice(
    (page - 1) * imagesPerPage,
    page * imagesPerPage
  );

  return reportsLoaded ? (
    <SkeletonLoader />
  ) : (
    <>
      <Box style={{ height: '38px' }}>
        {/* <Button
          version="light"
          onClick={() => {
            if (viewMode) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
        >
          Back
        </Button> */}
        <div
          onClick={() => {
            if (viewMode) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <img
              src={forwardArrow}
              alt="Forward Arrow"
              style={{
                marginRight: '8px',
                height: '12px'
              }}
            />
            <Typography
              variant="h6"
              fontSize="14px"
              style={{
                fontWeight: 'bold',
                textDecoration: 'underline',
                color: '#102633'
              }}
            >
              Back
            </Typography>
          </Box>
        </div>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid container item xs={12} md={12} lg={12} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Card
              style={{
                minHeight: '78vh',
                maxHeight: '78vh',
                minWidth: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px'
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  height: '15vh'
                }}
              >
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    fontSize="20px"
                    mb={1}
                    sx={{ fontWeight: 'bold' }}
                  >
                    Train Model
                  </Typography>

                  <Stack direction="row" spacing={6}>
                    <Stack>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Configuration :
                        </span>{' '}
                        {objectAll?.config_name}
                      </Typography>

                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Data-Set Name :
                        </span>{' '}
                        {localImages?.[0]?.groupname}
                      </Typography>
                    </Stack>
                  </Stack>
                  {objects && (
                    <Stack direction={'row'} mt={1}>
                      <SelectInput
                        label="Select Classes"
                        placeholder="Select Classes"
                        variant="outlined"
                        width={'400px'}
                        name="classes"
                        value={selectedObject}
                        options={objects
                          .filter(obj => obj.classname !== 'unlabeled')
                          .map(obj => ({
                            label: obj.classname,
                            value: obj.classname
                          }))}
                        onChange={value => setSelectedObject(value)} // Update the state on change
                      />
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={3} mt={5}>
                  <Stack direction="row" spacing={6} ml={6}>
                    <Stack>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Total Labelled Images :
                        </span>{' '}
                        {objectAll?.labeled_count}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          Total Classes :{' '}
                        </span>{' '}
                        {Object.keys(objectAll?.classname_counts || {}).length}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  {' '}
                  <Graph
                    height={'100%'}
                    width={'100%'}
                    labels={
                      objects
                        ?.filter(obj => obj.classname !== 'unlabeled')
                        ?.map(i => i.classname) || []
                    }
                    values={
                      objects
                        ?.filter(obj => obj.classname !== 'unlabeled')
                        ?.map(i => i?.images?.length || 0) || []
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={6} mb={1}>
                <div
                  style={{ border: '1px solid #696969', opacity: 0.6 }}
                ></div>
              </Grid>
              <Grid
                item
                xs={12}
                mt={1}
                mb={1}
                style={{
                  minHeight: '45vh',
                  maxHeight: '45vh',
                  overflow: 'scroll'
                }}
              >
                <Box ref={ImagesRowRef}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      gridTemplateRows: 'repeat(10, 1fr)',
                      gridTemplateColumns: 'repeat(10, 1fr)'
                    }}
                  >
                    {currentImages?.map((item, index) => (
                      <Grid item xs={1} key={item.id}>
                        <Thumbnail
                          id={item.id}
                          title=""
                          showImageGallery={true}
                          thumbnailUrl={item.thumb_url || ''}
                          index={(page - 1) * imagesPerPage + index}
                          checkable={false}
                          checked={checkedItems.includes(item.id)}
                          onCheck={checked => onCheckItem(checked, item.id)}
                          handelChangeImageIndex={handelChangeImageIndex}
                          type={
                            (page - 1) * imagesPerPage + index ===
                            previewImageID
                              ? 'OK'
                              : ''
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              {(mainObject?.images?.length ?? 0) > imagesPerPage && (
                <Stack
                  spacing={2}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'30px'}
                  direction={'row'}
                >
                  <Pagination
                    count={Math.ceil(
                      (mainObject?.images?.length ?? 0) / imagesPerPage
                    )}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Stack>
              )}
            </Card>

            {!viewMode && (
              <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                <Button
                  onClick={() => {
                    setStep(1);
                    setTrainingModal(false);
                  }}
                  style={{ width: '150px', height: '45px' }}
                  version="light"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !objectAll?.classname_counts || // Checks if classname_counts is null or undefined
                    Object.keys(objectAll.classname_counts || {}).length === 0 // Checks if it's an empty object
                  }
                  onClick={() => {
                    if (
                      objectAll &&
                      objectAll?.classname_counts &&
                      Object.keys(objectAll.classname_counts || {}).length < 2
                    ) {
                      setShowErrorModal(true); // Show error modal if number of classes < 2
                    } else {
                      const classesWithLowCount = Object.keys(
                        objectAll?.classname_counts || {}
                      ).filter(
                        className =>
                          ((objectAll?.classname_counts &&
                            objectAll.classname_counts[className]) ||
                            0) < 200
                      );

                      if (classesWithLowCount.length > 0) {
                        // If there are any classes with less than 200 images, show the warning modal
                        setShowErrorWarning(true);
                        setWarningMessage(
                          `Classes with less than 200 images: ${classesWithLowCount.join(
                            ', '
                          )}`
                        );
                      } else {
                        setModelArchitecture('');

                        objects && objects?.length > 0 && handleSync(objects);
                        setTrainingModal(true);
                      }
                    }
                  }}
                  style={{ width: '150px', height: '45px' }}
                >
                  Proceed
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>

      {showErrorWarning && (
        <WarningModal
          onClose={() => setShowErrorWarning(false)}
          message={warningMessage} // Display the message with class names
        />
      )}

      {/* Error Modal */}
      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <Typography variant="h6" mb={2}>
          Error
        </Typography>
        <Typography variant="body1" mb={3}>
          Minimum 2 Classes Needed For Supervised Training{' '}
        </Typography>
        <Button
          onClick={() => setShowErrorModal(false)}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
      </Modal>
      <TrainingModal
        currentInferenceID={currentInferenceID || ''}
        mode={mode}
        objectAll={objectAll}
        selectedConfig={selectedConfig || ''}
        setTrainingModal={setTrainingModal}
        trainingModal={trainingModal}
        setStep={setStep}
        selectedModel={selectedModel}
      />
      <Modal
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev - 1);
              }}
              disabled={previewImageID <= 1}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <img
              src={previewImageSrc || ''}
              style={{
                height: '50vh',
                objectFit: 'contain',
                width: '80%'
              }}
              alt=""
            />
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev + 1);
              }}
              disabled={previewImageID + 1 >= (mainObject?.images?.length || 0)}
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
            <Box>
              Image Number :{previewImageID + 1}/{mainObject?.images?.length}
            </Box>
            <Box>Image Class Type : {selectedObject}</Box>
          </Stack>
        </>
      </Modal>
    </>
  );
};
