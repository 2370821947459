import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';
import { Main } from './main';

import { ViewDetails } from './viewDetails';
import PreprocessedData from 'components/filemanager/preProcessedData';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  useGetModelList,
  useGetObjectsByDataId,
  useListCameras,
  useListConfigurations,
  useStartTraining,
  useSyncObjectByDataid
} from 'api/sdk';
import {
  LabelAsisitRequestQualityControl,
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest,
  TrainingResultSchema
} from 'schemas';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setAllConfigs } from 'store/configSlice';
import { useSnack } from 'plugins/snack';
import DropClass from './DropClass';

export const TrainPage: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [viewMode, setViewMode] = useState<boolean>(false);
  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();
  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });
  const [models, setModels] = useState<TrainingResultSchema[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();
  const [isIncremental, setIsIncrementl] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjects] = useState<ObjSchema[]>([]);
  const [objectsinModels, setObjectsinModels] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const snack = useSnack();
  const dispatch = useDispatch();
  const { mutateAsync: syncObject } = useSyncObjectByDataid();
  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      processed_id: null,
      configuration_id: selectedConfig || '',

      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid ? inferenceid : currentInferenceID || '',
      params: { config_id: selectedConfig || '' }
    });
  };

  const onChange = (num: number) => {
    setStep(num);
  };
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const { refetch: refetchModels } = useGetModelList({
    config_id: selectedConfig || ''
  });
  const [reportsLoaded, setReportsLoaded] = useState(true);

  const { refetch: currentObjectsRefetch, isLoading: objectsloading } =
    useGetObjectsByDataId(currentInferenceID || '');

  useEffect(() => {
    setReportsLoaded(true);

    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
        setObjectAll(res?.data?.data);
        setReportsLoaded(false);
      });
    }
  }, [currentInferenceID]);

  useEffect(() => {
    refetchModels().then(res => {
      setModels(res.data?.data || []);
    });
  }, [selectedConfig, selectedModel]);
  console.log(objectsinModels, 'a');
  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          setCurrentInferenceID={setCurrentInferenceID}
          setViewMode={setViewMode}
          setStep={setStep}
          step={step}
          models={models || []}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          setIsIncrementl={setIsIncrementl}
          setObjectsinModels={setObjectsinModels}
        />
      )}

      {step == 2 && (
        <PreprocessedData
          type="TRAINING"
          selectedConfig={selectedConfig || ''}
          configs={configs || []}
          onSelect={(e, o) => {
            if (isIncremental) {
              setCurrentInferenceID(e);
              setSelectedObjects(o || []);

              if (o) {
                const isNoOverlap = o.every(
                  x => !objectsinModels.includes(x.classname)
                );

                // condition2: fullmatch
                const fullMatch = o.every(x =>
                  objectsinModels.includes(x.classname)
                );

                const fullMatchWithAdditionalClasses =
                  fullMatch &&
                  o.some(x => objectsinModels.includes(x.classname));

                // condition4: partialMatch
                const partialMatch = o.some(
                  x => !objectsinModels.includes(x.classname)
                );

                if (isNoOverlap) {
                  snack({
                    message:
                      'There are no overlaps with the selected models. Please select a different dataset.',
                    severity: 'error'
                  });
                  return;
                }

                if (fullMatch) {
                  setStep(3);
                  return;
                }

                if (fullMatchWithAdditionalClasses) {
                  setStep(3);
                  return;
                }
                if (partialMatch) {
                  snack({
                    message:
                      'not all the selected objects are in the selected models. Please select a different dataset.',
                    severity: 'error'
                  });
                }
              }

              setStep(3);
            } else {
              setCurrentInferenceID(e);
              setStep(3);
            }
          }}
          onCancel={() => {
            setStep(1);
          }}
          isIncremental={isIncremental}
        />
      )}

      {step == 3 && (
        <ViewDetails
          onChange={onChange}
          selectedConfig={selectedConfig}
          objects={currentObjects}
          objectAll={objectAll}
          currentInferenceID={currentInferenceID}
          mode={isIncremental ? 'INCREMENTAL_TRAINING' : 'TRAINING'}
          setStep={setStep}
          viewMode={viewMode}
          configs={configs}
          reportsLoaded={reportsLoaded}
          selectedModel={selectedModel}
        />
      )}

      <DropClass
        handleSync={handleSync}
        modelsClass={objectsinModels}
        open={open}
        onClose={() => setOpen(false)}
        selectedObjects={selectedObjects}
        selectedConfig={selectedConfig || ''}
        setCurrentInferenceID={setCurrentInferenceID}
        setStep={setStep}
      />
    </>
  );
};
