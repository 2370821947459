import { Button, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import Delete from '../icons/deleteIcon.svg';
import User from '../icons/userprofile.svg';
import { FORMAT_DATE_ONLY, dateToString } from 'utils';
interface LicenseDetailsProps {}

const LicenseDetails: React.FC<LicenseDetailsProps> = () => {
  // Select profile information from the Redux store
  const profile = useSelector((state: RootState) => state.auth.profile);

  const matchesLg = useMediaQuery('(min-width:960px)');
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={12} md={4} lg={5} ml={1}>
        <Grid container xs={12} spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Grid container xs={12}>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span
                  style={{
                    marginRight: '1vw',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Purchased By:
                </span>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {profile?.license?.purchased_by}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Grid container xs={12}>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span
                  style={{
                    marginRight: '1vw',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Purchased At:
                </span>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {' '}
                  {dateToString(
                    String(profile?.license?.purchased_at),
                    FORMAT_DATE_ONLY
                  )}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Grid container xs={12}>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span
                  style={{
                    marginRight: '1vw',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Expires At:
                </span>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-start'}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {dateToString(
                    String(profile?.license?.expires_at),
                    FORMAT_DATE_ONLY
                  )}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md lg={4}>
        <Grid container xs={12}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LicenseDetails;
