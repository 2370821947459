/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type VideoStatus = typeof VideoStatus[keyof typeof VideoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  ABORTED: 'ABORTED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
} as const;
