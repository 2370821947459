/* eslint-disable  */
// Dependency imports
import {
  Box,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material';

import React, { useState } from 'react';

import { Button, RecordValue, Typography } from 'components';

import True from '../../assets/images/possitiveIcon.svg';
import False from '../../assets/images/negativeIcon.svg';
import EditViewer from '../../assets/images/EditExpandable.svg';
import AddViewer from '../../assets/images/AddViewer.svg';
import PlusIcon from '../../assets/images/PlusIcon.svg';
import ViewerCreation from './createViewerModal';
import { RemoveUserModal } from './removeUserModel';

// Style imports

interface DropdownListProps {
  record: any;
  refetch: () => Promise<any>; // Define refetch as a function that returns a Promise
  SetGroupList: React.Dispatch<React.SetStateAction<any[]>>; // Define SetGroupList to match the type of the state setter function
}

export const DropdownList = ({
  record,
  refetch,
  SetGroupList
}: DropdownListProps) => {
  const ActionToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  const styleContainerCell = {
    flex: 1,
    padding: '10px 0'
  };

  const styleHeaderCell = {
    flex: 1,
    padding: '10px 0',
    whiteSpace: 'nowrap'

    // background: '#00000029'
  };
  const breakStyle = {
    height: '30px',
    width: '2px',
    marginTop: '5px',
    background: '#00000029'
  };
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [viewerValue, setViewerValue] = React.useState([]);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = React.useState(false);
  const [UserName, setUserName] = useState<RecordValue | RecordValue[]>('');
  const [UserId, setUserId] = useState<RecordValue | RecordValue[]>('');
  console.log('record', record);
  return (
    <>
      <Stack direction={'column'} sx={{ background: '#F5F5F5' }}>
        <Stack
          direction="row"
          gap={2}
          alignItems={'center'}
          justifyContent="space-between"
          sx={{ marginBottom: 1, padding: 2 }}
        >
          <Typography
            component="div"
            sx={{ fontSize: 14, fontWeight: 600, marginLeft: '5px' }}
            color={'#102633'}
          >
            Viewers Details
          </Typography>
          <Typography
            component="div"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              textDecoration: 'underline',
              marginRight: '5px'
            }}
            color={'#102633'}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => {
                setCreateModalOpen(true);
                setViewerValue(record);
                // setViewerValue([]);
              }}
            >
              {' '}
              <img src={PlusIcon}></img> Add Viewers
            </Stack>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems={'center'}
          justifyContent="space-between"
          sx={{ marginBottom: 1 }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, marginLeft: '10px' }}
              color={'#102633'}
            >
              Viewers
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Configuration & Data Manager
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Training
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Incremental Training
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Advance Settings
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Recorded Inference
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Live Inference{' '}
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Novelty Detection
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={styleHeaderCell}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 600 }}
              color={'#102633'}
            >
              Actions
            </Typography>
            <Stack />
          </Stack>
        </Stack>
        <Box
          sx={{
            borderBottom: '2px solid #00000029',

            marginBottom: 2
          }}
        />
        {record?.UserList?.length > 0 &&
          record?.UserList.map((user: any) => (
            <Stack
              key={record?.id}
              direction="row"
              gap={2}
              alignItems={'center'}
              justifyContent="space-between"
              sx={{ marginBottom: 1 }}
            >
              <Stack
                direction="column"
                alignItems="center"
                //   justifyContent="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.username}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('DATASHARING') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('TRAINING') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('INCTRAINING') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('ADVANCEDSETTINGS') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('RECORDEDINFERENCE') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('LIVEINFERENCE') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  {user?.features_list?.includes('NOVELTY') ? (
                    <img src={True}></img>
                  ) : (
                    <img src={False}></img>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color={'#102633'}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={styleContainerCell}
                    gap={2}
                  >
                    {' '}
                    <Tooltip title={'Manage Access'}>
                      <img
                        src={EditViewer}
                        onClick={() => {
                          setViewerValue(user);
                          setCreateModalOpen(true);
                        }}
                      ></img>
                    </Tooltip>
                    <Tooltip title={'Remove Viewer'}>
                      <img
                        src={AddViewer}
                        alt=""
                        onClick={() => {
                          setUserName(user?.username);
                          setUserId(user?.id);
                          setDeleteUserModalOpen(true);
                        }}
                      />
                    </Tooltip>
                  </Stack>
                </Typography>
              </Stack>
            </Stack>
          ))}
        {record?.UserList?.length == 0 && (
          <Stack
            key={record?.id}
            direction="row"
            gap={2}
            alignItems={'center'}
            justifyContent="space-between"
            sx={{ marginBottom: 1 }}
          >
            {' '}
            <Stack
              direction="column"
              alignItems="center"
              //   justifyContent="center"
              sx={styleContainerCell}
              mb={2}
              mt={2}
            >
              <Typography sx={{ fontSize: 20 }} color={'#707070'}>
                Create Viewer to view the list
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <ViewerCreation
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSucess={() => {
          setCreateModalOpen(false);
          refetch().then(res => {
            res.data?.data && SetGroupList(res.data?.data);
          });
        }}
        record={record}
        viewerValue={viewerValue}
      />
      <RemoveUserModal
        open={deleteUserModalOpen}
        onClose={() => {
          setDeleteUserModalOpen(false);
        }}
        UserName={UserName}
        UserId={UserId}
        record={record}
        onSucess={() => {
          setDeleteUserModalOpen(false);
          refetch().then(res => {
            res.data?.data && SetGroupList(res.data?.data);
          });
        }}
      />
    </>
  );
};
