/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type ImageType = typeof ImageType[keyof typeof ImageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageType = {
  objects: 'objects',
  clips: 'clips',
  video: 'video',
} as const;
