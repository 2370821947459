import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import styled from '@emotion/styled';
import { Typography } from '../Typography/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface DatalistProps {
  classname: string;
  noOfImages: number;

  onRemove?: (data: string) => void;
  type?: string;
}

const Datalist: React.FC<DatalistProps> = ({
  classname,
  noOfImages,
  onRemove,
  type
}) => {
  return (
    <Grid style={{ backgroundColor: '#E3EFF6' }} mt={1} mb={1} p={1}>
      <Stack
        direction={'row'}
        justifyContent={onRemove ? 'space-between' : ''}
        pl={1}
        pr={1}
        spacing={2}
      >
        <img src={Folder} alt="" style={{ width: '50px', height: '40px' }} />
        {onRemove && (
          <IconButton
            onClick={() => {
              onRemove && onRemove(classname);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
        {!onRemove && (
          <Box>
            <Typography>
              {' '}
              {type == 'UNLABELLED' ? 'Folder Name' : 'Classname'} : {classname}
            </Typography>
            <Typography>No. of Images : {noOfImages}</Typography>
          </Box>
        )}
      </Stack>
      {onRemove && (
        <>
          <Typography>
            {' '}
            {type == 'UNLABELLED' ? 'Folder Name' : 'Classname'} : {classname}
          </Typography>
          <Typography>No. of Images : {noOfImages}</Typography>
        </>
      )}
    </Grid>
  );
};

export default Datalist;
