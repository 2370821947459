import React, { useState, useEffect, useRef } from 'react';

import Modal from 'components/Modal';
import { ConfigSchema } from 'schemas';
import { Stack, Typography, TextField } from '@mui/material';
import { SelectInput } from 'components/Form';
import { ISelectInputOption } from 'components/Form/Inputs/SelectInput';
import { Button } from 'components/Button/Button';
import { useCreatePreProcessedObjects } from 'api/sdk';
import { useNavigate } from 'react-router-dom';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface configSelectionModalProps {
  configSelectionModalOpen: boolean;
  setConfigSelectionModalOpen: (data: boolean) => void;

  labeled_data: string[];
  semilabeled_data: string[];
  unlabelled_data: string[];
  mode: string;
}

const ConfigSelectionModal: React.FC<configSelectionModalProps> = ({
  configSelectionModalOpen,
  setConfigSelectionModalOpen,

  mode,
  labeled_data,
  semilabeled_data,
  unlabelled_data
}) => {
  const [groupName, setGroupName] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedConfig, setSelectedConfig] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const configs = useSelector((state: RootState) => state.config.allConfigs);

  const { mutateAsync: createObject, isLoading } =
    useCreatePreProcessedObjects();

  const createObjects = async (
    configid: string,
    group_name: string,
    labeled_data: string[],
    semilabeled_data: string[],
    unlabelled_data: string[]
  ) => {
    const res = await createObject({
      configId: configid,
      data: {
        labeled_data: labeled_data,
        semilabeled_data: semilabeled_data,
        unlabeled_data: unlabelled_data
      },
      params: { group_name: group_name, mode: mode }
    });
    return res.data;
  };

  const options: ISelectInputOption[] = configs
    ? configs?.map(i => {
        return { label: i.name, value: i.id };
      })
    : [];
  return (
    <Modal
      open={configSelectionModalOpen}
      onClose={() => {
        setConfigSelectionModalOpen(false);
        setError(undefined);
        setGroupName('');
      }}
    >
      <Typography mt={2} sx={{ textAlign: 'center' }}>
        {' '}
        Select the appropriate configuration
      </Typography>
      <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
        and enter dataset name to save your dataset.
      </Typography>

      <SelectInput
        name="configuration"
        variant="outlined"
        placeholder="Select Configuration"
        value={selectedConfig}
        options={options}
        onChange={e => {
          setSelectedConfig(e);
          dispatch(setSelectedConfiguration(e));
        }}
      />
      <Stack>
        <TextField
          name=""
          variant="outlined"
          placeholder="Enter Dataset name"
          size="small"
          fullWidth
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
        />
        {error && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            {' '}
            {error}{' '}
          </Typography>
        )}
      </Stack>

      <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
        <Button
          version="light"
          onClick={() => {
            setConfigSelectionModalOpen(false);
            setError(undefined);
            setGroupName('');
          }}
        >
          {' '}
          cancel{' '}
        </Button>
        <Button
          loading={isLoading}
          disabled={!selectedConfig || groupName.length == 0 || isLoading}
          version="dark"
          onClick={() => {
            createObjects(
              selectedConfig,
              groupName,
              labeled_data,
              semilabeled_data,
              unlabelled_data
            )
              .then(res => {
                navigate(`/labelAssist1/${res}/null`);
                setConfigSelectionModalOpen(false);
              })
              .catch(error => {
                console.log(error);
                setError(error.response.data.detail);
                setTimeout(() => {
                  setError(undefined);
                }, 3000);
              });
          }}
        >
          {' '}
          save{' '}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ConfigSelectionModal;
