import { useState, useCallback, useEffect } from 'react';

export const useWebSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    'connecting' | 'open' | 'closed'
  >('connecting');

  const sendMessage = useCallback(
    (message: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      }
    },
    [socket]
  );

  const initializeSocket = useCallback(() => {
    setConnectionStatus('connecting');
    const newSocket = new WebSocket(url);
    setSocket(newSocket);
    return newSocket;
  }, [url]);

  const reconnect = useCallback(() => {
    if (connectionStatus === 'closed') {
      initializeSocket();
    }
  }, [connectionStatus, initializeSocket]);

  useEffect(() => {
    const newSocket = initializeSocket();

    return () => {
      newSocket.close();
    };
  }, [initializeSocket]);

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        setConnectionStatus('open');
        const pingInterval = setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send('ping');
          }
        }, 5000);

        socket.onclose = () => {
          clearInterval(pingInterval);
          setConnectionStatus('closed');
        };
      };

      socket.onerror = () => {
        setConnectionStatus('closed');
      };

      return () => {
        socket.close();
      };
    }
  }, [socket]);

  return {
    socket,
    connectionStatus,
    sendMessage,
    reconnect
  };
};
