import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import {
  Contents,
  DirectoriesContents,
  DirectoriesContentsResponse,
  FileType
} from '../../schemasMedia';
import Folder from '../../assets/images/Folder.png';
import DraggableItem from './Draggable';
interface ListViewProps {
  files: Contents[];
  setSearchMode: (data: boolean) => void;
  selectCurrentDirectory: (path: string) => void;

  setSearchQueryText: (data: string) => void;
  setSearchQuery: (data: string) => void;
  // setCurrentDirectory: (data: string) => void;
}

const ListView: React.FC<ListViewProps> = ({
  files,
  setSearchMode,
  setSearchQueryText,
  setSearchQuery,
  selectCurrentDirectory
}) => {
  const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    border: `1px solid #ededed`,
    padding: '2px',
    backgroundColor: '#ededed',
    position: 'sticky',
    top: 0,
    zIndex: 1
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '10px'
  }));

  return (
    <TableContainer
      component={Paper}
      sx={{
        overflowY: 'auto',
        borderRadius: '8px',
        height: '100%'
      }}
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableHeader align="left">
              <Typography
                variant="caption"
                style={{ fontWeight: 'bold', width: '100px' }}
              >
                Name
              </Typography>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Path
              </Typography>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Created Time
              </Typography>
            </StyledTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.length > 0 &&
            files.map((item, index) => (
              <TableRow key={index}>
                <StyledTableCell align="left">
                  <DraggableItem id={item.name || ''} path={item.path || ''}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',

                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSearchMode(false);
                        selectCurrentDirectory(item.path || '');

                        setSearchQueryText('');
                        setSearchQuery('');
                      }}
                    >
                      <img
                        src={Folder}
                        alt=""
                        style={{
                          width: '50px',
                          height: '40px',
                          transition: 'transform 0.3s ease'
                        }}
                        onMouseEnter={e => {
                          e.currentTarget.style.transform = 'scale(1.05)';
                        }}
                        onMouseLeave={e => {
                          e.currentTarget.style.transform = 'scale(1)';
                        }}
                      />
                      {item.name}
                    </div>
                  </DraggableItem>
                </StyledTableCell>
                <StyledTableCell align="left">{item.path}</StyledTableCell>
                <StyledTableCell align="left">
                  {item.created_time &&
                    new Date(item.created_time).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListView;
