/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type LabelAssistMode = typeof LabelAssistMode[keyof typeof LabelAssistMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LabelAssistMode = {
  balanced: 'balanced',
  fast: 'fast',
  accuracy: 'accuracy',
} as const;
