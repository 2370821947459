/* eslint-disable */
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField
} from '@mui/material';
import {
  useCreateObjects,
  useGetObjectsByDataId,
  useLiveInference,
  useStartInference,
  useStartLiveInference,
  useSyncObjectByDataid
} from 'api/sdk';
import { Modal, Typography } from 'components';
import { SelectInput } from 'components/Form';

import { Snack } from 'plugins/snack/Snack';
import { config } from 'process';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CameraSchema,
  CreateObjectSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema,
  ObjSchemaInferenceId,
  SyncObjectsRequest
} from 'schemas';
import { RootState } from 'store';
import { v4 } from 'uuid';
import * as Yup from 'yup';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSucess: () => void;
  ModelList: any;
  objects: ObjSchema[];

  InferenceId: string | undefined;

  count: number | undefined;
  setSelectedModel: (modalID: string) => void;
  selectedModel: string | undefined;
  InferenceListArray: () => Promise<void>;
  refetchInference: () => void;
  inferenceValue: any;
}

interface IFormValues {
  model_name: string;
  number_of_image: number | null;
  infer_all: boolean;
  specific_number: number | null;
}

export const CreateModalLive: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,

  ModelList,
  InferenceId,
  count,
  selectedModel,
  InferenceListArray,
  setSelectedModel,
  objects,

  inferenceValue,
  refetchInference
}) => {
  const [values, setValues] = useState<IFormValues>({
    model_name: '',
    number_of_image: 50,
    infer_all: true,
    specific_number: 50
  });
  const [cameraList, setCameraList] = useState<CameraSchema[]>([]);
  const [camera, setCamera] = useState('');

  const [newInferenceID, setNewInferenceID] = useState<string>('');
  const [newObjects, setNewObjects] = useState<ObjSchema[]>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [proceedDisable, setProceedDisable] = useState(true);
  const { mutateAsync, isLoading } = useStartInference();
  const { mutateAsync: startLiveInference } = useStartLiveInference();
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const resetValues = () => {
    setValues({
      model_name: '',
      number_of_image: 50,
      infer_all: true,
      specific_number: 50
    });
  };
  useEffect(() => {
    selectedConfig && handleCameraList();
  }, [open, selectedConfig]);

  useEffect(() => {
    if (selectedModel && camera) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [selectedModel, camera]);

  const handleTriggerLiveInference = () => {
    startLiveInference({
      data: {
        camera_id: camera?.toString() ?? '',
        model_id: selectedModel?.toString() ?? ''
      }
    })
      .then(() => {
        refetchInference();
        onSucess();

        onClose();
        Snack({
          message: 'PipeLine Triggered successfully',
          severity: 'success'
        });
      })
      .catch(error => {
        setError(error.response.data.detail);
        setProceedDisable(false);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      });
  };

  const handleCameraList = async () => {
    const token = localStorage.getItem('token');

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/configuration/${selectedConfig}/cameras`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      setCameraList(result);
    } catch (error) {}
  };
  // const handleCameraList = async () => {
  //   const token = localStorage.getItem('token');

  //   const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/configuration/${selectedConfig}/cameras`;
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });

  //     if (!response.ok) {
  //       // Fetch API does not throw an error for non-2xx responses, so handle this explicitly
  //       const errorData = await response.json();
  //       throw new Error(errorData.detail || 'Failed to fetch camera list.');
  //     }

  //     const result = await response.json();
  //     setCameraList(result);
  //     console.log('Result', result);
  //   } catch (error: any) {
  //     console.log('Error:', error);
  //     // Handle error gracefully
  //     setError(error.message || 'An unknown error occurred.');
  //   }
  // };

  useEffect(() => {
    if (open && ModelList.length === 1) {
      setSelectedModel(ModelList[0].model_id);
    }
  }, [open, ModelList, setSelectedModel]);

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const { data: objectData, refetch: currentObjectsRefetch } =
    useGetObjectsByDataId(newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);
  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByDataid();
  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      processed_id: null,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'TRAINING'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid,
      params: { config_id: configID || '' }
    });
  };
  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = objects.find(j => j.classname === i.classname);

        temp2 = {
          ...i,
          images: currentObj?.images || []
        };

        transformedObjects.push(temp2);
      });

      handleSync(
        transformedObjects,
        selectedConfig || '',
        transformedObjects[0].data_id || ''
      ).then(res => {
        // handleTriggerLiveInference(newInferenceID);
      });
    }
  }, [newObjects, objects]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues();
      }}
      title="Select Camera and Model to Start Inference"
      size="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput
            name="Camera"
            label="Select Camera"
            options={
              (cameraList &&
                cameraList?.map((camera: any) => ({
                  label: camera?.name,
                  value: camera?.id
                }))) ||
              []
            }
            placeholder={'Select Camera'}
            value={camera}
            variant="outlined"
            onChange={value => {
              setCamera(value);
              setErrorMessage(null);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            name="Model"
            label="Select Model"
            options={
              ModelList?.filter(
                (model: any) => model?.status === 'COMPLETED'
              ).map((model: any) => ({
                label: model?.model_name,
                value: model?.model_id
              })) || []
            }
            placeholder={'Select Model'}
            value={selectedModel}
            variant="outlined"
            onChange={value => {
              setSelectedModel(value);
              setErrorMessage(null);
            }}
          />
        </Grid>
      </Grid>
      {error && (
        <Typography variant="caption" sx={{ color: 'red' }}>
          {' '}
          {error}{' '}
        </Typography>
      )}
      <Grid item xs={12} mt={2} container justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            setError(undefined);
            resetValues();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={proceedDisable}
          style={{ marginLeft: '8px' }}
          onClick={() => {
            setProceedDisable(true);
            handleTriggerLiveInference();
          }}
        >
          Proceed
        </Button>
      </Grid>
    </Modal>
  );
};
