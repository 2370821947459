/* eslint-disable */
import {
  FormControlLabel,
  Grid,
  MobileStepper,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import {
  useCreateConfiguration,
  useCreateGroup,
  useFeaturesList
} from 'api/sdk';
import { Button, RecordValue } from 'components';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessLevel, Analytics } from 'schemas';
import * as Yup from 'yup';
import { TextField } from 'components/Form';
import { useSnack } from 'plugins/snack';
import { convertServerErrors } from 'utils/form';

interface IProps {
  initialValues: IformValues;
  onSubmit: (values: IformValues) => void;
  onCancel: () => void;
  featuresList: RecordValue | RecordValue[];
  GrpName: any | any[];
  GrpId: any | any[];
  editStatus: boolean;
  GrpShare: any | any[];
}

interface IformValues {
  name: string;
  global_share: boolean;
  features_list: string[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Group name is required')
    .min(6, 'Group name must be at least 6 characters')
    .max(50, 'Group name must be less than 50 characters')
    .matches(
      /^[a-zA-Z0-9 @\-_()\[\]{}<>:]+$/, // Updated regex pattern
      'Invalid characters. Only alphanumeric, space, dash, and underscore, @, (), [], {}, <>, : are allowed.'
    )
    .max(50, 'Group name must be less than 50 characters')
});

const CameraNameForm: React.FC<IProps> = ({
  onSubmit,
  initialValues,
  onCancel,
  featuresList,
  GrpName,
  GrpId,
  GrpShare,
  editStatus
}) => {
  const { mutateAsync, isLoading } = useCreateGroup();
  const { mutateAsync: EditFeaturesList, isLoading: isEditLoading } =
    useFeaturesList();

  const [globalDetails, setGlobalDetails] = useState(GrpShare);

  const snack = useSnack();
  console.log('GrpShare', GrpShare);
  const defaultInitialValues: IformValues = {
    name: editStatus && GrpName ? GrpName : initialValues.name,

    global_share: editStatus && GrpShare ? true : false,
    features_list: []
  };
  // Initial state with all switches set to false by default
  const [switchesState, setSwitchesState] = useState({
    dataSharing:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('DATASHARING')
        ? true
        : false,
    training:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('TRAINING')
        ? true
        : false,
    incrementalTraining:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('INCTRAINING')
        ? true
        : false,
    advancedSettings:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('ADVANCEDSETTINGS')
        ? true
        : false,
    recordedInference:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('RECORDEDINFERENCE')
        ? true
        : false,
    liveInference:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('LIVEINFERENCE')
        ? true
        : false,
    noveltyDetection:
      editStatus &&
      Array.isArray(featuresList) &&
      featuresList.includes('NOVELTY')
        ? true
        : false
  });

  const handleFormSave = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    // Create an array for the features list dynamically based on switchesState
    const featuresList = Object.keys(switchesState)
      .filter(key => switchesState[key as keyof typeof switchesState]) // Narrow the key type
      .map(key => {
        // Map the switch state keys to their corresponding feature names
        switch (key) {
          case 'dataSharing':
            return 'DATASHARING';
          case 'training':
            return 'TRAINING';
          case 'liveInference':
            return 'LIVEINFERENCE';
          case 'incrementalTraining':
            return 'INCTRAINING';
          case 'noveltyDetection':
            return 'NOVELTY';
          case 'recordedInference':
            return 'RECORDEDINFERENCE';
          case 'advancedSettings':
            return 'ADVANCEDSETTINGS';
          default:
            return '';
        }
      })
      .filter(feature => feature !== null); // Remove null values if any
    await mutateAsync({
      data: {
        name: values.name,
        global_share: globalDetails,
        // features_list: values.imageBasedConfigOptions.map(
        //   option => option as Analytics
        // )
        features_list: featuresList
      }
    })
      .then(response => {
        snack({
          message: 'Group created successfully',
          severity: 'success'
        });
        onSubmit(values);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formikHelpers.setErrors(
            convertServerErrors(err.response.data.detail)
          );
        }
      });
  };
  const handleEditForm = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    // Create an array for the features list dynamically based on switchesState
    const featuresList = Object.keys(switchesState)
      .filter(key => switchesState[key as keyof typeof switchesState]) // Narrow the key type
      .map(key => {
        // Map the switch state keys to their corresponding feature names
        switch (key) {
          case 'dataSharing':
            return 'DATASHARING';
          case 'training':
            return 'TRAINING';
          case 'liveInference':
            return 'LIVEINFERENCE';
          case 'incrementalTraining':
            return 'INCTRAINING';
          case 'noveltyDetection':
            return 'NOVELTY';
          case 'recordedInference':
            return 'RECORDEDINFERENCE';
          case 'advancedSettings':
            return 'ADVANCEDSETTINGS';
          default:
            return '';
        }
      })
      .filter(feature => feature !== null); // Remove null values if any
    await EditFeaturesList({
      data: {
        name: values.name,
        type: AccessLevel.group,
        id: GrpId,
        global_share: globalDetails,

        features_list: featuresList
      }
    })
      .then(response => {
        snack({
          message: 'Group Edited successfully',
          severity: 'success'
        });
        onSubmit(values);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formikHelpers.setErrors(
            convertServerErrors(err.response.data.detail)
          );
        }
      });
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };

  const handleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setSwitchesState(prevState => {
      const newState = { ...prevState, [name]: checked };
      // If "dataSharing" is being deactivated, deactivate all other switches
      if (name === 'dataSharing' && !checked) {
        newState.dataSharing = false;
        newState.recordedInference = false;
        newState.liveInference = false;
        newState.training = false;
        newState.incrementalTraining = false;
        newState.noveltyDetection = false;
        newState.advancedSettings = false;
      }
      // Automatically enable "training" if "incrementalTraining" is checked
      if (name === 'incrementalTraining' && checked) {
        newState.training = true;
      }

      if (name === 'noveltyDetection' && checked) {
        newState.recordedInference = true;
        newState.liveInference = true;
      }
      if (!newState.recordedInference && !newState.liveInference) {
        newState.noveltyDetection = false;
      }
      if (!newState.recordedInference && !newState.liveInference) {
        newState.noveltyDetection = false;
      }

      // Automatically enable "dataSharing" if other switches are enabled, but allow manual toggling
      if (name !== 'dataSharing') {
        const anySwitchEnabled =
          newState.recordedInference ||
          newState.liveInference ||
          newState.training ||
          newState.incrementalTraining ||
          newState.noveltyDetection;

        // Only auto-enable dataSharing if it's currently false
        if (!newState.dataSharing && anySwitchEnabled) {
          newState.dataSharing = true;
        }
      }
      return newState;
    });
  };

  return (
    <Formik
      initialValues={defaultInitialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={editStatus ? handleEditForm : handleFormSave}
    >
      {({ handleSubmit, errors, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={3}
            style={{
              paddingLeft: '25px',
              paddingRight: '25px'
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Typography
                component="label" // Ensures it's treated as a label
                htmlFor="group-name" // Links the label to the input field
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  color: '#102633',
                  marginBottom: '4px'
                }}
              >
                Group Name
              </Typography>
              <TextField
                id="group-name" // Matches the `htmlFor` of the label
                name="name"
                placeholder="Enter Group Name"
                type="text"
                required
                variant="outlined" // Optional, defines the input style
                fullWidth
                // value={GrpName}
              />
            </div>
          </Stack>
          <Stack
            direction={'column'} // Change direction to row to align items horizontally
            spacing={2} // Add spacing between the switches
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Grid
              container
              direction={'row'} // Change direction to row to align items horizontally
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid
                item
                xs={6}
                mt={3}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="h3"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    opacity: 0.5
                  }}
                >
                  Global Share :
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={3}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    disabled
                    checked={globalDetails}
                    onChange={handleToggleChange}
                    name="Globalshare"
                    color="primary"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              direction={'row'} // Change direction to row to align items horizontally
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="h3"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px'
                  }}
                >
                  Assign Features
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px',
                    opacity: GrpShare ? 0.5 : ''
                  }}
                >
                  Configuration & Data Manager
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    disabled={GrpShare}
                    checked={switchesState.dataSharing}
                    onChange={handleSwitches}
                    name="dataSharing"
                    color="primary"
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Training
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.training}
                    onChange={handleSwitches}
                    name="training"
                    color="primary"
                    disabled={switchesState.incrementalTraining}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Incremental Training
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.incrementalTraining}
                    onChange={handleSwitches}
                    name="incrementalTraining"
                    color="primary"
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Advanced Settings
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.advancedSettings}
                    onChange={handleSwitches}
                    name="advancedSettings"
                    color="primary"
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Recorded Inference
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.recordedInference}
                    onChange={handleSwitches}
                    name="recordedInference"
                    color="primary"
                    // disabled={switchesState.noveltyDetection}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Live Inference
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.liveInference}
                    onChange={handleSwitches}
                    name="liveInference"
                    color="primary"
                    // disabled={switchesState.noveltyDetection}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {' '}
                <Typography
                  variant="subtitle2"
                  ml={3}
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '14px'
                  }}
                >
                  Novelty Detection
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {' '}
                <Stack mr={2}>
                  <Switch
                    checked={switchesState.noveltyDetection}
                    onChange={handleSwitches}
                    name="noveltyDetection"
                    color="primary"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px'
            }}
            padding={2}
          >
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => {
                onCancel();
              }}
              version="light"
            >
              Cancel
            </Button>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              loading={isLoading}
            >
              {isLoading
                ? 'Creating...'
                : isEditLoading
                ? 'Processing..'
                : 'Save'}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CameraNameForm;
