import { Button, Card, Modal, Typography } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  LinearProgress,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Slider } from 'components/slider';
import PieChartComponent from 'components/charts/pieChart';
import PieChart from 'components/charts/pieChart';
import Switch from '@mui/material/Switch';
import Default from '../../assets/images/default.svg';
import forwardArrow from '../../assets/images/forwardArrow.svg';
import { CircularProgress } from '@mui/material';
import {
  ConfigSchema,
  InferenceResultSchema,
  LabelAsisitRequestQualityControl,
  ObjSchema
} from 'schemas';

import { useGetInferenceResult, useStartNovelty } from 'api/sdk';
import { AxiosResponse } from 'axios';
import { useWebSocket } from 'hooks';
import Env from 'config/env';
import Thumbnail from '../train/Thumbnail';
import { useNavigate } from 'react-router-dom';
import { Graph } from './graph';
import SkeletonLoader from 'components/SkeletonLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface MoveImageProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;
  handelRefetchObjects: () => Promise<any>;

  setCurrentInferenceID: (inferenceID: string | undefined) => void;
  currentInferenceID: string | undefined;

  selectedModel: string | undefined;
  statusValue: string | undefined;
  setStep: (data: number) => void;
}

interface ConfusionMatrixProps {
  data: {
    [actualClass: string]: {
      [predictedClass: string]: number;
    };
  };
}
export const MoveImage: React.FC<MoveImageProps> = ({
  selectedConfig,

  currentInferenceID,

  selectedModel,
  setStep,
  statusValue
}) => {
  const [value, setValue] = useState<number>(50);
  const [InferenceArray, setInferenceArray] = useState<InferenceResultSchema>();
  const [truePanelselected, setTruePanelselected] = useState<boolean>(true);
  const [pageNG, setPageNG] = useState(1);
  const [pageOK, setPageOK] = useState(1);
  const [imagesPerRow, setImagesPerRow] = useState(0);
  const [previewImageID, setPreviewImageID] = useState<number>(0);
  const [selectedObject, setSelectedObject] = useState('Select All'); // Default to "Select All"
  const [distributionModal, setDistributionModal] = useState(false);
  const [actualModal, setActualModal] = useState(false);
  const [userEnabled, setUserEnabled] = useState(false);
  const [distributionValue, setDistributionValue] = useState<any>('');
  const [progress, setProgress] = useState<number>(0);
  const handelChangeImageIndex = (id: number, truePanel: boolean) => {
    setTruePanelselected(truePanel);
    setPreviewImageID(id);
  };
  const profile = useSelector((state: RootState) => state.auth.profile);
  // Extract the feature list from the profile
  const userFeatures = profile?.features_list || [];
  const { mutateAsync: startNovelty } = useStartNovelty();
  // Function to get images based on selected class
  const getFilteredImages = () => {
    if (
      selectedObject === 'Select All' &&
      InferenceArray?.class_details &&
      InferenceArray?.class_details?.length > 0
    ) {
      // Return images from all classes
      return InferenceArray.class_details.flatMap(
        classDetail => classDetail.images
      );
    } else {
      // Return images for the selected class
      const selectedClass = InferenceArray?.class_details?.find(
        classDetail => classDetail.classname === selectedObject
      );

      return selectedClass ? selectedClass.images : [];
    }
  };
  const options = [
    { label: 'Select All', value: 'Select All' },
    ...(InferenceArray?.class_details
      ? InferenceArray.class_details.map(obj => ({
          label: obj.classname,
          value: obj.classname
        }))
      : [])
  ];
  const formattedOptions = options?.map(option => ({
    label: String(option.label), // Ensure label is a string
    value: String(option.value) // Ensure value is a string
  }));
  const [selectedDataType, setSelectedDataType] = useState<string>('labelled');

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newDataType: string | null
  ) => {
    if (newDataType !== null) {
      setSelectedDataType(newDataType);
    }
  };
  const ImagesRowRef = useRef(null);
  enum DataType {
    Label = 'Label',
    UnLabel = 'UnLabel'
  }

  useEffect(() => {
    if (InferenceArray?.labeled_count === null) {
      setSelectedDataType('unlabelled');
    }
  }, [InferenceArray]);
  const { refetch: refetchInferenceList, isLoading: inferenceDataLoading } =
    useGetInferenceResult({
      data_id: currentInferenceID || '',
      type: selectedDataType === 'labelled' ? DataType.Label : DataType.UnLabel
      // other parameters as needed
    });

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleSliderChange = (event: any) => {
    setValue(event.target.value);
  };

  const ConfusionMatrix: React.FC<ConfusionMatrixProps> = ({ data }) => {
    const labels = Object.keys(data); // ['upload']

    return (
      // <TableContainer
      //   component={Paper}
      //   sx={{ maxHeight: '300px', overflow: 'auto' }}
      // >
      //   <Table stickyHeader aria-label="confusion matrix">
      //     <TableHead>
      //       <TableRow>
      //         <TableCell
      //           style={{
      //             fontWeight: 'bold',
      //             border: '1px solid rgba(224, 224, 224, 1)'
      //           }}
      //         ></TableCell>
      //         {labels?.map(label => (
      //           <TableCell
      //             key={label}
      //             align="center"
      //             style={{
      //               fontWeight: 'bold',
      //               border: '1px solid rgba(224, 224, 224, 1)'
      //             }}
      //           >
      //             Predicted: {label}
      //           </TableCell>
      //         ))}
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       {labels?.map(actual => (
      //         <TableRow key={actual}>
      //           <TableCell
      //             component="th"
      //             scope="row"
      //             style={{
      //               fontWeight: 'bold',
      //               border: '1px solid rgba(224, 224, 224, 1)'
      //             }}
      //           >
      //             Actual: {actual}
      //           </TableCell>
      //           {labels?.map(predicted => (
      //             <TableCell
      //               key={predicted}
      //               align="center"
      //               style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
      //             >
      //               {data[actual][predicted] || 0}
      //             </TableCell>
      //           ))}
      //         </TableRow>
      //       ))}
      //     </TableBody>
      //   </Table>
      // </TableContainer>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: '300px', overflow: 'auto' }}
      >
        <Table stickyHeader aria-label="confusion matrix">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  border: '1px solid rgba(224, 224, 224, 1)',
                  position: 'sticky', // Make the cell sticky
                  top: 0, // Stick to the top for the header row
                  left: 0, // Stick to the left for the column header
                  backgroundColor: 'white', // Background color to avoid overlap
                  zIndex: 2 // Ensures row header appears above other cells when scrolling
                }}
              ></TableCell>
              {labels.map(label => (
                <TableCell
                  key={label}
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    border: '1px solid rgba(224, 224, 224, 1)',
                    zIndex: 1
                  }}
                >
                  Predicted: {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {labels.map(actual => (
              <TableRow key={actual}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontWeight: 'bold',
                    border: '1px solid rgba(224, 224, 224, 1)',
                    position: 'sticky', // Make the row header sticky
                    left: 0, // Stick to the left side
                    backgroundColor: 'white', // Background color to avoid overlap with other cells
                    zIndex: 1 // Ensures row header appears above other cells when scrolling
                  }}
                >
                  Actual: {actual}
                </TableCell>
                {labels.map(predicted => (
                  <TableCell
                    key={predicted}
                    align="center"
                    style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    {data[actual][predicted] || 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const tableStyle: React.CSSProperties = {
    border: '1px solid black',
    borderCollapse: 'collapse' as 'collapse'
  };

  const cellHeaderStyle: React.CSSProperties = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center' as 'center', // specify 'center' as a literal type
    fontSize: '14px',
    background: '#EEEEEE'
  };
  const cellStyle: React.CSSProperties = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center' as 'center' // specify 'center' as a literal type
  };
  const PredictionsList = [
    {
      label: 'True Positive (User - NG , AI Predictions - NG)',
      value: 'true_positive'
    },
    {
      label: 'False Positive (User - OK , AI Predictions - NG)',
      value: 'false_positive'
    }
  ];

  const PredictionsList1 = [
    {
      label: 'True Negative (User - OK , AI Predictions - OK)',
      value: 'true_negative'
    },
    {
      label: 'False Negative  (User-NG , AI Predictions - OK)',
      value: 'false_nagative'
    }
  ];

  //socket
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [status, setStatus] = useState(statusValue ? statusValue : '');

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (message?.pipeline_type === 'quality_control_inference') {
      setProgress(data?.progress);
      setStatus(type);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  useEffect(() => {
    refetchInferenceList().then(res => {
      setInferenceArray(res.data?.data);
    });
  }, [selectedDataType]);

  // useEffect(()=>{
  //   InferenceArray?.total_images === 0
  // },[])
  const navigate = useNavigate();

  // useEffect(() => {
  //   let interval: NodeJS.Timeout;

  //   if (InferenceArray?.total_images === 0) {
  //     interval = setInterval(() => {
  //       refetchInferenceList().then(res => {
  //         setInferenceArray(res.data?.data);
  //       });
  //     }, 3000);
  //   }

  //   return () => clearInterval(interval);
  // }, [InferenceArray]);

  const handleSwitchChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
    setUserEnabled(false);
  };

  // const getPreviewImage = useMemo(() => {
  //   const filteredImages =
  //     InferenceArray?.class_details &&
  //     InferenceArray?.class_details?.length > 0 &&
  //     // InferenceArray.class_details.flatMap(classDetail => classDetail.images);
  //     InferenceArray?.class_details?.find(
  //       classDetail => classDetail.classname === selectedObject
  //     );
  //   console.log('seelelee', filteredImages);

  //   const selectedClass = filteredImages?.images;
  //   console.log('seelelee', selectedClass);
  //   return filteredImages && selectedClass[previewImageID];
  //   // : filteredImages && filteredImages[previewImageID];
  // }, [
  //   truePanelselected,
  //   InferenceArray,
  //   previewImageID,
  //   selectedObject,
  //   isSwitchOn
  // ]);
  const getPreviewImage = useMemo(() => {
    // Check if 'Select All' is selected and class details are present
    const filteredImages =
      selectedObject === 'Select All' &&
      InferenceArray?.class_details &&
      InferenceArray.class_details.length > 0
        ? // Return images from all classes
          InferenceArray.class_details.flatMap(
            classDetail => classDetail.images
          )
        : // Otherwise, find and return images for the selected class
          InferenceArray?.class_details?.find(
            classDetail => classDetail.classname === selectedObject
          )?.images || [];

    // Return the image at the previewImageID index
    return filteredImages[previewImageID];
  }, [
    truePanelselected,
    InferenceArray,
    previewImageID,
    selectedObject,
    isSwitchOn
  ]);
  useEffect(() => {
    const updateImagesPerRow = () => {
      if (ImagesRowRef.current) {
        const boxWidth = (ImagesRowRef.current as HTMLElement)?.offsetWidth;

        const thumbnailWidth = 110;
        const imagesInRow = Math.floor(boxWidth / thumbnailWidth);
        setImagesPerRow(imagesInRow);
      }
    };
    updateImagesPerRow();
    window.addEventListener('resize', updateImagesPerRow);

    return () => {
      window.removeEventListener('resize', updateImagesPerRow);
    };
  }, []);
  const handleUprow = (e: KeyboardEvent) => {
    e.preventDefault();
    if (previewImageID > imagesPerRow) {
      setPreviewImageID(previewImageID - imagesPerRow);
    }
  };
  const handleDownrow = (e: KeyboardEvent) => {
    e.preventDefault();
    if (previewImageID + imagesPerRow <= 99) {
      setPreviewImageID(previewImageID + imagesPerRow);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          handleUprow(e);
          break;
        case 'ArrowDown':
          handleDownrow(e);
          break;
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPageNG(value);
  };
  const handleChange2 = (event: ChangeEvent<unknown>, value: number) => {
    setPageOK(value);
  };

  const handlePrevImage = () => {
    setPreviewImageID(prev => {
      if (prev != 0) return prev - 1;
      else return prev;
    });
  };

  const dataDistributionData = async () => {
    const selectedValue =
      selectedDataType === 'labelled' ? DataType.Label : DataType.UnLabel;
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/training/get_inference_data_distribution?data_id=${currentInferenceID}&type=${selectedValue}`;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data distribution');
      }

      const data = await response.json(); // Assuming API returns JSON data
      setDistributionValue(data);
      // setData(data); // Update state or handle data as needed
    } catch (error) {}
  };
  // const handleNextImage = () => {
  //   // InferenceArray?.total_images
  //   setPreviewImageID(prev => {
  //     if (prev < 59) return prev + 1;
  //     else return prev;
  //   });
  // };
  const handleNextImage = () => {
    const totalImages = InferenceArray?.total_images ?? 99; // Default to 99 if undefined
    setPreviewImageID(prev => {
      if (prev < totalImages - 1) {
        return prev + 1; // Increment only if it's less than the max
      } else {
        return prev; // Stay on the last image if max reached
      }
    });
  };
  const handleOpen = () => {
    dataDistributionData();
    setDistributionModal(true);
  };
  const handleMatrixOpen = () => {
    setActualModal(true);
  };
  const handleClose = () => setDistributionModal(false);
  const handleActualClose = () => setActualModal(false);

  useEffect(() => {
    if (!truePanelselected) {
      setIsSwitchOn(false);
    }
  }, [truePanelselected]);
  return (
    <>
      {/* <Button
        version="light"
        onClick={() => {
          setStep(1);
        }}
      >
        {' '}
        Back{' '}
      </Button> */}
      <div
        onClick={() => {
          setStep(1);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <img
            src={forwardArrow}
            alt="Forward Arrow"
            style={{
              marginRight: '8px',
              height: '12px'
            }}
          />
          <Typography
            variant="h6"
            fontSize="14px"
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: '#102633'
            }}
          >
            Back
          </Typography>
        </Box>
      </div>

      {inferenceDataLoading ? (
        // <Box
        //   sx={{
        //     height: '75vh',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     backgroundColor: '#fff'
        //   }}
        // >
        //   <CircularProgress />
        // </Box>
        <SkeletonLoader />
      ) : (
        <Grid container spacing={1} mt={1}>
          <Grid container item xs={12} lg={7} md={12} spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: '100%' }}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      fontSize="20px"
                      mb={0.5}
                      sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                    >
                      Inference Dashboard
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      mb={0.5}
                    >
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Dataset Name :
                        </span>{' '}
                        {InferenceArray?.dataset_name}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      mb={1}
                    >
                      {selectedDataType === 'labelled' && (
                        <Typography
                          variant="h6"
                          fontSize="14px"
                          mb={2}
                          sx={{
                            opacity: 1,
                            textTransform: 'capitalize'
                          }}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            Accuracy :{' '}
                          </span>{' '}
                          {InferenceArray?.accuracy?.toFixed(2)}
                        </Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      mt={4}
                    >
                      {InferenceArray && (
                        <Stack direction={'row'} mt={1}>
                          <SelectInput
                            label="Select Classes"
                            placeholder="Select Classes"
                            variant="outlined"
                            width={'250px'}
                            name="classesType"
                            value={selectedObject}
                            options={formattedOptions}
                            onChange={value => {
                              setSelectedObject(value);
                              setPageOK(1);
                            }} // Update the state on change
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      mb={0.5}
                      ml={6}
                      mt={4.5}
                    >
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={0.5}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          Configuration :
                        </span>{' '}
                        {InferenceArray?.config_name}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      mb={0.5}
                      ml={6}
                    >
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        mb={1}
                        sx={{
                          opacity: 1,
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          Total Images :{' '}
                        </span>{' '}
                        {InferenceArray?.total_images}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <ToggleButtonGroup
                      value={selectedDataType}
                      exclusive
                      onChange={handleToggle}
                      aria-label="Data Type"
                      size="small"
                      style={{ borderRadius: '0px', marginTop: '20px' }}
                    >
                      <ToggleButton
                        value="labelled"
                        disabled={
                          InferenceArray?.labeled_count === 0 ||
                          InferenceArray?.labeled_count === null
                        }
                        // disabled={InferenceArray?.total_images === 0}
                        style={{
                          color:
                            selectedDataType === 'labelled'
                              ? '#fff'
                              : InferenceArray?.labeled_count == null
                              ? 'grey'
                              : '#000',
                          // backgroundColor:
                          //   selectedDataType === 'labelled'
                          //     ? '#102633'
                          //     : '#f0f0f0',
                          backgroundColor:
                            selectedDataType === 'labelled'
                              ? '#102633'
                              : InferenceArray?.labeled_count == null
                              ? '#e0e0e0'
                              : '#f0f0f0',
                          fontWeight: 'bold'
                        }}
                      >
                        Labelled Data
                      </ToggleButton>
                      <ToggleButton
                        value="unlabelled"
                        disabled={
                          InferenceArray?.unlabeled_count === 0 ||
                          InferenceArray?.unlabeled_count === null
                        }
                        style={{
                          color:
                            selectedDataType === 'unlabelled'
                              ? '#fff'
                              : InferenceArray?.unlabeled_count == null
                              ? 'grey'
                              : '#000',
                          backgroundColor:
                            selectedDataType === 'unlabelled'
                              ? '#102633'
                              : InferenceArray?.unlabeled_count == null
                              ? '#e0e0e0'
                              : '#f0f0f0', // Disabled background color for 'Unlabelled'

                          fontWeight: 'bold'
                        }}
                      >
                        Unlabelled Data
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginTop: '65px'
                      }}
                    >
                      <Button
                        style={{
                          height: '45px'
                        }}
                        variant="outlined"
                        version="light"
                        onClick={handleOpen}
                      >
                        View Data Distribution
                      </Button>
                      {selectedDataType === 'labelled' && (
                        <Button
                          style={{
                            height: '45px'
                          }}
                          variant="outlined"
                          version="light"
                          onClick={handleMatrixOpen}
                        >
                          View Advance Metrics
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* Modal component For Data Distribution*/}
            <Modal
              open={distributionModal}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              {/* {distributionValue && (
      <Graph height={'100%'} width={'100%'} data={distributionValue} />
    )} */}
              <Box
                sx={{
                  width: '50vw', // Width set to 80% of the viewport width
                  height: '50vh', // Height set to 80% of the viewport height
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: 24,
                  overflow: 'auto', // Allow scrolling if content is too large
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                {distributionValue && (
                  <Graph
                    height={'100%'}
                    width={'100%'}
                    data={distributionValue}
                  />
                )}
              </Box>
            </Modal>

            {/* Modal component For Advance Matrix*/}
            <Modal
              open={actualModal}
              onClose={handleActualClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              {InferenceArray?.confusion_matrix && (
                <ConfusionMatrix data={InferenceArray?.confusion_matrix} />
              )}
              {/* </Box> */}
            </Modal>

            {/* Predictions  */}

            <Grid item xs={12} sx={{ height: 'calc(100vh - 340px)' }}>
              <Card style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={12}
                  mt={1}
                  mb={1}
                  style={{
                    minHeight: '90%',
                    maxHeight: '90%',
                    overflow: 'scroll'
                  }}
                >
                  {InferenceArray && InferenceArray.total_images !== 0 ? (
                    <Box ref={ImagesRowRef}>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          display: 'grid',
                          gridTemplateRows: 'repeat(6, auto)',
                          gridTemplateColumns: 'repeat(6, auto)'
                        }}
                        ref={ImagesRowRef}
                      >
                        {InferenceArray &&
                          getFilteredImages()
                            ?.slice((pageOK - 1) * 60, pageOK * 60)
                            .map((item, index) => {
                              return (
                                <Grid item xs={1} padding={1}>
                                  <Thumbnail
                                    title=""
                                    type={
                                      (pageOK - 1) * 60 + index ==
                                        previewImageID && truePanelselected
                                        ? 'OK'
                                        : ''
                                    }
                                    id={item?.image_url || ''}
                                    showImageGallery={false}
                                    key={index}
                                    thumbnailUrl={item?.image_url || ''}
                                    index={index}
                                    checkable={false}
                                    handelChangeImageIndex={() =>
                                      handelChangeImageIndex(
                                        (pageOK - 1) * 60 + index,
                                        true
                                      )
                                    }
                                  />
                                </Grid>
                              );
                            })}
                      </Grid>
                    </Box>
                  ) : (
                    <Stack
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        height: '40vh'
                      }}
                    >
                      <Typography
                        mt={2}
                        style={{ fontWeight: 'bold', fontSize: '18px' }}
                      >
                        {`There are no ${selectedDataType} Images`}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
                {InferenceArray && InferenceArray.total_images !== 0 && (
                  <Stack
                    mt={1}
                    justifyContent={'center'}
                    width={'100%'}
                    height={'30px'}
                    direction={'row'}
                  >
                    <Pagination
                      count={
                        InferenceArray?.class_details &&
                        InferenceArray.class_details.length > 0 &&
                        getFilteredImages()
                          ? Math.ceil(getFilteredImages()!.length / 60) // Use ! operator to assert non-null
                          : 0 // Return 0 pages if no class details are available
                      }
                      page={pageOK}
                      onChange={handleChange2}
                      variant="outlined"
                      siblingCount={0}
                    />
                  </Stack>
                )}
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                >
                  {!InferenceArray ||
                    (InferenceArray.total_images === 0 && (
                      <>
                        {' '}
                        <Stack
                          style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            height: '40vh'
                          }}
                        >
                          <Typography
                            mt={2}
                            style={{ fontWeight: 'bold', fontSize: '14px' }}
                          >
                            Inference Working In Progress ...
                          </Typography>
                        </Stack>
                      </>
                    ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={5} md={12}>
            <Card
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Showing All Images
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginRight: '8px'
                    }}
                  >
                    Hotspot:
                  </Typography>
                  <Switch
                    disabled={!truePanelselected}
                    {...label}
                    checked={isSwitchOn}
                    onChange={handleSwitchChange}
                  />
                </Grid>
              </Grid>

              {getPreviewImage ? (
                <Grid
                  container
                  direction="column"
                  style={{
                    height: '100%'
                  }}
                >
                  <Grid
                    item
                    style={{
                      height: '80%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <IconButton
                      onClick={handlePrevImage}
                      disabled={previewImageID == 0}
                    >
                      <ArrowCircleLeftIcon />
                    </IconButton>

                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '250px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {/* Ensure the blue wrapper appears when is_hotspot is true */}
                      {isSwitchOn && !getPreviewImage?.is_hotspot && (
                        <div
                          style={{
                            position: 'absolute',
                            // top: 0,
                            // left: 0,
                            width: '250px',
                            alignItems: 'center',
                            objectFit: 'contain',
                            height: '250px',
                            backgroundColor: 'rgba(0, 0, 255, 0.3)', // Semi-transparent blue
                            zIndex: 1
                          }}
                        ></div>
                      )}

                      <img
                        style={{
                          width: '100%',
                          height: '250px',
                          objectFit: 'contain',
                          position: 'relative',
                          zIndex: 0 // Ensures the image is below the wrapper
                        }}
                        src={
                          isSwitchOn
                            ? getPreviewImage?.is_hotspot
                              ? getPreviewImage?.hotspot_url
                              : getPreviewImage?.image_url
                            : getPreviewImage?.image_url
                        }
                        alt=""
                      />
                    </div>

                    <IconButton
                      onClick={handleNextImage}
                      // disabled={
                      //   previewImageID >= InferenceArray?.total_images &&
                      //   InferenceArray?.total_images
                      //     ? InferenceArray?.total_images
                      //     : 99
                      // }
                      disabled={
                        previewImageID > (InferenceArray?.total_images ?? 99)
                      }
                    >
                      <ArrowCircleRightIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textAlign: 'center',
                      marginTop: '8px'
                    }}
                  >
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      User Class:
                      {getPreviewImage?.user_class_name}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Probability:
                      {getPreviewImage?.probability}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      AI Prediction:
                      {getPreviewImage?.ai_class_name}
                    </Typography>
                  </Grid>

                  {/* <Grid item style={{ textAlign: 'center', marginTop: '8px' }}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Probability:
            {getPreviewImage?.probability}
          </Typography>
        </Grid> */}
                </Grid>
              ) : (
                <Grid style={{ height: '70%', textAlign: 'center' }}>
                  <img
                    style={{
                      width: '90%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                    src={Default}
                    alt=""
                  />
                  <Typography>
                    {' '}
                    Please double click to preview the image{' '}
                  </Typography>
                </Grid>
              )}
              {(profile?.role === 'organization_admin' ||
                userFeatures?.includes('NOVELTY')) && (
                <Stack direction={'row'} justifyContent={'center'}>
                  <Button
                    onClick={() => {
                      startNovelty({
                        data: {
                          config_id: selectedConfig || '',
                          data_id: currentInferenceID || '',
                          model_id: selectedModel || ''
                        }
                      }).then(() => {
                        setStep(1);
                      });
                    }}
                  >
                    {' '}
                    Run Novelty Detection
                  </Button>
                </Stack>
              )}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
