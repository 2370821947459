import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Switch,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  IconButton,
  Tooltip,
  FormControlLabel
} from '@mui/material';

import Reports from '../../assets/images/reportsQuality.svg';
import ReportsData from '../../assets/images/viewReports.svg';
import reportsNotFound from '../../assets/images/reportsNotFound.svg';

import { ConfigSchema, CreateObjectSchema, ObjSchema } from 'schemas';
import Inference from '../../assets/images/trainingImage.svg';
import { useWebSocket } from 'hooks';

import Env from 'config/env';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
// import { useSaveFolderFiles } from 'api/sdk';
import { useGetTrainingResult } from '../../api/sdk';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ListTable } from 'components/ListTable';
import NoDataComponent from 'components/NoDataComponent';

interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;
  setCurrentInferenceID: (config: string | undefined) => void;
  setInferenceReport: any;
  handelRefetchObjects: () => Promise<any>;
  setViewMode: (data: boolean) => void;
  setReportsLoaded: (data: boolean) => void;
}
interface TrainingReport {}
interface InferenceValue {
  mode: string;
}
interface IncrementalTrainingReport {
  before_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
  after_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
}
interface InferenceReport {
  training_report: TrainingReport[];

  incremental_training_report?: IncrementalTrainingReport[];
  inference_report_label?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
    true_positive: number;
    true_negative: number;
    false_positive: number;
    false_nagative: number;
    accuracy: number;
  }>;
  inference_report_unlabel?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
  }>;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  objects,
  handelRefetchObjects,
  setCurrentInferenceID,
  setInferenceReport,
  setViewMode,
  setReportsLoaded
}) => {
  const [step, setStep] = useState<string>('TABLE');
  const [processing, setProcessing] = useState<boolean>(false);

  const [selectedObjectID, setSelectedObjectID] = useState<string | undefined>(
    undefined
  );

  const { data, refetch: refectchTrainingResults } = useGetTrainingResult({
    config_id: selectedConfig || ''
  });

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [status, setStatus] = useState('QUEUED');

  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const token = useSelector((state: RootState) => state.auth.token);
  const profile = useSelector((state: RootState) => state.auth.profile);
  const userFeatures = profile?.features_list || [];

  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    setFromWebsocket(data);
    setStatus(type);

    if (type == 'COMPLETED') {
      handelRefetchObjects().then(res => {
        setProcessing(false);
      });
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedConfig != '') {
      setStep('INITIAL');
    }
  }, []);

  useEffect(() => {
    if (selectedConfig != '') {
      setStep('TABLE');
      refectchTrainingResults().then(res => {});
    }
  }, []);

  const dispatch = useDispatch();
  const [rows, setRows] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = React.useState<any>([]);
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };
  useEffect(() => {
    const updatedcolumns: ITableHeadColumn[] = [
      {
        value: 'Model Name',
        key: 'ModelName',
        align: 'center'
      },
      {
        value: 'Base Model',
        key: 'baseModel',
        align: 'center'
      },
      {
        value: 'Model Architecture',
        key: 'ModelArchitecture',
        align: 'center'
      },
      {
        value: 'Total # Images',
        key: 'TotalImages',
        align: 'center'
      },
      {
        value: 'Total # Classes',
        key: 'totalClasses',
        align: 'center'
      },

      {
        value: 'Train test split',
        key: 'Traintestsplit',
        align: 'center'
      },
      {
        value: 'Batch size',
        key: 'Batchsize',
        align: 'center'
      },
      {
        value: 'Learning Rate',
        key: 'Learningrate',
        align: 'center'
      },
      {
        value: 'Epochs',
        key: 'Epochs',
        align: 'center'
      },

      {
        value: 'Test Accuracy',
        key: 'TestAccuracy'
      },

      {
        value: 'Reports',
        key: 'actions',
        render: ({ value, record }) => {
          return (
            <Box display="flex" gap={1} justifyContent={'center'}>
              {/* IconButton for "View Training Data" */}
              <Tooltip title="View Training Data">
                <IconButton
                  disabled={record.isDeleted != false}
                  onClick={() => {
                    if (record) {
                      setCurrentInferenceID(record.inferenceID as string);
                      setViewMode(true);
                      onChange(3);
                    }
                  }}
                  aria-label="View Training Data"
                >
                  <img
                    src={Reports} // Assuming Reports is the icon you want for "View Training Data"
                    alt="View Training Data"
                    style={{ width: '30px', height: '30px' }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Reports">
                {/* IconButton for "View Reports" */}
                <IconButton
                  onClick={() => {
                    // setInferenceReport([]);
                    fetchTrainingResult(record?.modelId as string);
                    setReportsLoaded(true);

                    setCurrentInferenceID(record.inferenceID as string);
                    setViewMode(true);
                    onChange(2);
                  }}
                  disabled={
                    record.TrainingStatus !== 'COMPLETED' ||
                    record.isDeleted != false
                  }
                  aria-label="View Reports"
                >
                  <img
                    src={
                      record.TrainingStatus !== 'COMPLETED'
                        ? reportsNotFound
                        : ReportsData
                    }
                    alt="View Reports"
                    style={{ width: '30px', height: '25px' }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      }
    ];
    const filteredColumns = advancedDetails
      ? updatedcolumns
      : updatedcolumns.filter(
          column =>
            ![
              'Traintestsplit',
              'ModelArchitecture',
              'Batchsize',
              'Learningrate',
              'Epochs'
            ].includes(column.key)
        );
    setColumns(filteredColumns);
    const Updatedrows =
      data?.data
        ?.filter((item: any) => {
          // Check if searchTerm is valid
          const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
          if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

          // Filter rows based on the search term
          if (typeof item.model_name === 'string') {
            return item.model_name.toLowerCase().includes(trimmedSearchTerm);
          }
          return false; // Exclude rows without a valid 'name' field
        })
        ?.map(item => ({
          ModelName: item.model_name,
          ModelArchitecture: item?.model_architecture,
          TotalImages: item?.total_image_count,
          totalClasses: item?.class_count || 0,

          Traintestsplit: item.train_spilt,
          Batchsize: item.batch_size,
          Learningrate: item.learning_rate,
          Epochs: item.total_epochs,
          TrainingStatus: item.test_accuracy
            ? item.status
            : fromWebsocket?.model_id === item.model_id &&
              fromWebsocket?.progress !== undefined
            ? fromWebsocket.progress
            : item.status
            ? item.status
            : 'COMPLETED',
          TestAccuracy: item.test_accuracy
            ? (item.test_accuracy * 100).toFixed(2) + ' %'
            : fromWebsocket?.model_id === item.model_id &&
              fromWebsocket?.['Test accuracy']
            ? (fromWebsocket['Test accuracy'] * 100).toFixed(2) + ' %'
            : '0 %',
          inferenceID: item.data_id,
          baseModel: item.old_model_name || '-',
          modelId: item.model_id || '-',
          isDeleted: item?.is_deleted
        }))
        ?.filter(row => row.TrainingStatus === 'COMPLETED') || [];
    setRows(Updatedrows);
  }, [searchTerm, data, advancedDetails]);

  const fetchTrainingResult = async (inference_id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/training/get_training_inference_details?model_id=${inference_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setReportsLoaded(false);
      setInferenceReport(response?.data);
    } catch (error) {
      console.error('Error fetching training result:', error);
    }
  };

  return (
    <Card
      style={{
        height:
          configs && configs.length > 0 && selectedConfig != '' ? '' : '82vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {configs && step != 'INITIAL' && (
        <Stack direction={'row'} justifyContent={'space-between'} mt={2} mb={3}>
          <Typography variant="h6" fontWeight="bold">
            Reports
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {configs &&
              configs.length > 0 &&
              selectedConfig != '' &&
              (profile?.role === 'organization_admin' ||
                userFeatures?.includes('ADVANCEDSETTINGS')) && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={advancedDetails}
                      onChange={handleToggleChange}
                      name="toggleButton"
                      color="primary"
                    />
                  }
                  label="Advanced Details"
                  style={{ marginLeft: '18px', marginRight: '30px' }}
                  labelPlacement="start" // Ensure label is on the left (before the switch)
                />
              )}

            <SelectInput
              label="Select Configuration"
              placeholder="Select Configuration"
              variant="outlined"
              width={'250px'}
              name="configurationType"
              options={configs?.map(i => ({ label: i.name, value: i.id }))}
              value={selectedConfig || ''}
              onChange={(value: string) => {
                dispatch(setSelectedConfiguration(value));
              }}
            />
          </div>
        </Stack>
      )}
      {configs && configs.length > 0 && selectedConfig != '' && (
        <Box>
          {step == 'TABLE' && (
            <Box>
              <Box>
                {/* {rows && (
                  <Table
                    // alignRows='center'
                    stickyHeader={true}
                    columns={columns}
                    rows={rows}
                    striped={false}
                  />
                )} */}
                <ListTable
                  columns={columns}
                  rows={rows}
                  searchVal={searchTerm}
                  setSearchVal={setSearchTerm}
                  striped={true}
                  loading={false}
                  stickyHeader
                  showSearch={true}
                  // containerStyle={{ overflow: 'auto' }}
                />
              </Box>

              {/* <Stack direction={'row'} justifyContent={'center'}>
                <Button
                  onClick={() => {
                    setViewMode(false);
                    setStep('INITIAL');
                  }}
                  style={{ width: '310px', height: '45px' }}
                >
                  {' '}
                  START TRAINING
                </Button>
              </Stack> */}
            </Box>
          )}
        </Box>
      )}

      {configs && configs.length == 0 && (
        <Stack style={{ textAlign: 'center' }}>
          <Typography>Please add configuration to train a model</Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig == '' && (
        <Stack
          style={{
            height: '41vh',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <img
            src={Inference}
            alt="Inference"
            style={{ width: '350px', height: '400px' }}
          /> */}
          <NoDataComponent />
          <Typography style={{ color: '#707070', fontSize: '26px' }}>
            Select Configuration type to proceed with the process{' '}
          </Typography>
        </Stack>
      )}

      <Stack spacing={2} direction="column" alignItems="center">
        <Typography
          variant="body2"
          style={{ color: 'red', fontWeight: '16px' }}
        >
          {errorMessage}
        </Typography>
      </Stack>
    </Card>
  );
};
