/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type ObjectType = typeof ObjectType[keyof typeof ObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObjectType = {
  Label: 'Label',
  UnLabel: 'UnLabel',
} as const;
