import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { store } from 'store';

const apiAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,

  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
});

apiAxiosInstance.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export const apiAxiosInstanceFn = <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const source = axios.CancelToken.source();
  const promise = apiAxiosInstance({
    ...config,
    cancelToken: source.token
  }).then((response: AxiosResponse<T>) => response);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export default apiAxiosInstanceFn;

export type ErrorType<Error> = AxiosError<Error>;
