import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  LinearProgress,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
  Tooltip
} from '@mui/material';

import Reports from '../../assets/images/reportsQuality.svg';
import {
  ConfigSchema,
  CreateObjectSchema,
  ObjSchema,
  TrainingResultSchema
} from 'schemas';
import Inference from '../../assets/images/trainingImage.svg';
import { useWebSocket } from 'hooks';

import Env from 'config/env';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
// import { useSaveFolderFiles } from 'api/sdk';
import { useGetTrainingResult } from '../../api/sdk';
import moment from 'moment';
import { ListTable } from 'components/ListTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import NoDataComponent from 'components/NoDataComponent';

interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  models: TrainingResultSchema[];
  setCurrentInferenceID: (config: string | undefined) => void;
  selectedModel: string | undefined;
  setSelectedModel: (config: string | undefined) => void;

  setViewMode: (data: boolean) => void;
  setStep: (data: number) => void;
  step: number;
  setIsIncrementl: (data: boolean) => void;
  setObjectsinModels: (data: string[]) => void;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  setCurrentInferenceID,
  setViewMode,
  setStep,
  setSelectedModel,
  models,
  setIsIncrementl,
  selectedModel,
  setObjectsinModels,
  step
}) => {
  // const { mutateAsync: saveFile } = useSaveFolderFiles();
  const { data, refetch: refectchTrainingResults } = useGetTrainingResult({
    config_id: selectedConfig || ''
  });

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [statusValue, setStatus] = useState('QUEUED');
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [proceedDisable, setProceedDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rows, setRows] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = React.useState<any>([]);
  const profile = useSelector((state: RootState) => state.auth.profile);
  // Extract the feature list from the profile
  const userFeatures = profile?.features_list || [];
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (
      message?.pipeline_type === 'quality_control_training' ||
      message?.pipeline_type === 'quality_control_incremental'
    ) {
      setFromWebsocket(data);
      setStatus(type);

      if (type == 'COMPLETED') {
        refectchTrainingResults();
        // handelRefetchObjects().then(res => {
        //   setProcessing(false);
        // });
      }
    }
  };

  useEffect(() => {
    if (selectedConfig != '') {
      refectchTrainingResults().then(res => {});
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    const updatedcolumns: ITableHeadColumn[] = [
      {
        value: 'Model Name',
        key: 'ModelName',
        align: 'center'
      },
      {
        value: 'Base Model',
        key: 'baseModel',
        align: 'center'
      },
      {
        value: 'Model Architecture',
        key: 'ModelArchitecture',
        align: 'center'
      },
      {
        value: 'Training started at',
        key: 'Date',
        align: 'center'
      },
      {
        value: 'Total # Images',
        key: 'TotalImages',
        align: 'center'
      },
      {
        value: 'DataSet Name',
        key: 'dataSetName',
        align: 'center'
      },
      {
        value: 'Total # Classes',
        key: 'totalClasses',
        align: 'center'
      },

      {
        value: 'Train test split',
        key: 'Traintestsplit',
        align: 'center'
      },
      {
        value: 'Batch size',
        key: 'Batchsize',
        align: 'center'
      },
      {
        value: 'Learning Rate',
        key: 'Learningrate',
        align: 'center'
      },
      {
        value: 'Epochs',
        key: 'Epochs',
        align: 'center'
      },
      {
        value: 'Training Status',
        key: 'TrainingStatus',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.TrainingStatus;
          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                Failed
              </Typography>
            );
          }
          if (trainingStatus === 'INPROGRESS') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                In Progress
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                Queued
              </Typography>
            );
          }

          // If it's neither "COMPLETED" nor "QUEUED", show the progress bar

          return (
            <Box position="relative" display="inline-flex" ml={4}>
              <CircularProgress
                variant="determinate"
                value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  style={{ fontSize: '10px' }}
                >
                  {`${Math.round(trainingStatus as number)}%`}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
      {
        value: 'Test Accuracy',
        key: 'TestAccuracy'
      },
      {
        value: 'View Training Data',
        key: 'actions',
        render: ({ value, record }) => {
          return (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title="View Training Data">
                <IconButton
                  disabled={record.isDeleted != false}
                  onClick={() => {
                    if (record) {
                      setCurrentInferenceID(record.inferenceID as string);
                      setViewMode(true);
                      onChange(3);
                    }
                  }}
                >
                  <img
                    src={Reports}
                    alt="Report"
                    style={{ width: '30px', height: '30px' }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      }
    ];
    const filteredColumns = advancedDetails
      ? updatedcolumns
      : updatedcolumns.filter(
          column =>
            ![
              'Traintestsplit',
              'ModelArchitecture',
              'Batchsize',
              'Learningrate',
              'Epochs'
            ].includes(column.key)
        );
    setColumns(filteredColumns);
    function formatDateTime(dateTimeString: string) {
      const dateObj = new Date(dateTimeString);

      // Extract components
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');

      // Format as mm-dd-yyyy hh:mm
      return `${month}-${day}-${year} ${hours}:${minutes}`;
    }

    const Updatedrows =
      data?.data
        ?.filter((item: any) => {
          // Check if searchTerm is valid
          const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
          if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

          // Filter rows based on the search term
          if (typeof item.model_name === 'string') {
            return item.model_name.toLowerCase().includes(trimmedSearchTerm);
          }
          return false; // Exclude rows without a valid 'name' field
        })
        .map(item => ({
          ModelName: item.model_name,
          TotalImages: item?.total_image_count,
          dataSetName: item?.datasetname,
          totalClasses: item?.class_count || 0,
          ModelArchitecture: item?.model_architecture,
          Traintestsplit: item.train_spilt,
          Batchsize: item.batch_size,
          Learningrate: item.learning_rate,
          Epochs: item.total_epochs,
          Date: formatDateTime(item.training_started_at as string),
          TrainingStatus:
            fromWebsocket?.['Test accuracy'] &&
            fromWebsocket?.model_id === item.model_id
              ? 'COMPLETED' // Show "Completed" when data is received and inference_id matches
              : fromWebsocket?.progress !== undefined &&
                fromWebsocket?.model_id === item.model_id
              ? fromWebsocket.progress // Show progress if available and inference_id matches
              : item.status,

          TestAccuracy: item.test_accuracy
            ? (item.test_accuracy * 100).toFixed(2) + ' %'
            : 'N/A',

          inferenceID: item.data_id,
          baseModel: item?.old_model_name || '-',
          isDeleted: item?.is_deleted
        })) || [];
    setRows(Updatedrows);
  }, [data, searchTerm, advancedDetails, fromWebsocket]);

  // Handle closing the modal
  const handleClose = () => {
    setCreateModalOpen(false);
    // Optionally reset the selected model
  };

  useEffect(() => {
    if (selectedModel) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [selectedModel]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12} mt={2}>
        <Card
          style={{
            height:
              configs && configs.length > 0 && selectedConfig != ''
                ? ''
                : '82vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          {configs && (
            <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: 2.5
                }}
              >
                Train Model
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Toggle Button */}
                {configs &&
                  configs.length > 0 &&
                  selectedConfig != '' &&
                  (profile?.role === 'organization_admin' ||
                    userFeatures?.includes('ADVANCEDSETTINGS')) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={advancedDetails}
                          onChange={handleToggleChange}
                          name="toggleButton"
                          color="primary"
                        />
                      }
                      label="Advanced Details"
                      style={{ marginLeft: '18px', marginRight: '30px' }}
                      labelPlacement="start" // Ensure label is on the left (before the switch)
                    />
                  )}

                <SelectInput
                  label="Select Configuration"
                  placeholder="Select Configuration"
                  variant="outlined"
                  width={'250px'}
                  name="configurationType"
                  options={configs?.map(i => ({ label: i.name, value: i.id }))}
                  value={selectedConfig || ''}
                  onChange={(value: string) => {
                    dispatch(setSelectedConfiguration(value));
                  }}
                />
              </div>
            </Stack>
          )}
          {configs && configs.length > 0 && selectedConfig != '' && (
            <>
              <Box>
                {
                  <Box>
                    {/* <Box style={{ height: '65vh', overflowY: 'scroll' }}>
                {rows && (
                  <Table
                    stickyHeader={true}
                    columns={filteredColumns}
                    rows={rows}
                    striped={true}
                  />
                )}
              </Box> */}{' '}
                    {rows && (
                      <ListTable
                        columns={columns}
                        rows={rows}
                        searchVal={searchTerm}
                        setSearchVal={setSearchTerm}
                        striped={true}
                        loading={false}
                        stickyHeader
                        showSearch={true}
                        // containerStyle={{ overflow: 'auto' }}
                      />
                    )}
                  </Box>
                }
              </Box>
            </>
          )}
          {configs && configs.length == 0 && (
            <Stack style={{ textAlign: 'center' }}>
              <Typography>Please add configuration to train a model</Typography>
            </Stack>
          )}

          {configs && configs.length > 0 && selectedConfig == '' && (
            <Stack
              style={{
                height: '41vh',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <img
                src={Inference}
                alt="Inference"
                style={{ width: '350px', height: '400px' }}
              /> */}
              <NoDataComponent />
              <Typography style={{ color: '#707070', fontSize: '26px' }}>
                Select Configuration type to proceed with the process{' '}
              </Typography>
            </Stack>
          )}

          <Stack spacing={2} direction="column" alignItems="center">
            <Typography
              variant="body2"
              style={{ color: 'red', fontWeight: '16px' }}
            >
              {errorMessage}
            </Typography>
          </Stack>

          {/* Modal */}
          <Modal
            open={createModalOpen}
            onClose={handleClose}
            title="Select Model to Start Incremental Training"
            size="md"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {models && (
                  <SelectInput
                    label="Select Model"
                    placeholder="Select Model"
                    variant="outlined"
                    width={'500px'}
                    name="modelType"
                    options={
                      models
                        ?.filter((model: any) => model?.status === 'COMPLETED')
                        ?.map(i => ({
                          label: String(i.model_name),
                          value: String(i.model_id)
                        })) || []
                    }
                    value={selectedModel}
                    onChange={value => {
                      setSelectedModel(value);
                      const a = models.find(
                        i => i.model_id === value
                      )?.classnames;
                      setObjectsinModels(a || []);
                    }}
                  />
                )}
              </Grid>
              {selectedModel &&
                models
                  .find(i => i.model_id === selectedModel)
                  ?.classnames?.join(',')}
            </Grid>

            {/* Display Error Message */}
            {errorMessage && (
              <Typography
                variant="caption"
                sx={{ color: 'red' }}
                component="div"
              >
                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </Typography>
            )}

            {/* Buttons for Proceed or Cancel */}
            <Grid item xs={12} mt={2} container justifyContent="center">
              <Button version="light" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={proceedDisable}
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  setViewMode(false);
                  setStep(2);
                }}
              >
                Proceed
              </Button>
            </Grid>
          </Modal>
        </Card>
        {configs && configs.length > 0 && selectedConfig != '' && (
          <>
            <Stack direction={'row'} justifyContent={'center'} mt={2}>
              <Button
                onClick={() => {
                  setViewMode(false);
                  setStep(2);
                  setSelectedModel('');
                  setIsIncrementl(false);
                }}
                style={{ width: '310px', height: '45px', borderRadius: '6px' }}
              >
                {' '}
                Start Training
              </Button>
              {(profile?.role === 'organization_admin' ||
                userFeatures?.includes('INCTRAINING')) && (
                <Button
                  onClick={() => {
                    setCreateModalOpen(true);
                    setSelectedModel('');
                    setIsIncrementl(true);
                  }}
                  style={{
                    width: '310px',
                    height: '45px',
                    marginLeft: '20px',
                    borderRadius: '6px'
                  }}
                >
                  {' '}
                  Start Incremental Training
                </Button>
              )}
            </Stack>
          </>
        )}
      </Grid>
    </Grid>
  );
};
