import React, { useEffect, useState } from 'react';
import { Button, Card, TableContainer, Typography } from 'components';
import { Box, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SelectInput } from 'components/Form';
import AddCameraModal from './addCamerModal';
import { useListCameras, useListConfigurations } from 'api/sdk';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAllConfigs, setSelectedConfiguration } from 'store/configSlice';
import { CameraSchema } from 'schemas';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { useLiveInference } from 'hooks/useLiveInference';
import { VideoModal } from './videModal';
import { set } from 'date-fns';
import moment from 'moment';

export const LiveData: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [addCamera, setAddCamera] = useState<boolean>(false);
  const [listOfCameras, setListOfCameras] = useState<CameraSchema[]>([]);
  const [filteredlistOfCameras, setFilteredListOfCameras] =
    useState<CameraSchema[]>(listOfCameras);

  const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
  const [cameraId, setCameraId] = useState<string | null>();
  const [videoModalOPen, setVideoModalOpen] = useState<boolean>(false);

  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const dispatch = useDispatch();
  const { data, refetch: refetchListOfCameras } = useListCameras(
    selectedConfig || ''
  );

  useEffect(() => {
    setListOfCameras([]);
    setFilteredListOfCameras([]);
    refetchListOfCameras().then(res => {
      setListOfCameras(res.data?.data || []);
      setFilteredListOfCameras(res.data?.data || []);
    });
  }, [selectedConfig]);

  const {
    startLive,
    started,
    loading,
    setLoading,
    endInference,
    startStream,
    streamError,
    endStream,
    activityData,

    setStreamError,
    image,
    setImage,

    sendMessage,
    isReseting,
    setResetting,
    socket
  } = useLiveInference();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });

  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setImage('');
    }, 1);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 1000); // Stop after 10 ms

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (started) {
        endInference();
      }
      if (image) {
        endStream();
      }
    };
  }, [data]);

  useEffect(() => {
    const cam = listOfCameras.filter(i => {
      return i.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredListOfCameras(cam);
  }, [searchQuery]);
  // function formatDateTime(dateTimeString: string) {
  //   const dateObj = new Date(dateTimeString);

  //   // Extract components
  //   const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //   const day = String(dateObj.getDate()).padStart(2, '0');
  //   const year = dateObj.getFullYear();
  //   const hours = String(dateObj.getHours()).padStart(2, '0');
  //   const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  //   // Format as mm-dd-yyyy hh:mm
  //   return `${month}-${day}-${year} ${hours}:${minutes}`;
  // }
  return (
    <Card>
      <Stack
        direction={'row'}
        sx={{ borderBottom: '1px solid #ededed' }}
        justifyContent={'space-between'}
      >
        <Typography sx={{ borderBottom: '1px solid #000' }}>
          Live Camera
        </Typography>
        <SelectInput
          name="configuration"
          value={selectedConfig}
          onChange={e => {
            dispatch(setSelectedConfiguration(e));
          }}
          options={
            configs?.map(i => ({
              value: i.id,
              label: i.name
            })) || []
          }
          width={'250px'}
        />
      </Stack>
      <Stack>
        <Stack
          mt={1}
          padding={1}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ backgroundColor: '#ededed' }}
        >
          <Box></Box>
          <TextField
            sx={{ width: '300px' }}
            variant="outlined"
            placeholder="Search"
            fullWidth
            size="small"
            value={searchQuery}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
          <Stack direction={'row'}>
            <Button
              disabled={!selectedConfig}
              onClick={() => setAddCamera(true)}
            >
              <AddIcon />
              ADD CAMERA
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> Camera name </TableCell>
              <TableCell align="right"> Created At</TableCell>
              <TableCell align="right"> URL</TableCell>
              <TableCell align="right">Sampling Rate</TableCell>
              <TableCell align="right"> Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredlistOfCameras.map(row => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {moment
                    .utc(row.created_at)
                    .local()
                    .format('MM-DD-YYYY HH:mm:ss')}
                </TableCell>

                <TableCell align="right">{row.url}</TableCell>
                <TableCell align="right">{row.sampling_rate}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setVideoModalOpen(true);
                      startStream(row.url, row.id);
                    }}
                  >
                    play
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddCameraModal
        open={addCamera}
        onClose={() => setAddCamera(false)}
        onSubmit={() => {
          setAddCamera(false);
          refetchListOfCameras().then(res => {
            setListOfCameras(res.data?.data || []);
            setFilteredListOfCameras(res.data?.data || []);
          });
        }}
      />
      <VideoModal
        onClose={() => {
          endStream();
          setVideoModalOpen(false);
          setStreamError(null);
          setImage('');
        }}
        open={videoModalOPen}
        img={image}
        loading={loading}
        streamError={streamError}
      />
    </Card>
  );
};
