/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type VideoMode = typeof VideoMode[keyof typeof VideoMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoMode = {
  OFFLINE: 'OFFLINE',
  LIVE: 'LIVE',
  FRAME: 'FRAME',
} as const;
