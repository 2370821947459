import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
const NoDataComponent = () => {
  return (
    <div>
      <DotLottieReact
        src="/loaderData.json"
        loop
        autoplay
        style={{ width: '50vh', height: '50vh' }}
        // className="w-[800px] h-50"
      />
    </div>
  );
};

export default NoDataComponent;
