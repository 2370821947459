/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type UserRoles = typeof UserRoles[keyof typeof UserRoles];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoles = {
  super_admin: 'super_admin',
  organization_admin: 'organization_admin',
  organization_member: 'organization_member',
} as const;
