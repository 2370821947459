import {
  Grid,
  ListItem,
  Stack,
  Box,
  CircularProgress,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table
} from '@mui/material';
import { Button, Card, TableContainer, Typography } from 'components';

import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import styled from 'styled-components';
import UnderRepresented from './underRepresented';
import UnRepresented from './unRepresented';
import MisLabelled from './misLabelled';
import Thumbnail from 'pages/train/Thumbnail';
import { v4 } from 'uuid';
import {
  CreateObjectSchema,
  ImgSchema,
  NoveltySchema,
  ObjSchema,
  ObjSchemaImages,
  SyncObjectsRequest,
  UnRepresentedlist,
  UnderRepresentedList
} from 'schemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedConfiguration } from '../../store/configSlice';
import circularProgress from '@mui/material';
import { SelectInput } from 'components/Form';
import {
  useCreateObjects,
  useGetNoveltyDetails,
  useGetObjectsByDataId,
  useSyncObjectByDataid
} from 'api/sdk';
import { StartIncremental } from './modalToStartIncremental';
import { useSnack } from 'plugins/snack';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';
import SkeletonLoader from 'components/SkeletonLoader';
import forwardArrow from '../../assets/images/forwardArrow.svg';

export const NoveltyDetection: React.FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { inference_id, configId, selectedModal } = location.state || {};
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );

  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [selectedModel, setSelectedModel] = useState<string>('');

  const snack = useSnack();
  const [noveltyObjects, setNoveltyObjects] = useState<NoveltySchema>();

  const {
    refetch: refetchNovelty,
    error,
    isLoading: noveltyLoading
  } = useGetNoveltyDetails({
    config_id: selectedConfig || '',
    data_id: currentInferenceID || '',
    model_id: selectedModel || ''
  });

  useEffect(() => {
    dispatch(setSelectedConfiguration(configId));
    setCurrentInferenceID(inference_id);
    setSelectedModel(selectedModal);
  }, [inference_id, configId, selectedModal]);

  useEffect(() => {
    if (currentInferenceID && selectedConfig && selectedModel) {
      refetchNovelty().then(res => {
        setNoveltyObjects(res.data?.data);
      });
    }
  }, [currentInferenceID, selectedConfig, selectedModel]);

  const navigate = useNavigate();

  const [selectBins, setSelectBins] = useState<boolean>(false);

  const [currentBin, setCurrentBin] = useState<number | null | undefined>();
  const [currentTable, setCurrentTable] = useState<string>();
  const [currentClassName, setCurrentClassName] = useState<string>('');
  const [createModal, setCreateModal] = useState<boolean>(false);

  const [checkedUnRepresented, setCheckedUnRepresented] = useState<
    UnRepresentedlist[]
  >([]);

  const [checkedUnderRepresented, setCheckedUnderRepresented] = useState<
    UnderRepresentedList[]
  >([]);

  const underrepresentedImagesArray = useMemo(() => {
    if (
      currentTable == 'underrepresented' &&
      noveltyObjects?.underrepresented
    ) {
      const selectedBin = noveltyObjects?.underrepresented.find(
        i => i.bin_number == currentBin
      );

      const currentImages = selectedBin?.classnames.find(
        i => i.classname == currentClassName
      );

      return currentImages?.images;
    }
  }, [currentTable, currentClassName, currentBin]);

  const unrepresentedImagesArray = useMemo(() => {
    if (currentTable == 'unrepresented' && noveltyObjects?.unrepresented) {
      const selectedBin = noveltyObjects?.unrepresented.find(
        i => i.bin_number == currentBin
      );

      const currentImages = selectedBin?.classnames?.find(
        i => i.classname == currentClassName
      );

      return currentImages?.imagespath;
    }
  }, [currentTable, currentClassName, currentBin]);

  const ClassNames = useMemo(() => {
    if (
      currentTable == 'underrepresented' &&
      noveltyObjects?.underrepresented
    ) {
      const selectedBin = noveltyObjects?.underrepresented.find(
        i => i.bin_number == currentBin
      );

      const filtered = selectedBin?.classnames.filter(
        i =>
          i.images &&
          !(
            i.images.test_images_?.length === 0 &&
            i.images.train_images_?.length === 0
          )
      );

      return filtered;
    }

    if (currentTable == 'unrepresented' && noveltyObjects?.unrepresented) {
      const selectedBin = noveltyObjects?.unrepresented.find(
        i => i.bin_number == currentBin
      );

      const filtered = selectedBin?.classnames?.filter(
        i => i.images && i.images.length > 0
      );
      return filtered;
    }
  }, [currentTable, currentBin]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px'
  }));

  // const handleObjectSelection = (
  //   currentTable: string,
  //   bin: number | null | undefined
  // ) => {
  //   if (bin != null && bin != undefined) {
  //     setCurrentTable(currentTable);
  //     setCurrentBin(bin);
  //   }
  // };

  const handleBinSelection = useCallback(
    (type: string, bin: number | null | undefined) => {
      // Batch state updates together
      setCurrentTable(type);
      setCurrentBin(bin);

      // Only update className if we have new valid bin data
      if (bin !== null && bin !== undefined) {
        const relevantData =
          type === 'underrepresented'
            ? noveltyObjects?.underrepresented
            : noveltyObjects?.unrepresented;

        const binData = relevantData?.find(i => i.bin_number === bin);
        const firstClassName = binData?.classnames?.[0]?.classname;

        if (firstClassName) {
          setCurrentClassName(firstClassName);
        }
      }
    },
    [noveltyObjects]
  );

  // Optimize image array computation
  const selectedBinData = useMemo(() => {
    if (!currentTable || currentBin === null || currentBin === undefined) {
      return null;
    }

    if (currentTable === 'underrepresented') {
      return noveltyObjects?.underrepresented?.find(
        i => i.bin_number === currentBin
      );
    }

    return noveltyObjects?.unrepresented?.find(
      i => i.bin_number === currentBin
    );
  }, [currentTable, currentBin, noveltyObjects]);

  const currentClassData = useMemo(() => {
    if (!selectedBinData || !currentClassName) {
      return null;
    }

    return selectedBinData.classnames?.find(
      i => i.classname === currentClassName
    );
  }, [selectedBinData, currentClassName]);

  const handleCheckUnrepresented = (res: UnRepresentedlist) => {
    const isExist = checkedUnRepresented.find(
      x => x.bin_number === res.bin_number
    );

    if (isExist) {
      setCheckedUnRepresented(prevState =>
        prevState.filter(x => x.bin_number !== res.bin_number)
      );
    } else {
      setCheckedUnRepresented(prevState => [...prevState, res]);
    }
  };

  const imagesLen = () => {
    let totalImages = 0;
    for (let x of checkedUnRepresented) {
      if (x.number_of_images) {
        totalImages = totalImages + x.number_of_images;
      }
    }
    for (let x of checkedUnderRepresented) {
      if (x.number_of_test) {
        totalImages = totalImages + x?.number_of_test;
      }
    }
    return totalImages;
  };
  interface ClassDetails {
    [key: string]: any;
  }

  const classDetails = (): ClassDetails => {
    let classD: ClassDetails = {};

    for (let x of checkedUnderRepresented) {
      for (let y of x.classnames) {
        if (y && y.classname && y.images) {
          classD[y.classname] = classD[y.classname]
            ? classD[y.classname] + y.images?.test_images_.length
            : y?.images.test_images_?.length || 0;
        }
      }
    }

    for (let x of checkedUnRepresented) {
      for (let y of x.classnames || []) {
        if (y && y.classname && y.imagespath) {
          const imagePathCount = y.imagespath?.length || 0;

          classD[y.classname] = classD[y.classname]
            ? classD[y.classname] + imagePathCount
            : imagePathCount;
        }
      }
    }
    return classD;
  };

  useEffect(() => {
    classDetails();
  }, [checkedUnderRepresented, checkedUnRepresented]);
  const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    border: `1px solid #ededed`,
    padding: '2px',
    backgroundColor: '#ededed',
    position: 'sticky',
    top: 0,
    zIndex: 1
  }));
  useEffect(() => {
    if (ClassNames && ClassNames.length > 0) {
      const classname1 = ClassNames?.[0].classname || '';
      setCurrentClassName(classname1 || '');
    }
  }, [currentBin]);
  return (
    <>
      <Stack mb={1} direction={'row'} justifyContent={'flex-start'}>
        {' '}
        {/* <Button
          version="light"
          onClick={() => {
            navigate('/inference/inference');
          }}
        >
          {' '}
          Back
        </Button> */}
        <div
          onClick={() => {
            navigate('/inference/inference');
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <img
              src={forwardArrow}
              alt="Forward Arrow"
              style={{
                marginRight: '8px',
                height: '12px'
              }}
            />
            <Typography
              variant="h6"
              fontSize="14px"
              style={{
                fontWeight: 'bold',
                textDecoration: 'underline',
                color: '#102633'
              }}
            >
              Back
            </Typography>
          </Box>
        </div>
      </Stack>

      {noveltyLoading ? (
        <>
          {/* <Grid
            sx={{
              height: '80vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff'
            }}
          >
            <CircularProgress />
          </Grid> */}
          <SkeletonLoader />
        </>
      ) : (
        <Box>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>Novelty detection </Typography>
            <Button onClick={() => setSelectBins(true)}>
              Select Bins For Incremental Training
            </Button>
          </Stack>

          <Grid container mt={1} gap={1} sx={{ height: '80vh' }}>
            <Grid item xs={6.9} sx={{ height: '35vh' }}>
              <Stack
                style={{
                  backgroundColor: '#102633',
                  color: '#fff',
                  padding: '5px',
                  textAlign: 'center'
                }}
              >
                <Typography> Under-represented </Typography>
              </Stack>

              <UnderRepresented
                handleObjectSelection={handleBinSelection}
                UnderRepresentedList={noveltyObjects?.underrepresented || []}
                selectBins={selectBins}
                checkedUnderRepresented={checkedUnderRepresented}
                currentTable={currentTable}
                currentBin={currentBin}
                setSelectBins={setSelectBins}
                setCheckedUnderRepresented={setCheckedUnderRepresented}
              />
            </Grid>
            <Grid item xs={5} sx={{ height: '35vh' }}>
              <Stack
                style={{
                  backgroundColor: '#102633',
                  color: '#fff',
                  padding: '5px',
                  textAlign: 'center'
                }}
              >
                <Typography> Un-represented </Typography>
              </Stack>

              <UnRepresented
                handleObjectSelection={handleBinSelection}
                UnRepresentedlist={noveltyObjects?.unrepresented || []}
                handleCheckUnrepresented={handleCheckUnrepresented}
                checkedUnRepresented={checkedUnRepresented}
                selectBins={selectBins}
                currentTable={currentTable}
                currentBin={currentBin}
              />
            </Grid>

            {!selectBins && (
              <>
                <Grid
                  item
                  xs={12}
                  mt={1}
                  sx={{
                    backgroundColor: '#FFF',
                    padding: '16px',
                    borderRadius: '8px',
                    height: '35vh'
                  }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <SelectInput
                      name="Classname"
                      maxWidth={'300px'}
                      width={'300px'}
                      options={
                        ClassNames?.map(i => ({
                          label: i.classname ?? '',
                          value: i.classname ?? ''
                        })) || []
                      }
                      placeholder={'Select Classname'}
                      value={currentClassName}
                      variant="outlined"
                      onChange={value => {
                        setCurrentClassName(value);
                      }}
                    />
                  </Stack>

                  <Grid container sx={{ height: '35vh' }}>
                    {!selectBins && currentTable == 'underrepresented' && (
                      <Grid item xs={6} sx={{ height: '100%' }}>
                        <Typography
                          sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                          Train Images{' '}
                        </Typography>
                        <Grid
                          style={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            height: '70%',

                            borderRight: '1px solid #ededed'
                          }}
                        >
                          {underrepresentedImagesArray?.train_images_?.map(
                            (item, index) => {
                              return (
                                <Thumbnail
                                  id={`${index}`}
                                  thumbnailUrl={item || ''}
                                  checkable={false}
                                  title="title"
                                  index={index}
                                  showImageGallery={false}
                                />
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {!selectBins && currentTable == 'underrepresented' && (
                      <Grid item xs={6} style={{ height: '100%' }}>
                        <Typography
                          sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                          Test Images{' '}
                        </Typography>
                        <Grid
                          style={{
                            height: '70%',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            paddingLeft: '16px'
                          }}
                        >
                          {underrepresentedImagesArray?.test_images_?.map(
                            (item, index) => {
                              return (
                                <Thumbnail
                                  id={`${index}`}
                                  thumbnailUrl={item || ''}
                                  checkable={false}
                                  title="title"
                                  index={index}
                                  showImageGallery={false}
                                />
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {!selectBins && currentTable == 'unrepresented' && (
                      <Grid item xs={12} style={{ height: '100%' }}>
                        <Grid
                          style={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            maxHeight: '100%'
                          }}
                        >
                          {unrepresentedImagesArray?.map((item, index) => {
                            return (
                              <Thumbnail
                                id={`${index}`}
                                thumbnailUrl={item || ''}
                                checkable={false}
                                title="title"
                                index={index}
                                showImageGallery={false}
                              />
                            );
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            {selectBins && (
              <>
                <Grid item xs={6.9} sx={{ height: '35vh' }}>
                  <Stack
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#102633',
                      padding: '5px',
                      color: '#fff',
                      width: '100%',

                      height: '10%'
                    }}
                  >
                    Selected Bins for Incremental Training
                  </Stack>
                  <Grid sx={{ height: '90%' }}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        overflowY: 'auto',
                        borderRadius: '8px',
                        height: '100%'
                      }}
                    >
                      <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#ededed' }}>
                            <StyledTableHeader align="center">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', width: '100px' }}
                              >
                                Bin no
                              </Typography>
                            </StyledTableHeader>
                            <StyledTableHeader align="center">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold' }}
                              >
                                {' '}
                                Type
                              </Typography>
                            </StyledTableHeader>
                            <StyledTableHeader align="center">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold' }}
                              >
                                No. of Images
                              </Typography>
                            </StyledTableHeader>
                            <StyledTableHeader align="center">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold' }}
                              >
                                No of class
                              </Typography>
                            </StyledTableHeader>
                          </TableRow>
                        </TableHead>
                        {checkedUnderRepresented.length > 0 &&
                          checkedUnderRepresented.map(item => {
                            return (
                              <TableRow key={item.bin_number}>
                                <StyledTableCell align="center">
                                  {item.bin_number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  underRepresented
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {item.number_of_test != null
                                    ? item.number_of_test
                                    : 0}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {
                                    item.classnames.filter(classItem => {
                                      const testImages =
                                        classItem.images?.test_images_;
                                      return (
                                        testImages && testImages.length > 0
                                      );
                                    }).length
                                  }
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        {checkedUnRepresented.length > 0 &&
                          checkedUnRepresented.map(item => {
                            return (
                              <TableRow key={item.bin_number}>
                                <StyledTableCell align="center">
                                  {item.bin_number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  un-Represented
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {item.number_of_images}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {
                                    item.classnames?.filter(
                                      i => i.images && i.images.length > 0
                                    ).length
                                  }
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid item xs={5} sx={{ height: '35vh' }}>
                  <Stack
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#102633',
                      padding: '5px',
                      color: '#fff',
                      width: '100%',
                      height: '10%'
                    }}
                  >
                    Selected Bins Summary
                  </Stack>

                  <Grid
                    sx={{
                      height: '90%',
                      backgroundColor: '#FFF',
                      padding: '8px'
                    }}
                  >
                    <Grid>
                      <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                        Total Summarry
                      </Typography>

                      <Typography>
                        No Of Selected Bins :
                        {checkedUnRepresented.length +
                          checkedUnderRepresented.length}
                      </Typography>
                      <Typography> Total Images :{imagesLen()} </Typography>
                      <Typography>
                        {' '}
                        Total Classes:{' '}
                        {
                          Object.values(classDetails()).filter(i => i != 0)
                            .length
                        }{' '}
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                          Class summarry
                        </Typography>
                      </Grid>

                      {Object.entries(classDetails()).map(
                        ([classname, count]) =>
                          count != 0 && (
                            <Grid item xs={6} key={classname}>
                              {classname}: {count} images
                            </Grid>
                          )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {selectBins && (
              <Grid item xs={12}>
                <Stack
                  mt={1}
                  direction={'row'}
                  justifyContent={'center'}
                  spacing={2}
                  style={{ position: 'relative', zIndex: 5, width: '100%' }}
                >
                  <Button
                    version="light"
                    onClick={() => {
                      setCheckedUnRepresented([]);
                      setCheckedUnderRepresented([]);
                      setSelectBins(false);
                    }}
                  >
                    {' '}
                    cancel
                  </Button>
                  <Button
                    onClick={() => {
                      const number = Object.values(classDetails()).filter(
                        i => i != 0
                      );

                      const allAbove50 = number.every(i => i >= 50);

                      if (allAbove50) {
                        setCreateModal(true);
                      } else {
                        snack({
                          message:
                            'Please select at least 50 images for each class',
                          severity: 'error'
                        });
                      }
                    }}
                  >
                    Add for Incremental Training
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <StartIncremental
        open={createModal}
        onClose={() => setCreateModal(false)}
        checkedUnderRepresented={checkedUnderRepresented}
        checkedUnRepresented={checkedUnRepresented}
        selectedModal={selectedModal}
        selectedConfig={selectedConfig || ''}
      />
    </>
  );
};
