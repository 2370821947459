import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface GraphProps {
  width: string;
  height: string;
  labels: string[];
  values: number[];
}

export const Graph: React.FC<GraphProps> = ({
  height,
  width,
  labels,
  values
}) => {
  const colorPalette = ['#92BAE8', '#C2FBFF', '#C2FFDE', '#FFC2C2', '#FFD6A5'];

  // Adjust colors to ensure each label has a unique color
  const backgroundColors = labels.map(
    (_, index) => colorPalette[index % colorPalette.length]
  );

  const barThickness = labels.length > 20 ? 20 : 48; // Dynamically adjust the bar thickness based on the number of labels

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Images'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Class Types'
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20 // Limit the number of ticks to prevent overcrowding on the x-axis
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        label: '',
        backgroundColor: backgroundColors,
        barThickness: barThickness
      }
    ]
  };

  // return <Bar options={options} data={data} height={height} width={width} />;
  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
      <Bar options={options} data={data} height={height} width={width} />
    </div>
  );
};
