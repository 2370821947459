/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * File manager
 * This is my PyNest app.
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  BodyUploadFileFileManagerUploadFilePost,
  CreateDirectoryFileManagerDirectoryPostParams,
  DeleteDirectoryFileManagerDirectoriesDeleteParams,
  DirectoriesContents,
  DirectoriesContentsResponse,
  DownloadZipFileFileManagerDownloadZipFileGetParams,
  FileType,
  GetDirectoryContentsFileManagerDirectoryContentsGetParams,
  HTTPValidationError,
  MoveCopyFilesRequest,
  RenameDirectoryFileManagerRenameDirectoryPatchParams,
  SearchDirectoryFileManagerSearchDirectoryGetParams,
  StreamVideoFileManagerStreamVideoGetParams,
  UploadFileFileManagerUploadFilePostParams
} from '../schemasMedia'
import { mediaAxiosInstanceFn } from './mediaAxiosInstance';



/**
 * @summary Get App Info
 */
export const getAppInfoGet = (
    
 signal?: AbortSignal
) => {
      
      
      return mediaAxiosInstanceFn<unknown>(
      {url: `/`, method: 'GET', signal
    },
      );
    }
  

export const getGetAppInfoGetQueryKey = () => {
    return [`/`] as const;
    }

    
export const getGetAppInfoGetQueryOptions = <TData = Awaited<ReturnType<typeof getAppInfoGet>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppInfoGet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppInfoGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppInfoGet>>> = ({ signal }) => getAppInfoGet(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppInfoGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppInfoGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAppInfoGet>>>
export type GetAppInfoGetQueryError = unknown

/**
 * @summary Get App Info
 */
export const useGetAppInfoGet = <TData = Awaited<ReturnType<typeof getAppInfoGet>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppInfoGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAppInfoGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Upload File
 */
export const uploadFileFileManagerUploadFilePost = (
    bodyUploadFileFileManagerUploadFilePost: BodyUploadFileFileManagerUploadFilePost,
    params: UploadFileFileManagerUploadFilePostParams,
 ) => {
      
      const formData = new FormData();
bodyUploadFileFileManagerUploadFilePost.files.forEach(value => formData.append('files', value));

      return mediaAxiosInstanceFn<unknown>(
      {url: `/file_manager/upload_file`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      );
    }
  


export const getUploadFileFileManagerUploadFilePostMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileFileManagerUploadFilePost>>, TError,{data: BodyUploadFileFileManagerUploadFilePost;params: UploadFileFileManagerUploadFilePostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadFileFileManagerUploadFilePost>>, TError,{data: BodyUploadFileFileManagerUploadFilePost;params: UploadFileFileManagerUploadFilePostParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFileFileManagerUploadFilePost>>, {data: BodyUploadFileFileManagerUploadFilePost;params: UploadFileFileManagerUploadFilePostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  uploadFileFileManagerUploadFilePost(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadFileFileManagerUploadFilePostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFileFileManagerUploadFilePost>>>
    export type UploadFileFileManagerUploadFilePostMutationBody = BodyUploadFileFileManagerUploadFilePost
    export type UploadFileFileManagerUploadFilePostMutationError = HTTPValidationError

    /**
 * @summary Upload File
 */
export const useUploadFileFileManagerUploadFilePost = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileFileManagerUploadFilePost>>, TError,{data: BodyUploadFileFileManagerUploadFilePost;params: UploadFileFileManagerUploadFilePostParams}, TContext>, }
) => {

      const mutationOptions = getUploadFileFileManagerUploadFilePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Directory
 */
export const createDirectoryFileManagerDirectoryPost = (
    params: CreateDirectoryFileManagerDirectoryPostParams,
 ) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContents>(
      {url: `/file_manager/directory `, method: 'POST',
        params
    },
      );
    }
  


export const getCreateDirectoryFileManagerDirectoryPostMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDirectoryFileManagerDirectoryPost>>, TError,{params: CreateDirectoryFileManagerDirectoryPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createDirectoryFileManagerDirectoryPost>>, TError,{params: CreateDirectoryFileManagerDirectoryPostParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDirectoryFileManagerDirectoryPost>>, {params: CreateDirectoryFileManagerDirectoryPostParams}> = (props) => {
          const {params} = props ?? {};

          return  createDirectoryFileManagerDirectoryPost(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDirectoryFileManagerDirectoryPostMutationResult = NonNullable<Awaited<ReturnType<typeof createDirectoryFileManagerDirectoryPost>>>
    
    export type CreateDirectoryFileManagerDirectoryPostMutationError = HTTPValidationError

    /**
 * @summary Create Directory
 */
export const useCreateDirectoryFileManagerDirectoryPost = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDirectoryFileManagerDirectoryPost>>, TError,{params: CreateDirectoryFileManagerDirectoryPostParams}, TContext>, }
) => {

      const mutationOptions = getCreateDirectoryFileManagerDirectoryPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Directory Contents
 */
export const getDirectoryContentsFileManagerDirectoryContentsGet = (
    params: GetDirectoryContentsFileManagerDirectoryContentsGetParams,
 signal?: AbortSignal
) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContentsResponse>(
      {url: `/file_manager/directory/contents`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetDirectoryContentsFileManagerDirectoryContentsGetQueryKey = (params: GetDirectoryContentsFileManagerDirectoryContentsGetParams,) => {
    return [`/file_manager/directory/contents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDirectoryContentsFileManagerDirectoryContentsGetQueryOptions = <TData = Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>, TError = HTTPValidationError>(params: GetDirectoryContentsFileManagerDirectoryContentsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDirectoryContentsFileManagerDirectoryContentsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>> = ({ signal }) => getDirectoryContentsFileManagerDirectoryContentsGet(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDirectoryContentsFileManagerDirectoryContentsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>>
export type GetDirectoryContentsFileManagerDirectoryContentsGetQueryError = HTTPValidationError

/**
 * @summary Get Directory Contents
 */
export const useGetDirectoryContentsFileManagerDirectoryContentsGet = <TData = Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>, TError = HTTPValidationError>(
 params: GetDirectoryContentsFileManagerDirectoryContentsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDirectoryContentsFileManagerDirectoryContentsGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDirectoryContentsFileManagerDirectoryContentsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Process File Transfer
 */
export const processFileTransferFileManagerFilesTypePatch = (
    type: FileType,
    moveCopyFilesRequest: MoveCopyFilesRequest,
 ) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContents>(
      {url: `/file_manager/files/${type}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: moveCopyFilesRequest
    },
      );
    }
  


export const getProcessFileTransferFileManagerFilesTypePatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processFileTransferFileManagerFilesTypePatch>>, TError,{type: FileType;data: MoveCopyFilesRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof processFileTransferFileManagerFilesTypePatch>>, TError,{type: FileType;data: MoveCopyFilesRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof processFileTransferFileManagerFilesTypePatch>>, {type: FileType;data: MoveCopyFilesRequest}> = (props) => {
          const {type,data} = props ?? {};

          return  processFileTransferFileManagerFilesTypePatch(type,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ProcessFileTransferFileManagerFilesTypePatchMutationResult = NonNullable<Awaited<ReturnType<typeof processFileTransferFileManagerFilesTypePatch>>>
    export type ProcessFileTransferFileManagerFilesTypePatchMutationBody = MoveCopyFilesRequest
    export type ProcessFileTransferFileManagerFilesTypePatchMutationError = HTTPValidationError

    /**
 * @summary Process File Transfer
 */
export const useProcessFileTransferFileManagerFilesTypePatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processFileTransferFileManagerFilesTypePatch>>, TError,{type: FileType;data: MoveCopyFilesRequest}, TContext>, }
) => {

      const mutationOptions = getProcessFileTransferFileManagerFilesTypePatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Directory
 */
export const deleteDirectoryFileManagerDirectoriesDelete = (
    deleteDirectoryFileManagerDirectoriesDeleteBody: string[],
    params: DeleteDirectoryFileManagerDirectoriesDeleteParams,
 ) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContents>(
      {url: `/file_manager/directories`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteDirectoryFileManagerDirectoriesDeleteBody,
        params
    },
      );
    }
  


export const getDeleteDirectoryFileManagerDirectoriesDeleteMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDirectoryFileManagerDirectoriesDelete>>, TError,{data: string[];params: DeleteDirectoryFileManagerDirectoriesDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteDirectoryFileManagerDirectoriesDelete>>, TError,{data: string[];params: DeleteDirectoryFileManagerDirectoriesDeleteParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDirectoryFileManagerDirectoriesDelete>>, {data: string[];params: DeleteDirectoryFileManagerDirectoriesDeleteParams}> = (props) => {
          const {data,params} = props ?? {};

          return  deleteDirectoryFileManagerDirectoriesDelete(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDirectoryFileManagerDirectoriesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDirectoryFileManagerDirectoriesDelete>>>
    export type DeleteDirectoryFileManagerDirectoriesDeleteMutationBody = string[]
    export type DeleteDirectoryFileManagerDirectoriesDeleteMutationError = HTTPValidationError

    /**
 * @summary Delete Directory
 */
export const useDeleteDirectoryFileManagerDirectoriesDelete = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDirectoryFileManagerDirectoriesDelete>>, TError,{data: string[];params: DeleteDirectoryFileManagerDirectoriesDeleteParams}, TContext>, }
) => {

      const mutationOptions = getDeleteDirectoryFileManagerDirectoriesDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename Directory
 */
export const renameDirectoryFileManagerRenameDirectoryPatch = (
    params: RenameDirectoryFileManagerRenameDirectoryPatchParams,
 ) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContents>(
      {url: `/file_manager/rename_directory`, method: 'PATCH',
        params
    },
      );
    }
  


export const getRenameDirectoryFileManagerRenameDirectoryPatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameDirectoryFileManagerRenameDirectoryPatch>>, TError,{params: RenameDirectoryFileManagerRenameDirectoryPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof renameDirectoryFileManagerRenameDirectoryPatch>>, TError,{params: RenameDirectoryFileManagerRenameDirectoryPatchParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameDirectoryFileManagerRenameDirectoryPatch>>, {params: RenameDirectoryFileManagerRenameDirectoryPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  renameDirectoryFileManagerRenameDirectoryPatch(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RenameDirectoryFileManagerRenameDirectoryPatchMutationResult = NonNullable<Awaited<ReturnType<typeof renameDirectoryFileManagerRenameDirectoryPatch>>>
    
    export type RenameDirectoryFileManagerRenameDirectoryPatchMutationError = HTTPValidationError

    /**
 * @summary Rename Directory
 */
export const useRenameDirectoryFileManagerRenameDirectoryPatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameDirectoryFileManagerRenameDirectoryPatch>>, TError,{params: RenameDirectoryFileManagerRenameDirectoryPatchParams}, TContext>, }
) => {

      const mutationOptions = getRenameDirectoryFileManagerRenameDirectoryPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Stream Video
 */
export const streamVideoFileManagerStreamVideoGet = (
    params: StreamVideoFileManagerStreamVideoGetParams,
 signal?: AbortSignal
) => {
      
      
      return mediaAxiosInstanceFn<unknown>(
      {url: `/file_manager/stream_video`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getStreamVideoFileManagerStreamVideoGetQueryKey = (params: StreamVideoFileManagerStreamVideoGetParams,) => {
    return [`/file_manager/stream_video`, ...(params ? [params]: [])] as const;
    }

    
export const getStreamVideoFileManagerStreamVideoGetQueryOptions = <TData = Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>, TError = HTTPValidationError>(params: StreamVideoFileManagerStreamVideoGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStreamVideoFileManagerStreamVideoGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>> = ({ signal }) => streamVideoFileManagerStreamVideoGet(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>, TError, TData> & { queryKey: QueryKey }
}

export type StreamVideoFileManagerStreamVideoGetQueryResult = NonNullable<Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>>
export type StreamVideoFileManagerStreamVideoGetQueryError = HTTPValidationError

/**
 * @summary Stream Video
 */
export const useStreamVideoFileManagerStreamVideoGet = <TData = Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>, TError = HTTPValidationError>(
 params: StreamVideoFileManagerStreamVideoGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof streamVideoFileManagerStreamVideoGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getStreamVideoFileManagerStreamVideoGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search Directory
 */
export const searchDirectoryFileManagerSearchDirectoryGet = (
    params: SearchDirectoryFileManagerSearchDirectoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return mediaAxiosInstanceFn<DirectoriesContents>(
      {url: `/file_manager/search_directory`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSearchDirectoryFileManagerSearchDirectoryGetQueryKey = (params: SearchDirectoryFileManagerSearchDirectoryGetParams,) => {
    return [`/file_manager/search_directory`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchDirectoryFileManagerSearchDirectoryGetQueryOptions = <TData = Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>, TError = HTTPValidationError>(params: SearchDirectoryFileManagerSearchDirectoryGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchDirectoryFileManagerSearchDirectoryGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>> = ({ signal }) => searchDirectoryFileManagerSearchDirectoryGet(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchDirectoryFileManagerSearchDirectoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>>
export type SearchDirectoryFileManagerSearchDirectoryGetQueryError = HTTPValidationError

/**
 * @summary Search Directory
 */
export const useSearchDirectoryFileManagerSearchDirectoryGet = <TData = Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>, TError = HTTPValidationError>(
 params: SearchDirectoryFileManagerSearchDirectoryGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchDirectoryFileManagerSearchDirectoryGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchDirectoryFileManagerSearchDirectoryGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Zip File
 */
export const downloadZipFileFileManagerDownloadZipFileGet = (
    params: DownloadZipFileFileManagerDownloadZipFileGetParams,
 signal?: AbortSignal
) => {
      
      
      return mediaAxiosInstanceFn<unknown>(
      {url: `/file_manager/download_zip_file`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDownloadZipFileFileManagerDownloadZipFileGetQueryKey = (params: DownloadZipFileFileManagerDownloadZipFileGetParams,) => {
    return [`/file_manager/download_zip_file`, ...(params ? [params]: [])] as const;
    }

    
export const getDownloadZipFileFileManagerDownloadZipFileGetQueryOptions = <TData = Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>, TError = HTTPValidationError>(params: DownloadZipFileFileManagerDownloadZipFileGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadZipFileFileManagerDownloadZipFileGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>> = ({ signal }) => downloadZipFileFileManagerDownloadZipFileGet(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadZipFileFileManagerDownloadZipFileGetQueryResult = NonNullable<Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>>
export type DownloadZipFileFileManagerDownloadZipFileGetQueryError = HTTPValidationError

/**
 * @summary Download Zip File
 */
export const useDownloadZipFileFileManagerDownloadZipFileGet = <TData = Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>, TError = HTTPValidationError>(
 params: DownloadZipFileFileManagerDownloadZipFileGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadZipFileFileManagerDownloadZipFileGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadZipFileFileManagerDownloadZipFileGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




