/* eslint-disable */
import { Stack } from '@mui/material';
import { useDeleteGroup } from 'api/sdk';
import { Button, Modal, RecordValue, Typography } from 'components';
import { useSnack } from 'plugins/snack';
import { Snack } from 'plugins/snack/Snack';
import React, { useState } from 'react';
interface IRemoveGroupModalProps {
  open: boolean;
  onClose: () => void;
  onSucess?: () => void;
  GrpName: any | any[];
  GrpId: any | any[];
}

export const RemoveGroupModal: React.FC<IRemoveGroupModalProps> = ({
  open,
  onClose,
  onSucess,
  GrpName,
  GrpId
}) => {
  const { mutateAsync: RemoveGroup, isLoading: isEditLoading } =
    useDeleteGroup();

  const snack = useSnack();

  const handleRemoveGroup = async () => {
    try {
      // Pass the required variables to the RemoveGroup function
      await RemoveGroup({ params: { group_id: GrpId ?? '' } });
      snack({
        message: 'Group deleted successfully',
        severity: 'success'
      });
    } catch (err: any) {
      if (err?.response?.status === 422) {
        snack({
          message: 'Group deletion failed',
          severity: 'error'
        });
      } else {
        snack({
          message: 'An unexpected error occurred',
          severity: 'error'
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      size="md"
    >
      <>
        <Stack
          style={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            mt={3}
            mb={1}
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Are you sure you want delete {GrpName}?
          </Typography>
          <Typography mb={1} style={{ fontSize: '16px' }}>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Note:</span>{' '}
            This action will remove all the viewers and configuration in this
            group.
          </Typography>
        </Stack>
        {/* Buttons */}
        <Stack
          mt={2}
          direction="row"
          spacing={2}
          width={'100%'}
          justifyContent={'center'}
        >
          <Button
            type="submit"
            variant="contained"
            version="light"
            onClick={() => {
              onClose();
            }}
          >
            No
          </Button>
          <Button
            type="submit"
            variant="contained"
            version="dark"
            onClick={async () => {
              await handleRemoveGroup();

              if (onSucess) {
                onSucess();
              }
            }}
          >
            Yes, Delete
          </Button>
        </Stack>
      </>
    </Modal>
  );
};
