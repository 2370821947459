/* eslint-disable */

import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import { Stage, Layer, Image as KonvaImage, Circle } from 'react-konva';
import useImage from 'use-image';
import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  IconButton,
  CircularProgress,
  Skeleton
} from '@mui/material';
import Thumbnail from './Thumbnail';
import {
  ConfigSchema,
  LabelAsisitRequestQualityControl,
  LabelAsisitRequestQualityControlIncremental,
  ObjResponseSchemaCount,
  ObjSchema,
  QualitygeneratemaskRequest,
  SyncObjectsRequest
} from 'schemas';
import { Graph } from './graph';
import { SelectInput, TextField } from 'components/Form';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Default from '../../assets/images/default.svg';
import forwardArrow from '../../assets/images/forwardArrow.svg';

import { useNavigate } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import {
  useStartIncremetal,
  useStartTraining,
  useModelArchitectureList,
  useSyncObjectByDataid
} from 'api/sdk';
import { v4 } from 'uuid';
import SkeletonLoader from 'components/SkeletonLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface TrainingModalProps {
  currentInferenceID: string;
  selectedConfig: string;
  mode: string;
  trainingModal: boolean;
  setTrainingModal: (data: boolean) => void;
  objectAll: ObjResponseSchemaCount | undefined;
  selectedModel?: string;
  setStep?: (data: number) => void;
}

const TrainingModal: React.FC<TrainingModalProps> = ({
  currentInferenceID,
  selectedConfig,
  selectedModel,
  mode,
  setStep,
  trainingModal,
  setTrainingModal,
  objectAll
}) => {
  const [modelArchitectureList, setModelArchitectureList] = useState<string[]>(
    []
  );
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const profile = useSelector((state: RootState) => state.auth.profile);
  // Extract the feature list from the profile
  const userFeatures = profile?.features_list || [];
  const { data: objectData, refetch: startArchitecture } =
    useModelArchitectureList();
  const { mutateAsync: startTraining, error, isError } = useStartTraining();
  const { mutateAsync: startIncremental } = useStartIncremetal();

  const navigate = useNavigate();
  interface FormValues {
    modelName: string;
    trainTestSplit: number;
    batchSize: number;
    learningRate: number;
    epochs: number;
    classesType: string;
  }
  const initialValues: FormValues = {
    modelName: '',
    classesType: '',
    trainTestSplit: 80,
    batchSize: 16,
    learningRate: 0.0001,
    epochs: 50
  };

  const validationInferenceSchema = Yup.object({
    modelName: Yup.string().required('Model Name is required'),
    trainTestSplit: Yup.number()
      .required('Train Test Split is required')
      .min(50, 'Train Test Split must be between 50 -95')
      .max(95, 'Train Test Split must be between 50 -95'),
    batchSize: Yup.number()
      .required('Batch Size is required')
      .min(1, 'Batch Size must be between 1-32')
      .max(32, 'Batch Size must be between 1-32'),
    learningRate: Yup.number()
      .required('Learning Rate is required')
      .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
      .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
    epochs: Yup.number()
      .required('Epochs is required')
      .min(1, 'Epochs must be between 1-500')
      .max(500, 'Epochs must be between 1-500')
  });

  const validationSchema = Yup.object({
    modelName: Yup.string().required('Model Name is required'),
    trainTestSplit: Yup.number()
      .required('Train Test Split is required')
      .min(50, 'Train Test Split must be between 50 -95')
      .max(95, 'Train Test Split must be between 50 -95'),
    batchSize: Yup.number()
      .required('Batch Size is required')
      .min(1, 'Batch Size must be between 1-32')
      .max(32, 'Batch Size must be between 1-32'),
    learningRate: Yup.number()
      .required('Learning Rate is required')
      .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
      .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
    epochs: Yup.number()
      .required('Epochs is required')
      .min(1, 'Epochs must be between 1-500')
      .max(500, 'Epochs must be between 1-500')
  });
  useEffect(() => {
    startArchitecture().then(res => {
      res != undefined &&
        setModelArchitectureList(res?.data?.data?.names || []);
    });
  }, []);

  const toggleAdvancedSettings = () => {
    setAdvancedDetails(prev => !prev);
  };

  const handleSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>
  ) => {
    const currentID = currentInferenceID;
    const data: LabelAsisitRequestQualityControl = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,
      model_architecture: values.classesType || modelArchitectureList[4],
      data_id: currentID,
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit
    };
    const data2: LabelAsisitRequestQualityControlIncremental = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,
      data_id: currentID,
      // inference_id: currentID || '',
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit,
      model_id: selectedModel || ''
    };
    if (mode == 'TRAINING') {
      try {
        await startTraining({ data });
        setStep && setStep(1);
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    } else {
      try {
        await startIncremental({ data: data2 });
        setStep && setStep(1);
        navigate('/train');
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    }
  };

  return (
    <Modal
      open={trainingModal}
      onClose={() => setTrainingModal(false)}
      // title="Training Summary"
    >
      <>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              mode === 'TRAINING' ? validationSchema : validationInferenceSchema
            }
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Labelled Images
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      {objectAll?.labeled_count}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total no. of classes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      {' '}
                      {
                        Object.keys(objectAll?.classname_counts || {}).length
                      }{' '}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Model Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="modelName"
                      type="text"
                      variant="outlined"
                      error={errors.modelName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textDecoration: 'underline', // Underline on hover
                        color: '#2AB5B7' // Color changes based on visibility
                      }}
                      onClick={toggleAdvancedSettings}
                    >
                      {(profile?.role === 'organization_admin' ||
                        userFeatures?.includes('ADVANCEDSETTINGS')) &&
                      advancedDetails
                        ? 'Hide Advanced Settings'
                        : 'View Advanced Settings'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  {advancedDetails && (
                    <>
                      {mode == 'TRAINING' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 'bold' }}
                            >
                              Model Architecture
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} justifyContent="center">
                            {modelArchitectureList && (
                              <SelectInput
                                label="Select Model Architecture"
                                placeholder="Select Model Architecture"
                                variant="outlined"
                                width={'220px'}
                                name="classesType"
                                value={
                                  values.classesType || modelArchitectureList[4]
                                }
                                options={
                                  modelArchitectureList &&
                                  modelArchitectureList?.map(obj => ({
                                    label: obj,
                                    value: obj
                                  }))
                                }
                                onChange={value =>
                                  setFieldValue('classesType', value)
                                }
                              />
                            )}
                            {errors.classesType && touched.classesType && (
                              <Typography
                                color="error"
                                variant="caption"
                                style={{ marginLeft: '20px' }}
                              >
                                {errors.classesType}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Train Test Split
                        </Typography>
                        <Typography variant="caption" align="center">
                          (50 to 95)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="trainTestSplit"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Batch Size
                        </Typography>
                        <Typography variant="caption">(1 to 32)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="batchSize"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Learning Rate
                        </Typography>
                        <Typography variant="caption">
                          (0.00001 to 0.1)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="learningRate"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Epochs
                        </Typography>
                        <Typography variant="caption">(1 to 500)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="epochs"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}

                  <Stack
                    direction="row"
                    justifyContent={'center'}
                    width={'100%'}
                    mt={2}
                  >
                    <Button type="submit" disabled={isSubmitting}>
                      Start Training
                    </Button>
                  </Stack>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </Modal>
  );
};

export default TrainingModal;
