import { Box, styled, IconButton, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { Check } from 'components/Form';

interface ThumbnailProps {
  thumbnailUrl: string;
  title: string;
  checkable?: boolean;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
  onClick?: () => void;
  handelChangeImageIndex?: (id: number) => void;
  index: number;
  showImageGallery: boolean;
  type?: string;
  id: string;
}

const getBorderColor = (type?: string) => {
  switch (type) {
    case 'OK':
      return 'green';
    case 'NG':
      return 'red';
    default:
      return '';
  }
};

const ImageContainer = styled('div')<{ type?: string }>(({ theme, type }) => ({
  height: 100,
  width: 100,
  borderRadius: 5,
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  float: 'left',
  padding: type ? '3px' : '0px',
  marginRight: 10,
  marginBottom: 10,
  border: type ? `6px solid green` : `2px solid ${theme.palette.grey[900]}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5
  }
}));

export const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbnailUrl,
  title,
  checkable,
  checked,
  onCheck,
  onClick,
  index,
  handelChangeImageIndex = () => {},
  showImageGallery,
  type,
  id
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect after image is loaded
        }
      },
      { threshold: 0.1 } // Load when 10% of the container is visible
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  const handleContainerClick = () => {
    if (checkable && onCheck) {
      onCheck(!checked);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <ImageContainer
      ref={imageRef}
      type={type}
      onClick={handleContainerClick}
      onDoubleClick={() => handelChangeImageIndex(index)}
    >
      {checkable && (
        <Check
          checked={checked}
          onChange={() => onCheck && onCheck(!checked)}
          overlap={true}
        />
      )}
      {isVisible ? (
        <img src={thumbnailUrl} alt={title} draggable={false} loading="lazy" />
      ) : (
        <CircularProgress size={24} />

        // <Box sx={{ width: '100%', height: '100%', backgroundColor: '#ddd' }} />
      )}
      {/* <img src={thumbnailUrl} alt={title} draggable={false} loading="lazy" /> */}
    </ImageContainer>
  );
};

export default Thumbnail;
