import { Button, Card, Modal, Typography } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  LinearProgress,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Slider } from 'components/slider';
import PieChartComponent from 'components/charts/pieChart';
import PieChart from 'components/charts/pieChart';
import Switch from '@mui/material/Switch';
import Default from '../../assets/images/default.svg';
import {
  ConfigSchema,
  InferenceResultSchema,
  LabelAsisitRequestQualityControl,
  ObjSchema
} from 'schemas';

import { useGetInferenceResult } from 'api/sdk';
import axios, { AxiosResponse } from 'axios';
import { useWebSocket } from 'hooks';
import Env from 'config/env';
import Thumbnail from '../train/Thumbnail';
import { useNavigate } from 'react-router-dom';
import { Graph } from './graph';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface MoveImageProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;
  handelRefetchObjects: () => Promise<any>;

  setCurrentInferenceID: (inferenceID: string | undefined) => void;
  currentInferenceID: string | undefined;

  selectedModel: string | undefined;
  statusValue: string | undefined;
  setStep: any;
  refetchInference: () => void;
}

interface ConfusionMatrixProps {
  data: {
    [actualClass: string]: {
      [predictedClass: string]: number;
    };
  };
}
export const LiveInferenceData: React.FC<MoveImageProps> = ({
  selectedConfig,
  onChange,
  currentInferenceID,
  setStep,
  refetchInference,
  statusValue
}) => {
  const [progress, setProgress] = useState<any>('');
  const [status, setStatus] = useState('QUEUED');
  console.log('currentInferenceID', currentInferenceID);
  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${currentInferenceID}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const profile = useSelector((state: RootState) => state.auth.profile);
  const userFeatures = profile?.features_list || [];

  const handleEndClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleMessagesReceived = (message: any) => {
    const data = message?.data;
    const type = message?.type;
    setStatus(type);

    if (message?.pipeline_type === 'quality_control_live_inference') {
      setProgress(data);
    }
    if (type == 'COMPLETED') {
      setIsModalOpen(true);
    }
    if (type === 'STOPPED') {
      handleCloseModal();
      refetchInference();
      onChange(1);
    }
  };
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const updateQueueStatus = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/training/queue_status`,
        {
          status: 'STOP',
          data_id: currentInferenceID,
          config_id: selectedConfig
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type
          }
        }
      );

      // Delay execution of subsequent functions by 2 seconds
      setTimeout(() => {
        handleCloseModal();
        refetchInference();
        onChange(1);
      }, 2000); // 2000 milliseconds = 2 seconds

      // Handle success as needed, e.g., refresh the data
    } catch (error) {
      console.error('Error updating queue status:', error);
    }
  };
  return (
    <>
      <Button
        version="light"
        onClick={() => {
          setStep(1);
          refetchInference();
        }}
      >
        {' '}
        Back{' '}
      </Button>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={12} md={12}>
          <Card
            style={{
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              padding: '1%',
              justifyContent: 'space-between'
            }}
          >
            <Grid container>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Live Inference Dashboard{' '}
              </Typography>
            </Grid>

            <Grid container alignItems="center" justifyContent="center">
              {/* Left Image */}
              <Grid
                item
                xs={5}
                container
                direction="column"
                alignItems="center"
              >
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Image
                </Typography>
                <img
                  style={{
                    width: '70%',
                    height: '100%',
                    objectFit: 'contain',
                    marginTop: '1vh'
                  }}
                  src={
                    progress && progress?.image_url
                      ? progress?.image_url
                      : Default
                  }
                  alt="Image"
                />
              </Grid>

              {/* Divider Line */}
              <Grid
                item
                xs={1}
                container
                justifyContent="center"
                style={{ height: '100%' }}
              >
                <div
                  style={{
                    height: '100%',
                    borderLeft: '2px solid gray' // Using border for the vertical divider
                  }}
                ></div>
              </Grid>

              {/* Right Image */}
              <Grid
                item
                xs={5}
                container
                direction="column"
                alignItems="center"
              >
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Hotspot
                </Typography>
                {/* <img
                  style={{
                    width: '70%',
                    height: '100%',
                    objectFit: 'contain',
                    marginTop: '1vh'
                  }}
                  src={
                    progress && !progress?.is_hotspot
                      ? progress?.image_url
                      : progress?.hotspot_url
                      ? progress?.hotspot_url
                      : Default
                  }
                  alt="Hotspot"
                /> */}
                <div
                  style={{ position: 'relative', width: '70%', height: '100%' }}
                >
                  {/* Blue Wrapper */}
                  {progress && !progress?.is_hotspot && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%', // Match the container's width
                        height: '100%', // Match the container's height
                        backgroundColor: 'rgba(0, 0, 255, 0.3)', // Semi-transparent blue
                        zIndex: 1
                      }}
                    ></div>
                  )}

                  {/* Image */}
                  <img
                    style={{
                      width: '100%', // Adjust to fit the container
                      height: '100%',
                      objectFit: 'contain',
                      marginTop: '1vh'
                    }}
                    src={
                      progress && !progress?.is_hotspot
                        ? progress?.image_url
                        : progress?.hotspot_url
                        ? progress?.hotspot_url
                        : Default
                    }
                    alt="Hotspot"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} mt={1}>
                <Card
                  style={{
                    background: '#EEEEEE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '10px auto',
                    padding: '10px 20px',
                    marginBottom: '10px'
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          opacity: 0.8,
                          textAlign: 'center'
                        }}
                      >
                        AI Prediction :{' '}
                        {progress && progress?.classname
                          ? progress?.classname
                          : 'N/A'}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ width: '100%' }} // Ensure the Stack spans the full width
                      >
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            opacity: 0.8,
                            textAlign: 'center',
                            flexGrow: 1 // This centers the text
                          }}
                        >
                          Probability:{' '}
                          {progress && progress?.probability
                            ? Number(progress?.probability).toFixed(2)
                            : 'N/A'}{' '}
                          %
                        </Typography>
                        <Button
                          style={{
                            width: '100px',
                            height: '45px',
                            marginRight: '10px' // Add space to the right
                          }}
                          // disabled={
                          //   !progress?.inference_id || !currentInferenceID
                          // }
                          onClick={handleEndClick}
                        >
                          End
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {/* Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        {status === 'COMPLETED' ? (
          <>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Live Inference Completed
            </Typography>
            <Typography style={{ marginTop: '10px' }}>
              The live inference session has been successfully completed.
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onChange(1);
                  handleCloseModal();
                  refetchInference();
                }}
              >
                Okay
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Live Inference
            </Typography>
            <Typography style={{ marginTop: '10px' }}>
              Are you sure you want to end the live inference session?
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
              <Button
                variant="outlined"
                version="light"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={
                  updateQueueStatus
                  // handleCloseModal
                }
              >
                Confirm
              </Button>
            </Stack>
          </>
        )}
      </Modal>
    </>
  );
};
