import { Grid, Stack } from '@mui/material';
import { Card, Typography } from 'components';
import React from 'react';

const ConfigDetails = () => {
  return (
    <>
      <Card></Card>
    </>
  );
};

export default ConfigDetails;
