import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Typography,
  Avatar,
  Stack,
  ListItemAvatar,
  ListItemText,
  TableCell,
  SelectChangeEvent,
  Box
} from '@mui/material';

import MultiSelector from 'components/MultiSelect';
import { Switch } from 'components/Switch';
import styled from 'styled-components';

import { Button } from 'components';
import { UnderRepresentedList } from 'schemas';

interface UnderRepresentedProps {
  UnderRepresentedList: UnderRepresentedList[];
  handleObjectSelection: (type: string, bin: number | null | undefined) => void;

  selectBins: boolean;
  checkedUnderRepresented: UnderRepresentedList[];
  currentTable: string | undefined;
  currentBin: number | null | undefined;

  setSelectBins: (value: boolean) => void;
  setCheckedUnderRepresented: (data: UnderRepresentedList[]) => void;
}

interface selectBinProps {
  selectbin: boolean;
}
const StyledTableCell = styled(TableCell)<selectBinProps>(
  ({ theme, selectbin }) => ({
    padding: '4px',
    backgroundColor: selectbin ? '#00000059' : '#ededed'
  })
);

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  border: `1px solid #ededed`,
  padding: '2px',
  backgroundColor: '#ededed',
  position: 'sticky',
  top: 0,
  zIndex: 1
}));
const UnderRepresented: React.FC<UnderRepresentedProps> = ({
  UnderRepresentedList,
  handleObjectSelection,
  selectBins,
  checkedUnderRepresented,
  currentTable,
  currentBin,

  setCheckedUnderRepresented,
  setSelectBins
}) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: '92%',
          overflowY: 'auto',
          borderRadius: '8px'
        }}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#ededed' }}>
              <StyledTableHeader align="center">
                {' '}
                <Typography
                  variant="caption"
                  style={{ fontWeight: 'bold', width: '100px' }}
                >
                  {' '}
                  Bin no
                </Typography>
              </StyledTableHeader>
              <StyledTableHeader align="center">
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  {' '}
                  No. of Images Train set
                </Typography>
              </StyledTableHeader>
              <StyledTableHeader align="center">
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  No. of Images in Test set
                </Typography>
              </StyledTableHeader>
              <StyledTableHeader align="center">
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  {' '}
                  % in Train set
                </Typography>
              </StyledTableHeader>
            </TableRow>
          </TableHead>

          <TableBody>
            {UnderRepresentedList &&
              UnderRepresentedList.map(item => {
                return (
                  <TableRow key={item.bin_number}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      selectbin={selectBins}
                    >
                      <Button
                        disableRipple
                        onClick={() => {
                          if (selectBins) {
                            const isExist = checkedUnderRepresented.some(
                              i => i.bin_number === item.bin_number
                            );

                            if (isExist) {
                              const temp = checkedUnderRepresented.filter(
                                x => x.bin_number !== item.bin_number
                              );
                              setCheckedUnderRepresented(temp);
                            } else {
                              setCheckedUnderRepresented([
                                ...checkedUnderRepresented,
                                item
                              ]);
                            }
                          } else {
                            if (
                              currentTable === 'underrepresented' &&
                              currentBin === item.bin_number
                            ) {
                              handleObjectSelection('underrepresented', null);
                            } else {
                              handleObjectSelection(
                                'underrepresented',
                                item.bin_number || 0
                              );
                            }
                          }
                        }}
                        version={
                          selectBins
                            ? checkedUnderRepresented
                                .map(i => i.bin_number)
                                .includes(item.bin_number)
                              ? 'dark'
                              : 'light'
                            : currentTable == 'underrepresented' &&
                              currentBin == item.bin_number
                            ? 'dark'
                            : 'light'
                        }
                        sx={{
                          width: '35px',
                          height: '25px',
                          minWidth: '0px',
                          border: 'none',
                          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                        }}
                      >
                        {item.bin_number}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center" selectbin={selectBins}>
                      {item.number_of_train}
                    </StyledTableCell>
                    <StyledTableCell align="center" selectbin={selectBins}>
                      {item.number_of_test}
                    </StyledTableCell>
                    <StyledTableCell align="center" selectbin={selectBins}>
                      {item.percentage_in_train} %
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UnderRepresented;
