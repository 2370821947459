import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface GraphProps {
  width: string;
  height: string;
  data: {
    class_name: string;
    user_class_count: number;
    ai_class_count: number;
  }[];
}

export const Graph: React.FC<GraphProps> = ({ height, width, data }) => {
  // Extract labels and values for user and AI counts
  const labels = data?.map(item => item?.class_name);
  const userCounts = data?.map(item => item?.user_class_count);
  const aiCounts = data?.map(item => item?.ai_class_count);

  // Color palette
  const colorPalette = ['#92BAE8', '#C2FBFF', '#C2FFDE', '#FFC2C2', '#FFD6A5'];

  // Function to generate random color from the palette
  const getRandomColor = () =>
    colorPalette[Math.floor(Math.random() * colorPalette.length)];

  const chartData = {
    labels,
    datasets: [
      // {
      //   label: 'User Class Count',
      //   data: userCounts,
      //   backgroundColor: '#92BAE8', // Assign random color for each bar
      //   barThickness: 20,
      //   maxBarThickness: 40
      // },
      // Add User Class Count dataset only if userCounts has non-zero values
      ...(userCounts?.some(count => count > 0)
        ? [
            {
              label: 'User Class Count',
              data: userCounts,
              backgroundColor: '#92BAE8',
              barThickness: 20,
              maxBarThickness: 40
            }
          ]
        : []),
      {
        label: 'AI Class Count',
        data: aiCounts,
        backgroundColor: '#6CE2A2', // Assign random color for each bar
        barThickness: 20,
        maxBarThickness: 40
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Class Types',
          color: 'black'
        },
        stacked: false,
        grid: {
          display: false
        },
        categoryPercentage: 0.5, // More spacing between groups
        barPercentage: 0.6 // More spacing between bars in each group
      },
      y: {
        title: {
          display: true,
          text: 'Number of Images',
          color: 'black'
        },
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          color: 'rgb(0, 0, 0)'
        }
      }
    }
  };

  return (
    <Bar options={options} data={chartData} height={height} width={width} />
  );
};
