import Modal from 'components/Modal';
import React from 'react';

interface VideoPlayerModalProps {
  imageModal: boolean;
  imageSrc: string;
  setImageModal: (value: boolean) => void;
  setimgSrc: (value: string) => void;
}

const ImageModal: React.FC<VideoPlayerModalProps> = ({
  imageModal,
  imageSrc,
  setImageModal,
  setimgSrc
}) => {
  return (
    <Modal
      open={imageModal}
      onClose={() => {
        setImageModal(false);
        setimgSrc('');
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        onClick={e => e.stopPropagation()}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '70vh',
          maxWidth: '70vw',
          overflow: 'hidden'
        }}
      >
        <img
          onClick={e => e.stopPropagation()}
          src={imageSrc}
          alt=""
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain'
          }}
        />
      </div>
    </Modal>
  );
};

export default ImageModal;
