import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card, Typography, Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const StorageDetails = () => {
  // Dummy Data
  const data = {
    labels: [
      'Total Storage for Data',
      'Total Storage for Models',
      'Available Storage'
    ],
    datasets: [
      {
        data: [426, 201, 1300], // Values in GB/TB
        backgroundColor: ['#001E3C', '#007FFF', '#CFE9FE'], // Colors for each section
        hoverBackgroundColor: ['#003366', '#0056b3', '#A6D8F7'] // Colors on hover
      }
    ]
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography
        variant="subtitle1"
        color="primary"
        fontWeight="bold"
        gutterBottom
      >
        Storage Details
      </Typography>
      <Grid container spacing={4} alignItems="center">
        {/* Chart Section */}
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            display="inline-flex"
            sx={{ position: 'relative', width: 250, height: 250 }}
          >
            <Doughnut
              data={data}
              options={{
                cutout: '70%', // Makes it a donut chart
                maintainAspectRatio: true,

                plugins: {
                  legend: {
                    display: false // This hides the legend
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${value} GB`;
                      }
                    }
                  }
                }
              }}
            />
            <Box
              top={0}
              left={0}
              right={0}
              bottom={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction="column" spacing={0} alignItems="center">
                <Typography
                  variant="caption"
                  component="div"
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  Total <br /> Storage
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  style={{
                    fontSize: '28px',
                    fontWeight: 'bold',
                    color: '#1E4E6A',
                    textAlign: 'center'
                  }}
                >
                  2TB
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>

        {/* Legend Section */}
        <Grid item xs={12}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#001E3C'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Total Storage for Data
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                426 GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#007FFF'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Total Storage for Models
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                201 GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#CFE9FE'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Available Storage
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                1.3 TB
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default StorageDetails;
