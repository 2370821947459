import { ConfigurationListPage } from 'pages/configuration';
import { TrainPage } from 'pages/train';
import { InferenceReports } from 'pages/InferenceReports';

import { NoveltyDetection } from 'pages/noveltyDetection';
import { Reports } from 'pages/Reports';
import { MediaManager } from 'pages/MediaManager';
import { LabelAssist1 } from 'pages/LabelAssist1';
import { LiveData } from 'pages/LiveData';
import { LiveInference } from 'pages/LiveInference';
import { Group } from 'pages/Group';
export interface RouteCoonfig {
  [key: string]: {
    route?: string;
    generate?: () => string;
    component?: React.ReactNode;
    name: string;
    pageTittle?: string;
    items?: RouteCoonfig;
    disabled?: boolean;
    Icon?: any;
  };
}

const config: RouteCoonfig = {
  CONFIGURATION: {
    name: 'Configuration',
    route: '/configuration',
    component: <ConfigurationListPage />
  },
  DATA: {
    name: 'Data',
    route: '/Media',
    items: {
      live: {
        name: 'Live Camera Data',
        route: '/LIVE',
        component: <LiveData />
      },
      media: {
        name: 'Recorded Data',
        route: '/Media',
        component: <MediaManager />
      }
    }
  },
  TRAINING: {
    name: 'Train',
    route: '/train',
    component: <TrainPage />
  },

  INFERENCE: {
    name: 'Inference',
    route: '/inference',

    items: {
      live: {
        name: 'Live Inference',
        route: '/LiveInference',
        component: <LiveInference />
      },
      media: {
        name: 'Batch Inference',
        route: '/inference',
        component: <InferenceReports />
      }
    }
  },

  NOVELTY: {
    name: 'Novelty Detection',
    route: '/novelty',
    component: <NoveltyDetection />
  },

  REPORTS: {
    name: 'Reports',
    route: '/reports/recorded',
    component: <Reports />
  },
  SETTINGS: {
    name: 'Setting',
    route: '/setting',
    component: <Group />
  },

  Label: {
    name: 'labelAssist1',
    route: '/labelAssist1',
    component: <LabelAssist1 />
  }
};

export default config;
