import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material';
import { Card, Typography } from 'components';
import React, { useState } from 'react';
import GroupStatistics from './GroupStatistics';
import StorageDetails from './StorageDetails';
import AccessDetails from './AccessDetails';
import ConfigDetails from './ConfigDetails';
import { SelectInput } from 'components/Form';

const Admindashboard = () => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const groupNames = [
    { label: 'Group 1', value: 'group1' },
    { label: 'Group 2', value: 'group2' },
    { label: 'Group 3', value: 'group3' },
    { label: 'Group 4', value: 'group4' },
    { label: 'Group 5', value: 'group5' }
  ];
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: 2.5
        }}
      >
        Admin Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <GroupStatistics />
        </Grid>
        <Grid item xs={2}>
          <Card style={{ backgroundColor: '#102633' }}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="flex-start"
              alignItems="flex-start"
              ml={1}
            >
              <RadioGroup
                value={selectedValue}
                onChange={handleChange}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <FormControlLabel
                  label={<Typography variant="body1">Self</Typography>}
                  control={
                    <Radio
                      sx={{
                        color: '#FFFFFF',
                        '&.Mui-checked': {
                          color: '#FFFFFF'
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)'
                        },
                        width: '24px', // Consistent size
                        height: '24px' // Consistent size
                      }}
                    />
                  }
                  sx={{
                    color: '#FFFFFF'
                  }}
                />
                <FormControlLabel
                  value="group"
                  control={
                    <Radio
                      sx={{
                        color: '#FFFFFF',
                        '&.Mui-checked': {
                          color: '#FFFFFF'
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)'
                        },
                        width: '24px', // Consistent size
                        height: '24px' // Consistent size
                      }}
                    />
                  }
                  label={<Typography variant="body1">Group</Typography>}
                  sx={{
                    color: '#FFFFFF'
                  }}
                />
              </RadioGroup>
              <Grid container xs={12}>
                <Grid item xs={12} style={{ background: 'white' }}>
                  <SelectInput
                    fullwidth
                    // label="Select Group"
                    placeholder="Select Group"
                    variant="filled"
                    name="groups"
                    value={selectedGroup}
                    options={groupNames} // Use dummy group data here
                    onChange={value => setSelectedGroup(value)} // Update the state on change
                  />
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {' '}
          <StorageDetails />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {' '}
          <AccessDetails />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {' '}
          <ConfigDetails />
        </Grid>
      </Grid>
    </>
  );
};

export default Admindashboard;
