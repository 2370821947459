/* eslint-disable */

import { Stack } from '@mui/material';
import { useCreateObjects, useGetObjectsByDataId } from 'api/sdk';
import { Button, Modal, Typography } from 'components';
import { SelectInput } from 'components/Form';

import { Snack } from 'plugins/snack/Snack';
import React, { useEffect, useState } from 'react';
import { CreateObjectSchema, ObjSchema } from 'schemas';

interface DropClassProps {
  open: boolean;
  onClose: () => void;
  modelsClass: string[];
  selectedObjects: ObjSchema[];
  selectedConfig: string;
  handleSync: (data: ObjSchema[], inferenceid?: string) => Promise<void>;
  setCurrentInferenceID: (data: string) => void;
  setStep: (data: number) => void;
}

const DropClass: React.FC<DropClassProps> = ({
  open,
  onClose,
  modelsClass,
  selectedObjects,
  selectedConfig,
  handleSync,
  setStep,
  setCurrentInferenceID
}) => {
  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const [newObjects, setNewObjects] = useState<ObjSchema[]>();

  const [newInferenceID, setNewInferenceID] = useState<string>('');

  const {
    data: objectData,
    refetch: currentObjectsRefetch,
    isLoading: currentObjectsLoading
  } = useGetObjectsByDataId(newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);

  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = selectedObjects.find(
          j => j.classname === i.classname
        );

        temp2 = {
          ...i,
          images: currentObj?.images || [],
          groupname: currentObj?.groupname || ''
        };

        transformedObjects.push(temp2);
      });

      handleSync(transformedObjects, transformedObjects[0].data_id || '').then(
        res => {
          setCurrentInferenceID(transformedObjects[0].data_id as string);
          setStep(3);
          onClose();
        }
      );
    }
  }, [newObjects]);

  return (
    <Modal open={open} title="Drop Class" onClose={onClose}>
      <div>
        {selectedObjects
          .filter(i => !modelsClass.includes(i.classname))
          .map(i => i.classname)
          .join(', ')}
      </div>

      <Typography>
        {' '}
        above classes will be dropped as they are not in the selected model.
      </Typography>
      <Stack direction={'row'} spacing={2} justifyContent={'center'}>
        <Button version="light" onClick={onClose}>
          cancel
        </Button>
        <Button
          loading={createObjectLoading || currentObjectsLoading}
          onClick={() => {
            const classesArrya = selectedObjects
              .filter(i => modelsClass.includes(i.classname))
              .map(i => i.classname);

            const data: CreateObjectSchema[] = [];
            classesArrya.forEach(i => {
              data.push({
                classname: i,
                configuration_id: selectedConfig || '',
                mode: 'INFERENCE',
                groupname: null
              });
            });
            CreateObjects({
              configId: selectedConfig || '',
              data: data
            }).then(res => {
              setNewInferenceID(res.data[0].data_id as string);
            });
          }}
        >
          proceed
        </Button>
      </Stack>
    </Modal>
  );
};

export default DropClass;
