/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type AccessLevel = typeof AccessLevel[keyof typeof AccessLevel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessLevel = {
  group: 'group',
  viewer: 'viewer',
} as const;
