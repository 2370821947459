import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';

import { Main } from './main';
import { MoveImage } from './moveImage';

import { MoveLiveImage } from './moveImageLive';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { InferenceSchema, ObjSchema, TrainingResultSchema } from 'schemas';
import {
  useGetInferenceList,
  useGetModelList,
  useGetObjectsByDataId,
  useListConfigurations
} from 'api/sdk';
import { useDispatch } from 'react-redux';
import { setAllConfigs } from 'store/configSlice';
import { MainLive } from './mainLive';

export const InferenceReports: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [statusValue, setStatusValue] = useState<string>('');
  const onChange = (num: number) => {
    setStep(num);
  };

  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );

  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);
  const configs = useSelector((state: RootState) => state.config.allConfigs);

  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const [models, setModels] = useState<TrainingResultSchema[]>([]);
  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [inferenceList, serInferenceList] = useState<InferenceSchema[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>('');

  const { refetch: InferenceListArray, isLoading: inferenceLoading } =
    useGetInferenceList({
      config_id: selectedConfig || ''
    });

  const { refetch: currentObjectsRefetch, isLoading: objectsloading } =
    useGetObjectsByDataId(currentInferenceID || '');

  useEffect(() => {
    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
      });
    }
  }, [currentInferenceID]);

  const { refetch: refetchModels } = useGetModelList({
    config_id: selectedConfig || ''
  });
  const profile = useSelector((state: RootState) => state.auth.profile);

  useEffect(() => {
    InferenceListArray();
    currentObjectsRefetch();
  }, [step]);

  useEffect(() => {
    refetchModels().then(res => {
      setModels(res?.data?.data || []);
    });
    InferenceListArray().then(res => {
      serInferenceList(res?.data?.data || []);
    });
  }, [selectedConfig, inferenceList, step]);
  const dispatch = useDispatch();

  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          objects={currentObjects}
          handelRefetchObjects={currentObjectsRefetch}
          handleModelList={refetchModels}
          modelList={models}
          InferenceList={inferenceList}
          setCurrentInferenceID={setCurrentInferenceID}
          currentInferenceID={currentInferenceID}
          setSelectedModel={setSelectedModel}
          setStatusValue={setStatusValue}
          selectedModel={selectedModel}
          InferenceListArray={InferenceListArray}
          refetchInference={InferenceListArray}
          setInferenceList={serInferenceList}
        />
      )}
      {step == 2 && (
        <MoveImage
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          objects={currentObjects}
          handelRefetchObjects={currentObjectsRefetch}
          setCurrentInferenceID={setCurrentInferenceID}
          currentInferenceID={currentInferenceID}
          selectedModel={selectedModel}
          statusValue={statusValue}
          setStep={setStep}
        />
      )}

      {step == 4 && (
        <MoveLiveImage
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          objects={currentObjects}
          handelRefetchObjects={currentObjectsRefetch}
          setCurrentInferenceID={setCurrentInferenceID}
          currentInferenceID={currentInferenceID}
          selectedModel={selectedModel}
          statusValue={statusValue}
          setStep={setStep}
        />
      )}

      {/* {step === 3 && (
        <LiveInference
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          objects={currentObjects}
          handelRefetchObjects={currentObjectsRefetch}
          setCurrentInferenceID={setCurrentInferenceID}
          currentInferenceID={currentInferenceID}
          selectedModel={selectedModel}
          statusValue={statusValue}
          setStep={setStep}
          refetchInference={InferenceListArray}
        />
      )} */}
    </>
  );
};
