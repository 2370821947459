/* eslint-disable */
import { Modal, RecordValue } from 'components';
import React, { useState } from 'react';

import CameraNameForm from './cameraNameForm';

interface ICreateConfigModalProps {
  open: boolean;
  editStatus: boolean;
  onClose: () => void;
  configName: string | undefined;
  onSucess?: () => void;
  featuresList: RecordValue | RecordValue[];
  GrpName: RecordValue | RecordValue[];
  GrpId: RecordValue | RecordValue[];
  GrpShare: RecordValue | RecordValue[];
}

interface IformValues {
  name: string;
  global_share: boolean;
  features_list: string[];
}

export const CreateConfigModal: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,
  configName,
  featuresList,
  GrpName,
  GrpId,
  GrpShare,
  editStatus
}) => {
  const [values, setValues] = useState<IformValues>({
    name: configName ? configName : '',
    global_share: false,
    features_list: []
  });

  const resetValues = () => {
    setValues({
      name: configName ? configName : '',
      global_share: false,
      features_list: []
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues(); // Reset values when modal is closed
      }}
      title="Manage Group"
      size="md"
    >
      <CameraNameForm
        initialValues={{
          name: values.name,
          global_share: false,
          features_list: []
          // analysisOptions: values.analysisOptions,
          // imageBasedConfigOptions: values.imageBasedConfigOptions
        }}
        featuresList={featuresList}
        GrpName={GrpName}
        GrpId={GrpId}
        GrpShare={GrpShare}
        editStatus={editStatus}
        onSubmit={val => {
          setValues({ ...values, ...val });
          resetValues(); // Reset values
          onSucess && onSucess(); // Close modal
        }}
        onCancel={() => {
          onClose(); // Close modal
          resetValues(); // Reset values
        }}
      />
    </Modal>
  );
};
